import { Action } from '@ngrx/store';


export enum AuthActionType {
    SignInSucess = 'auth.signIn.success',
    SignInExternal = 'auth.signIn.external',
    SignOutSuccess = 'auth.signOut.success',
    ResetToken = 'auth.reset',

    // ChangePasswordSuccess = 'auth.changePassword.success',
}

export class SignInSuccessAction implements Action {
    readonly type = AuthActionType.SignInSucess;
    constructor(public payload: string) {}
}

export class SignInExternalAction implements Action {
    readonly type = AuthActionType.SignInExternal;
    constructor(public payload: string) {}
}

export class SignOutSuccessAction implements Action {
    readonly type = AuthActionType.SignOutSuccess;
    constructor() {}
}

export class ResetTokenAction implements Action {
    readonly type = AuthActionType.ResetToken;
    constructor() {}
}

export type AuthAction =
    | SignInSuccessAction
    | SignOutSuccessAction
    | ResetTokenAction
    | SignInExternalAction
