import { AuthAction, AuthActionType } from './auth.actions';
import jwt_decode from "jwt-decode";

export const AUTH_FEATURE_KEY = 'auth';

export interface AuthState {
    token: string;
    // email: string;
    // name: string;
    // customerId: string;
    // expirationDate: number;
    // isAdmin: boolean;
    // isSuperAdmin: boolean;
}

export interface AuthPartialState {
    readonly [AUTH_FEATURE_KEY]: AuthState;
}

export interface JwtData{
    email: string;
    name: string;
    customerId: string;
    exp: number;
    admin: string;
    superAdmin: string;
}

const initialAuthState: AuthState = {
    token: '',
    // email: '',
    // name: '',
    // customerId: '',
    // expirationDate: 0,
    // isAdmin: false,
    // isSuperAdmin: false
};

export function authReducer(state: AuthState = initialAuthState, action: AuthAction): AuthState {
    switch (action.type) {
        case AuthActionType.SignInSucess: {
            // let  decodedJwt: JwtData = jwt_decode(action.payload);
            return {
                ...state,
                token: action.payload,
                // email: decodedJwt.email,
                // name: decodedJwt.name,
                // customerId: decodedJwt.customerId,
                // expirationDate: decodedJwt.exp,
                // isAdmin: decodedJwt.admin.toLowerCase() == 'true',
                // isSuperAdmin: decodedJwt.superAdmin.toLowerCase() == 'true'
            };
        }
        case AuthActionType.SignInExternal: {
            return {
                ...state,
                token: action.payload,
            };
        }
        case AuthActionType.SignOutSuccess: {
            return initialAuthState;
        }
        case AuthActionType.ResetToken: {
            return initialAuthState;
        }
        default: {
            return state;
          }
    }
}