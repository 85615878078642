import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AdminService, FaqData, MarketingBannerData } from '../_services/admin.service';

@Component({
  selector: 'app-marketing-banner-details',
  templateUrl: './marketing-banner-details.component.html',
  styleUrls: ['./marketing-banner-details.component.css']
})
export class MarketingBannerDetailsComponent implements OnInit {
  @Input() id: string = '';
  
  marketingBanner: MarketingBannerData = null;
  buttonText = "Marketing-Banner-Details.create-button";
  titleText = "Marketing-Banner-Details.create-title";
  form!: FormGroup;

  //file upload
  @ViewChild('UploadFileInput') uploadFileInput: ElementRef;
  myfilename = 'Select File';
  mbPreview: any;

  //spinner properties
  color = 'primary';
  mode = 'indeterminate';
  value = 50;
  displayProgressSpinner = false;
  spinnerWithoutBackdrop = false;
  disableSubmitButton = true;

  constructor(
    private route: ActivatedRoute,
    private adminService: AdminService,
    private formBuilder: FormBuilder, 
    private toastr: ToastrService,
    private router: Router,
    private sanitizer: DomSanitizer
  ) { 
  }

  ngOnInit() {
    this.id = this.route.snapshot.params.id;
    if(this.id != '0'){
      this.buttonText = 'Marketing-Banner-Details.edit-button';
      this.titleText = 'Marketing-Banner-Details.edit-title';
      this.adminService.getMarketingBanner(this.id).subscribe(result => {
        this.marketingBanner = result;
        this.adminService.getMarketingBannerPicture(this.id).subscribe(result => {
          let filename = '';
          if(result.headers.get('content-disposition')){
            filename = result.headers.get('content-disposition').split(';')[1].split('filename')[1].split('=')[1].trim();
            filename = filename.split('"')[1] === undefined ? filename : filename.split('"')[1].trim();
            var objectURL = URL.createObjectURL(new Blob([result.body], { type: result.body.type }));
            this.mbPreview = this.sanitizer.bypassSecurityTrustUrl(objectURL);
          }
          this.refreshForm(filename);
        });
      });    
    }
    this.refreshForm('');
  }

  private refreshForm(filename: string){
    this.form = this.formBuilder.group({ 
      id: [(this.marketingBanner && this.marketingBanner.id) || undefined],
      title: [(this.marketingBanner && this.marketingBanner.title) || undefined, Validators.required],
      fileContent: [undefined],  
      isEnabled: [(this.marketingBanner && this.marketingBanner.isEnabled) || false],
      myfilename:[filename]
    })
  }

  deleteMarketingBanner(){
    this.adminService.deleteMarketingBanner(this.id).subscribe((result => {
      if(result) {
        this.showToasterSuccess('Marketing banner deleted successfully.');
        this.router.navigate(['/marketingbanners']);
      }
      else{
        this.showToasterError('Failed to delete Marketing banner.')
      }
    }));
  }
  onSubmit(){
    if(this.form.valid){
      const formData = new FormData();
      console.log( this.form.controls['id'].value)
      formData.append('Id', this.form.controls['id'].value != null ? this.form.controls['id'].value : '');
      formData.append('Title', this.form.controls['title'].value);
      formData.append('IsEnabled', this.form.controls['isEnabled'].value);
      formData.append('FileContent', this.form.controls['fileContent'].value);
      formData.append('FileName', this.form.controls['myfilename'].value);

      this.adminService.editMarketingBanner(formData).subscribe((result => {
        //this.hideProgressSpinner();
        if(result) {
          this.showToasterSuccess('Marketing banner created/edited successfully.');
          this.router.navigate(['/marketingbanners']);
        }
        else{
          this.showToasterError('Failed to create/edit marketing banner.')
        }
      }));
    }
  }

  fileChangeEvent(fileInput: any) {
    if (fileInput.target.files && fileInput.target.files[0]) {
      this.form.controls['fileContent'].setValue(null);
      this.form.controls['myfilename'].setValue('');
      this.mbPreview = null;

      Array.from(fileInput.target.files).forEach((file: File) => {
        this.form.controls['fileContent'].setValue(file);
        this.form.controls['myfilename'].setValue(file.name);
      });

      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        this.mbPreview =  image.src;
        image.onload = rs => {

          // Return Base64 Data URL
          const imgBase64Path = e.target.result;

        };
      };
      reader.readAsDataURL(fileInput.target.files[0]);

      // Reset File Input to Selct Same file again
      this.uploadFileInput.nativeElement.value = "";
    } else {
      this.form.controls['myfilename'].setValue('Select File');
    }
  }

  //toast
  showToasterSuccess(message){
    this.toastr.success(message, 'Success', {
      positionClass: 'toast-bottom-center',
      progressAnimation: 'decreasing',
      timeOut: 5000,
    });
  }

  showToasterError(message){
    this.toastr.error(message, 'Error', {
      positionClass: 'toast-bottom-center',
      progressAnimation: 'decreasing',
      timeOut: 5000,
    });
  }

  //spinner methods
  showProgressSpinner = () => {
    this.displayProgressSpinner = true;
  };

  hideProgressSpinner = () => {
    console.log('hide spinenr')
    this.displayProgressSpinner = false;
  };
}