<mat-divider></mat-divider>
<div class="marketing-banners">
  <div class="marketing-banners-title" fxLayout="row">
      <mat-icon>photo_library</mat-icon>
      <p> {{ 'Marketing-banners.form-title' | translate }}</p>
  
      <button mat-flat-button (click)="createNewMarketingBanner()">{{ 'Marketing-banners.new-mb-button' | translate }}</button>
  </div> 

  <table *ngIf="showTable" mat-table [dataSource]="marketingBannersDataSource" class="mat-elevation-z0" (mouseout)="removeHighlightedRow()">

    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element"><mat-icon [ngClass] = "{'highlight-description' : element.id == selectedMarketingBanner}" class="mat-icon-description">insert_photo</mat-icon></td>
    </ng-container>

    <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef> {{ 'Marketing-banners.table-title-column' | translate }} </th>
      <td mat-cell *matCellDef="let element"> {{ element.title }} </td>
    </ng-container>

    <ng-container matColumnDef="isEnabled">
      <th mat-header-cell *matHeaderCellDef> {{ 'Marketing-banners.table-isEnabled-column' | translate }} </th>
      <td mat-cell *matCellDef="let element"> {{ (element.isEnabled ? 'General.Yes': 'General.No') | translate  }} </td>
    </ng-container>

    <ng-container matColumnDef="button">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell class="td-button" *matCellDef="let element" [ngClass] = "{'highlight-td' : element.id == selectedMarketingBanner}"><button mat-icon-button color="primary" (click)="openMarketingBannerEdit(element.id)" title="Edit Marketing Banner"><mat-icon class="mat-icon-arrow">keyboard_arrow_right</mat-icon></button></td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="marketingBannersDisplayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: marketingBannersDisplayedColumns;" (mouseover)="highlightRow(row)"></tr>
</table>

<mat-paginator [pageSize]="10">
</mat-paginator>

<div *ngIf="!showTable">
    <p> {{ 'Marketing-banners.no-mbs' | translate }}</p>
</div>

</div>



