import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { hydrationMetaReducer } from './hydration.reducer';
import { authReducer, AuthState } from './auth.reducer';

export { AuthFacade } from './auth.facade';
export { AuthState, AUTH_FEATURE_KEY, authReducer } from './auth.reducer';
export { authQuery } from './auth.selector';

export interface RootState {
    auth: AuthState
}

export const reducers: ActionReducerMap<RootState> = {
    auth: authReducer
  }
  
  export const metaReducers: MetaReducer[] = [
    hydrationMetaReducer
  ]