import { formatDate } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ConsoleLogger } from '@microsoft/signalr/dist/esm/Utils';
import { ToastrService } from 'ngx-toastr';
import { AuthFacade } from '../auth';
import { JwtData } from '../auth/state/auth.reducer';
import { CreateInvoiceComponent } from '../createinvoice/createinvoice.component';
import { AdminService } from '../_services/admin.service';
import { AgreementData, AgreementsService, AgreementStatus, AgreementStatusTranslation, CreditLimitAmounts, CreditLimitData, DEFAULT_CURRENCY, InvoiceData, InvoiceStatus, InvoiceStatusTranslation } from '../_services/agreements.service';
import { AppConfigService } from '../_services/appconfig.service';
// import { ContractData, ContractsService, ContractStatus, CustomerInvoiceData, DEFAULT_CURRENCY } from '../_services/contracts.service';
import jwt_decode from "jwt-decode";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  @Input() agreementsCount = 0;
  @Input() documentTemplatesCount = 0;
  @Input() latestInvoiceAmount = 0;
  @Input() agreementsOverdueAmount = 0;

  agreements: AgreementData[] = [];
  invoices: InvoiceData[] = [];
  creditLimit: CreditLimitData = null;
  isValidCreditLimit = false;
  customerId = '';
  isAdmin = false;
  isSuperAdmin = false;
  agreementsPreview: AgreementData[] = [];
  invoicesPreview: InvoiceData[] = [];
  currencyCode = DEFAULT_CURRENCY;

  //tables headers
  agreementsDisplayedColumns: string[] = ['agreementno', 'contracteffecticedate', 'contractterm'];
  invoicesDisplayedColumns: string[] = ['invoiceid', 'invoicestatus', 'invoicedate', 'invoiceduedate', 'invoiceamount'];

  //spinner properties
  color = 'primary';
  mode = 'indeterminate';
  value = 50;
  displayProgressSpinner = false;
  spinnerWithoutBackdrop = false;

  showAd = false;
  banner: any;

  constructor(
    private agreementsService: AgreementsService, 
    private authFacade: AuthFacade, 
    private dialog: MatDialog,
    private router: Router,
    private adminService: AdminService,
    private sanitizer: DomSanitizer,
    private toastr: ToastrService
  ) {
    this.authFacade.token$.subscribe((token => {
      let decodedToken: JwtData = jwt_decode(token)
      this.customerId = decodedToken.customerId;
      this.isAdmin = decodedToken.admin.toLowerCase() == 'true';
      this.isSuperAdmin = decodedToken.superAdmin.toLowerCase() == 'true';
    }));
    
  }

  ngOnInit(): void {
    this.adminService.getEnabledMarketingBannerPicture().subscribe(result => {
      let filename = '';
          if(result.headers.get('content-disposition')){
            filename = result.headers.get('content-disposition').split(';')[1].split('filename')[1].split('=')[1].trim();
            filename = filename.split('"')[1] === undefined ? filename : filename.split('"')[1].trim();
            var objectURL = URL.createObjectURL(new Blob([result.body], { type: result.body.type }));
            this.banner = this.sanitizer.bypassSecurityTrustUrl(objectURL);
            this.showAd = filename != 'null.txt';
          }
          else{
            this.showAd = false;
          }
    });

    this.agreementsService.getAgreements().subscribe((result) => {
      this.agreements = result.map((el) =>{
        el.statusText = AgreementStatus[el.status].replace(/([A-Z])/g, ' $1').trim();
        el.statusTranslation = AgreementStatusTranslation[AgreementStatus[el.status]];
        el.validContractTerm = new Date(el.contractTerm) > new Date();
        return el;
      });
      this.agreementsCount = this.agreements.length;
      this.agreements.forEach((agreement) => {
        this.agreementsOverdueAmount += agreement.overdueAmount;
      })
      this.agreementsOverdueAmount = Math.round(this.agreementsOverdueAmount * 100) / 100;
    });

    this.agreementsService.getInvoices().subscribe((result) => {
      this.invoices = result.map(el => {
        el.statusText = InvoiceStatus[el.status].replace(/([A-Z])/g, ' $1').trim();
        el.statusTranslation = InvoiceStatusTranslation[InvoiceStatus[el.status]];
        return el;
      });
    });

    this.agreementsService.getCreditLimit('contact', 'null').subscribe((result) => {
      if(result){
        this.creditLimit = result[0];
        let currentDate = new Date();
        if(this.creditLimit)
          this.isValidCreditLimit = new Date(this.creditLimit.validFrom) < currentDate && new Date(this.creditLimit.validUntil) > currentDate;
        if(this.creditLimit){
          this.agreementsService.getCreditLimitAmounts(this.creditLimit.id, 'contact').subscribe((result) => {
            this.creditLimit.utilisedAmount = result.utilisedCreditLimit;
            this.creditLimit.availableAmount = result.availableCreditLimit;
          });
        }
      }
    });
  }
  isMinimumDateTimeValue(eta: Date): Boolean {
    var isMin = new Date(eta).getTime() === new Date('0001-01-01T00:00:00').getTime()
    return isMin;
  }

  downloadCustomerStatement(){
    let language = localStorage.getItem("languageCode");
    if(language){
      language = language.substring(1, language.length -1)
    }else{
      language = "ENU";
    }
    this.showProgressSpinner()
    this.agreementsService.downloadCustomerStatement(language).subscribe((result => {
      if(result.body['size'] > 0){
        var filename = result.headers.get('content-disposition').split(';')[1].split('filename')[1].split('=')[1].trim();
        const downloadedFile = new Blob([result.body], { type: result.body.type });
        const a = document.createElement('a');
        a.setAttribute('style', 'display:none;');
        document.body.appendChild(a);
        a.href = URL.createObjectURL(downloadedFile);
        a.download = filename;
        a.target = '_blank';
        this.hideProgressSpinner();
        a.click();
        document.body.removeChild(a);
      }
      else{
        this.hideProgressSpinner();
        this.showToasterError();
      }
    }));
  }

  getDate(date: Date) : Date{
    return date.toString() == '0001-01-01T00:00:00' ? undefined : date;
  }

 
  openMarketingBannersConfiguration(){
    this.router.navigate(['/marketingbanners']);
  }

  openFaqsConfiguration(){
    this.router.navigate(['/faqs']);
  }

  openCreateAdmins(){
    this.router.navigate(['/admins']);
  }

  openLogs(){
    this.router.navigate(['/logs']);
  }

  openUsers(){
    this.router.navigate(['/users']);
  }

  openEvents(){
    this.router.navigate(['/events']);
  }

  redirectToUpload(){
    this.router.navigate(['/upload']);
  }

  createInvoice(agreementId){
    //this.creatingInvoice = true;
    const dialogRef = this.dialog.open(CreateInvoiceComponent, {
      restoreFocus: false,
      width: '60%',
      height: '80%',
      data: {agreementId: agreementId},
    });

    dialogRef.afterClosed().subscribe(() => {
      this.agreementsService.getInvoices().subscribe((result) => {
        this.invoices = result.map(el => {
          el.statusText = InvoiceStatus[el.status].replace(/([A-Z])/g, ' $1').trim();
          el.statusTranslation = InvoiceStatusTranslation[InvoiceStatus[el.status].replace(/([A-Z])/g, ' $1').trim()];
          el.invoiceDate = this.getDate(el.invoiceDate);
          el.invoiceDueDate = this.getDate(el.invoiceDueDate);
          return el;
        });
      });
    });
  }

  details(agreementId){
    this.router.navigate(['/agreements', agreementId]);
  }

  getInvoicesData(agreementId){
    let agreementInvoices;
    let count = 0;
    agreementInvoices = this.invoices.filter((invoice) =>{
      if(invoice.agreementId == agreementId && count < 10){
        count ++;
        return true;
      }
      else{
        return false;
      }
    });

    return agreementInvoices;
  }

  agreementHeaderClicked(agreement){
    agreement.expanded = !agreement.expanded;
  }

   //spinner methods
   showProgressSpinner = () => {
    this.displayProgressSpinner = true;
  };

  hideProgressSpinner = () => {
    this.displayProgressSpinner = false;
  };

  //toast

  showToasterError(){
    this.toastr.error('Failed to download customer statement.', 'Error', {
      positionClass: 'toast-bottom-center',
      progressAnimation: 'decreasing',
      timeOut: 5000,
    });
  }
}