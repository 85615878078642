import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CustomValidators } from '../custom-validators';
import { AuthService, SetPasswordData } from '../_services/authentication.service';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SignUpComponent implements OnInit {
  form!: FormGroup;
  code = "";

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.toastr.toastrConfig.preventDuplicates = true;
    this.toastr.toastrConfig.countDuplicates = true;
    let email = this.route.snapshot.params.email;
    this.code = this.route.snapshot.params.code;

    this.form = this.formBuilder.group({ 
      email: [email, Validators.required],
      password: ['', Validators.compose([
        CustomValidators.patternValidator(/\d/, { hasNumber: true }),
        CustomValidators.patternValidator(/[A-Z]/, { hasCapitalCase: true }),
        CustomValidators.patternValidator(/[a-z]/, { hasSmallCase: true }),
        CustomValidators.patternValidator(/[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/, { hasSpecialCharacters: true }),
        Validators.minLength(6),
        Validators.required
      ])],
      confirmPassword: ['', Validators.compose([
        Validators.required,
        CustomValidators.passwordMatchValidator('password')
      ])]
    }, { validators: this.checkPasswords })
  }

 

  onSubmit(){
    if(this.form.valid){
      const data = this.form.value as SetPasswordData;
      data.code = this.code;
      this.authService.setPassword(data).subscribe((result => {
        if(result){
          this.router.navigate(['/login']);
        }
        else{
          this.showToasterError();
        }
      }));
    }
  }
  
  checkPasswords: ValidatorFn = (group: AbstractControl):  ValidationErrors | null => { 
    let pass = group.get('password').value;
    let confirmPass = group.get('confirmPassword').value
    return pass === confirmPass ? null : { notSame: true }
  }

  showToasterError(){
    this.toastr.error('Failed to sign up. Check if you entered credentials correctly.', 'Error', {
      positionClass: 'toast-bottom-center',
      progressAnimation: 'decreasing',
      timeOut: 5000,
    });
  }
}
