import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {

  private appConfig: any;

  constructor(private http: HttpClient) { }

  loadAppConfig() {
    return this.http.get('./settings/app.settings.json')
      .toPromise()
      .then(data => {
        this.appConfig = data;
      });
  }

  get apiEndpointUrl() {

    if (!this.appConfig) {
      throw Error('Config file not loaded!');
    }
    return this.appConfig.endpoint.uri;
  }

  get adVisibility() {
    if (!this.appConfig) {
      throw Error('Config file not loaded!');
    }
    return this.appConfig.enableAds;
  }

  get addTitle() {
    if (!this.appConfig) {
      throw Error('Config file not loaded!');
    }
    return this.appConfig.addTitle;
  }

  get defaultLanguage() {
    if (!this.appConfig) {
      throw Error('Config file not loaded!');
    }
    return this.appConfig.defaultLanguage;
  }

  get createInvoicesDateFormat() {
    if (!this.appConfig) {
      throw Error('Config file not loaded!');
    }
    return this.appConfig.createInvoicesDateFormat;
  }
}

