import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthFacade  } from '../auth';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    token = "";
    constructor(private authFacade: AuthFacade){
      this.authFacade.token$.subscribe(token => {
        this.token = token;
      })
    }

    intercept(request: HttpRequest<any>, newRequest: HttpHandler): Observable<HttpEvent<any>> {
    // add authorization header to request

    //Get Token data from local storage

    const req1 = request.clone({
        headers: request.headers.set('Authorization', `Bearer ${this.token}`),
      });

    return newRequest.handle(req1 );
    }
}