import { Component, ElementRef, HostListener, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthFacade } from 'src/app/auth';
import { JwtData } from 'src/app/auth/state/auth.reducer';
import jwt_decode from "jwt-decode";

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class SidebarComponent {
  jwtData = null;
  id = '';
  isAdmin = false;
  isSuperAdmin = false;

  constructor(
    private elementRef: ElementRef,
    private router: Router,
    private authFacade: AuthFacade,
  ){ 
    this.authFacade.token$.subscribe((token => {
      let decodedToken: JwtData = jwt_decode(token)
      this.id = decodedToken.customerId;
      this.isAdmin = decodedToken.admin.toLowerCase() == 'true';
      this.isSuperAdmin = decodedToken.superAdmin.toLowerCase() == 'true';
    }));
  }

  @Input('hover-class') hoverClass:any;

  @HostListener('mouseenter') onMouseEnter() {
    this.elementRef.nativeElement.classList.add(this.hoverClass);
 }

  @HostListener('mouseleave') onMouseLeave() {
    this.elementRef.nativeElement.classList.remove(this.hoverClass);
  }

  openAgreements(){
    this.router.navigate(['/agreements']);
  }
  openInvoices(){
    this.router.navigate(['/invoices']);
  }
  openHome(){
    this.router.navigate(['/home']);
  }
  openMarketingBannersConfiguration(){
    this.router.navigate(['/marketingbanners']);
  }
  openFaqsConfiguration(){
    this.router.navigate(['/faqs']);
  }
  openCreateAdmins(){
    this.router.navigate(['/admins']);
  }
  openLogs(){
    this.router.navigate(['/logs']);
  }
  openFaqs(){
    this.router.navigate(['/faqs']);
  }
  openUsers(){
    this.router.navigate(['/users']);
  }
  openEvents(){
    this.router.navigate(['/events']);
  }
  // openUploadDocuments(){
  //   this.router.navigate(['/upload']);
  // }
}