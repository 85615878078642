<mat-divider></mat-divider>

<div class="marketing-banner-details">
  <div class="marketing-banner-details-title" fxLayoutAlign="space-between">
    <p>{{ titleText | translate }}</p>
    <a href="./marketingbanners">{{ 'Marketing-Banner-Details.back-button-title' | translate }}</a>
  </div>
    
    <div class="marketing-banner-details-form">
        <form [formGroup]="form" fxLayout="column" (ngSubmit)="onSubmit()">
            <mat-form-field >
                <mat-label>{{ 'Marketing-Banner-Details.form-title' | translate }}</mat-label>
                <input matInput formControlName="title" placeholder="{{ 'Marketing-Banner-Details.form-title-placeholder' | translate }}">
            </mat-form-field>
         
            <!-- <input formControlName="fileContent" type="file" (change)="fileBrowseHandler($event.target.files)"> -->
            <mat-form-field>
              <div class="myfilebrowser">
                <mat-toolbar>
                  <!-- Readonly Input to show File names -->
                  <input matInput formControlName="myfilename" readonly name="memberContactNo" />
                  <!-- Browse Button -->
                  <button mat-flat-button color="primary">
                    Browse
                  </button>
                </mat-toolbar>
                <!-- Fetch selected filed on change -->
                <input type="file" #UploadFileInput id="fileUpload" (change)="fileChangeEvent($event)" name="fileUpload"
                  multiple="multiple" accept="image/*" />
              </div>
            </mat-form-field>
            <img [src]="mbPreview" *ngIf="mbPreview">

            <mat-checkbox matInput formControlName="isEnabled">{{ 'Marketing-Banner-Details.form-isenabled' | translate }}</mat-checkbox>
            
            <div class="send-button" fxLayout="row" fxLayoutAlign="space-between center">
                <button  [disabled]="!form.valid"[ngClass]="{'disabled' : !form.valid}" mat-flat-button>{{ buttonText | translate }}</button>
                <button type="button" [disabled]="!form.valid" [ngClass]="{'disabled' : !form.valid}" mat-flat-button (click)="deleteMarketingBanner()">{{ 'Marketing-Banner-Details.table-delete-button' | translate }}</button>
            </div>
        </form>
    </div>
</div>

<app-progress-spinner  [color]="color" [mode]="mode" [value]="value" [backdropEnabled]="true" [positionGloballyCenter]="true"
 [displayProgressSpinner]="displayProgressSpinner">
</app-progress-spinner>