import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthFacade } from '../auth';
import { JwtData } from '../auth/state/auth.reducer';
import { CreateInvoiceComponent } from '../createinvoice/createinvoice.component';
import { InvoiceData, DEFAULT_CURRENCY, AgreementsService, InvoiceStatus, InvoiceStatusTranslation } from '../_services/agreements.service';
import jwt_decode from "jwt-decode";

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.css']
})
export class InvoicesComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Input() selectedInvoice: any;

  invoices: InvoiceData[] = [];
  id = '';
  defaultSearch = '';
  currencyCode = DEFAULT_CURRENCY;

  //spinner properties
  color = 'primary';
  mode = 'indeterminate';
  value = 50;
  displayProgressSpinner = false;
  spinnerWithoutBackdrop = false;

  allSelected = true;
  newSelected = false;
  openSelected = false;
  archiveSelected = false;

  // invoicesDisplayedColumns: string[] = ['description', 'invoiceid', 'agreementid', 'status', 'invoicedate', 'invoiceduedate', 'invoiceamount', 'button'];
  invoicesDisplayedColumns: string[] = ['description', 'invoiceid', 'agreementid', 'status', 'invoicedate', 'invoiceduedate', 'advancepaid', 'reserveoutstanding', 'invoiceamount'];
  invoicesDataSource = new MatTableDataSource<InvoiceData>(this.invoices);

  constructor(
    private agreementsService: AgreementsService,
    private authFacade: AuthFacade, 
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog
  ) { 
    this.authFacade.token$.subscribe((token => {
      let decodedToken: JwtData = jwt_decode(token)
      this.id = decodedToken.customerId;
    }));
  }

  ngOnInit() {
    if(this.route.snapshot.params.id == 'overdue'){
      this.defaultSearch = this.route.snapshot.params.id ;

    }
    else if(this.route.snapshot.params.id){
      this.agreementsService.getAgreement(this.route.snapshot.params.id).subscribe((result) => {
        this.defaultSearch = result.id;
      });
    }
    this.agreementsService.getInvoices().subscribe((result) => {
      this.invoices = result.map(el => {
        el.statusText = InvoiceStatus[el.status];
        el.statusTranslation = InvoiceStatusTranslation[InvoiceStatus[el.status]];
        return el;
      });

      this.invoicesDataSource = new MatTableDataSource(this.invoices);
      this.invoicesDataSource.paginator = this.paginator;
      if(this.defaultSearch){
        this.applySearch(this.defaultSearch);
      }
    })
  }

  applySearch(searchValue: string) {
    searchValue = searchValue.trim(); 
    searchValue = searchValue.toLowerCase(); 
    this.invoicesDataSource.filter = searchValue;
  }

  downloadInvoice(id: any, type: any){
    // this.showProgressSpinner();
    // let invoiceType = type == 'Invoice' ? 0 : 1;
    // this.contractsService.downloadInvoice({ documentNo: id, type: invoiceType}).subscribe((result => {
    //   if(result.headers.get('content-disposition')){
    //     var filename = result.headers.get('content-disposition').split(';')[1].split('filename')[1].split('=')[1].trim();
    //     const downloadedFile = new Blob([result.body], { type: result.body.type });
    //     const a = document.createElement('a');
    //     a.setAttribute('style', 'display:none;');
    //     document.body.appendChild(a);
    //     a.href = URL.createObjectURL(downloadedFile);
    //     a.download = filename;
    //     a.target = '_blank';
    //     a.click();
    //     document.body.removeChild(a);
    //   }
    //   else{
    //     this.showToasterError();
    //   }
      
    //   this.hideProgressSpinner();
    // }));
  }

  highlightRow(invoice) {
    this.selectedInvoice = invoice.invoiceId;
  }

  removeHighlightedRow(){
    this.selectedInvoice = null;
  }

  filterNew(){
    let newInvoices = this.invoices.filter(x => x.status == InvoiceStatus.New || x.status == InvoiceStatus.NotificationSent);
    this.invoicesDataSource = new MatTableDataSource(newInvoices);
    this.invoicesDataSource.paginator = this.paginator;
    this.changeSelected('new');
    this.applySearch(this.defaultSearch);
  }

  filterOpen(){
    let newInvoices = this.invoices.filter(x => x.status == InvoiceStatus.Accepted || x.status == InvoiceStatus.Disbursed || x.status == InvoiceStatus.Overdue);
    this.invoicesDataSource = new MatTableDataSource(newInvoices);
    this.invoicesDataSource.paginator = this.paginator;
    this.changeSelected('open');
    this.applySearch(this.defaultSearch);
  }

  filterArchive(){
    let newInvoices = this.invoices.filter(x => x.status == InvoiceStatus.Closed || x.status == InvoiceStatus.Rejected);
    this.invoicesDataSource = new MatTableDataSource(newInvoices);
    this.invoicesDataSource.paginator = this.paginator;
    this.changeSelected('archive');
    this.applySearch(this.defaultSearch);
  }

  filterAll(){
    this.invoicesDataSource = new MatTableDataSource(this.invoices);
    this.invoicesDataSource.paginator = this.paginator;
    this.changeSelected('all');
    this.applySearch(this.defaultSearch);
  }

  changeSelected(type: string){
    this.allSelected = false;
    this.newSelected = false;
    this.openSelected = false;
    this.archiveSelected = false;

    if(type == 'all')
      this.allSelected = true;
    if(type == 'new')
      this.newSelected = true;
    if(type == 'open')
      this.openSelected = true;
    if(type == 'archive')
      this.archiveSelected = true;
  }

  getDate(date: Date) : Date{
    return date.toString() == '0001-01-01T00:00:00' ? undefined : date;
  }

  // createInvoice(){
  //   //this.creatingInvoice = true;
  //   const dialogRef = this.dialog.open(CreateInvoiceComponent, {
  //     restoreFocus: false,
  //     width: '60%',
  //     height: '80%',
  //     data: {agreementId: null},
  //   });

  //   dialogRef.afterClosed().subscribe(() => {
  //     this.agreementsService.getInvoices().subscribe((result) => {
  //       this.invoices = result.map(el => {
  //         el.statusText = InvoiceStatus[el.status];
  //         el.statusTranslation = InvoiceStatusTranslation[InvoiceStatus[el.status]];

  //         el.invoiceDate = this.getDate(el.invoiceDate);
  //         el.invoiceDueDate = this.getDate(el.invoiceDueDate);
  //         return el;
  //       });
  
  //       this.invoicesDataSource = new MatTableDataSource(this.invoices);
  //       this.invoicesDataSource.paginator = this.paginator;
  //       if(this.defaultSearch){
  //         this.applySearch(this.defaultSearch);
  //       }
  //     })
  //   });
  // }

  //spinner methods
  showProgressSpinner = () => {
    this.displayProgressSpinner = true;
  };

  hideProgressSpinner = () => {
    this.displayProgressSpinner = false;
  };

  //toast

  showToasterError(){
    this.toastr.error('Failed to download invoice.', 'Error', {
      positionClass: 'toast-bottom-center',
      progressAnimation: 'decreasing',
      timeOut: 5000,
    });
  }

}