<mat-divider></mat-divider>

<div class="faq-details">
  <div class="faq-details-title" fxLayoutAlign="space-between">
    <p>{{ titleText | translate }}</p>
    <a href="./faqs">{{ 'Faq-Details.back-button-title' | translate }}</a>
  </div>
    
    <div class="faq-details-form">
        <form [formGroup]="form" fxLayout="column" (ngSubmit)="onSubmit()">
            <mat-form-field >
                <mat-label>{{ 'Faq-Details.form-question' | translate }}</mat-label>
                <input matInput formControlName="question" placeholder="{{ 'Faq-Details.form-question-placeholder' | translate }}">
            </mat-form-field>
            
            <mat-form-field >
              <mat-label>{{ 'Faq-Details.form-answer' | translate }}</mat-label>
              <input matInput formControlName="answer" placeholder="{{ 'Faq-Details.form-answer-placeholder' | translate }}">
            </mat-form-field>
    
            <mat-checkbox *ngIf="isAdmin" matInput formControlName="isEnabled">{{ 'Faq-Details.form-isenabled' | translate }}</mat-checkbox>
            
            <div *ngIf="isAdmin" class="send-button" fxLayout="row" fxLayoutAlign="space-between center">
                <button [disabled]="!form.valid" [ngClass]="{'disabled' : !form.valid}" mat-flat-button>{{ buttonText | translate }}</button>
                <button type="button" [disabled]="!form.valid" [ngClass]="{'disabled' : !form.valid}" mat-flat-button (click)="deleteFaq()">{{ 'Faqs-Details.table-delete-button' | translate }}</button>
            </div>
        </form>
    </div>
</div>

<app-progress-spinner  [color]="color" [mode]="mode" [value]="value" [backdropEnabled]="true" [positionGloballyCenter]="true"
 [displayProgressSpinner]="displayProgressSpinner">
</app-progress-spinner>