import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthFacade } from "../auth";
import { JwtData } from "../auth/state/auth.reducer";
// import { ContractsService, CustomerMessageData, CustomerMessageType } from "../_services/contracts.service";


@Component({
    selector: 'app-customer-messages',
    templateUrl: './customer-messages.component.html',
    styleUrls: ['./customer-messages.component.scss']
})
    export class CustomerMessagesComponent implements OnInit {
    // messages: CustomerMessageData[] = [];
    customerId = '';

    messagesDisplayedColumns: string[] = ['messageType','contract', 'subject', 'excerpt', 'messageCreated'];


    constructor(
        // private contractsService: ContractsService, 
        private authFacade: AuthFacade, 
        private router: Router
    ) {
        this.authFacade.token$.subscribe((token => {
            let decodedToken: JwtData = jwt_decode(token)
            this.customerId = decodedToken.customerId;
          }));
    }

    ngOnInit() {
        // this.contractsService.getCustomerMessages(this.customerId).subscribe((result => {
        //     this.messages = result.map(el => {
        //         el.typeText = CustomerMessageType[el.recipientContactId ? 1 : 0];
        //         return el;
        //     });
        // }));
        // this.contractsService.readMessages(this.customerId).subscribe((result => {
        //     console.log(result)
        // }));
    }

    createNewMessage(){
        this.router.navigate(['/help']);
    }
}

function jwt_decode(token: string): JwtData {
    throw new Error("Function not implemented.");
}
