import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { AuthFacade } from '../auth';
import { AdminService, FaqData, MarketingBannerData } from '../_services/admin.service';

@Component({
  selector: 'app-marketing-banners',
  templateUrl: './marketing-banners.component.html',
  styleUrls: ['./marketing-banners.component.css']
})
export class MarketingBannersComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  marketingBanners: MarketingBannerData[] = [];
  @Input() selectedMarketingBanner: any;
  id = '';
  showTable = false;

  marketingBannersDisplayedColumns: string[] = ['description', 'title', 'isEnabled', 'button'];
  marketingBannersDataSource = new MatTableDataSource<MarketingBannerData>(this.marketingBanners);

  constructor(
    private adminService: AdminService,
    private router: Router
  ) {
  }

  ngOnInit() {
    this.adminService.getMarketingBanners().subscribe((result) => {
      this.marketingBanners = result;
      this.showTable = result.length > 0;
      this.marketingBannersDataSource = new MatTableDataSource(this.marketingBanners);
      this.marketingBannersDataSource.paginator = this.paginator;
    });
  }

  openMarketingBannerEdit(id: any){
    this.router.navigate(['/marketingbanners', id]);
  }

  highlightRow(mb) {
    this.selectedMarketingBanner = mb.id;
  }

  removeHighlightedRow(){
    this.selectedMarketingBanner = null;
  }

  createNewMarketingBanner(){
    this.router.navigate(['/marketingbanners', 0]);
  }
}