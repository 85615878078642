<div fxFlexFill>
    <div class="logo-container" fxLayout="column" fxLayoutAlign="center center"  fxFlex="50">
        <img class="logo" src="./assets/images/logo_hovi.png">
    </div>
    <div class="sign-in" fxLayout="column" fxFlex="50">
        <div *ngIf="superAdminExists">
            <p class="sign-in-label">{{ 'Login.title' | translate }}</p>
            <p class="subtitle"> {{ 'Login.subtitle' | translate }}</p>
            <form [formGroup]="form" fxLayout="column" class="sign-in-form" (ngSubmit)="onSubmit()">
                <mat-form-field>
                    <mat-label>{{ 'Login.form-email' | translate }}</mat-label>
                    <input matInput formControlName="email" placeholder="Enter email">
                </mat-form-field>
                
                <mat-form-field>
                    <mat-label>{{ 'Login.form-password' | translate }}</mat-label>
                    <input type="password" matInput formControlName="password" placeholder="Enter password">
                </mat-form-field>

                <div class="sign-in-buttons" fxLayout="row" fxLayoutAlign="space-between center">
                    <a href="./forgotpassword">{{ 'Login.form-forgot-password' | translate }}</a> 
                    <button [disabled]="!form.valid" [ngClass]="{'disabled' : !form.valid}" mat-flat-button>{{ 'Login.sign-in-button-title' | translate }}</button>
                </div>
            </form>
        </div>

        <div *ngIf="!superAdminExists">
            <p class="sign-in-label">{{ 'Login.csa-title' | translate }}</p>
            <p class="subtitle"> {{ 'Login.subtitle' | translate }}</p>
            <form [formGroup]="formCsa" fxLayout="column" class="sign-in-form" (ngSubmit)="onSubmitCsa()">
                <mat-form-field>
                    <mat-label>{{ 'Login.form-email' | translate }}</mat-label>
                    <input matInput formControlName="email" placeholder="Enter email">
                </mat-form-field>
                
                <mat-form-field>
                    <mat-label>{{ 'Login.csa-form-name' | translate }}</mat-label>
                    <input type="name" matInput formControlName="name" placeholder="Enter name">
                </mat-form-field>

                <div class="sign-in-buttons" fxLayout="row" fxLayoutAlign="end">
                    <button [disabled]="!formCsa.valid" [ngClass]="{'disabled' : !formCsa.valid}" mat-flat-button>{{ 'Login.csa-button-title' | translate }}</button>
                </div>
            </form>
        </div>
    </div>
</div>
