<mat-divider></mat-divider>
<div class="admins">
    <div class="admins-title" fxLayout="row">
        <mat-icon>person</mat-icon>
        <p> {{ 'Admins.form-title' | translate }} </p>
    
        <button mat-flat-button (click)="createNewAdmin()">{{ 'Admins.new-admin-button' | translate }}</button>
    </div> 

    <table *ngIf="showTable" mat-table [dataSource]="adminsDataSource" class="mat-elevation-z0" (mouseout)="removeHighlightedRow()">
  <ng-container matColumnDef="description">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element"><mat-icon [ngClass] = "{'highlight-description' : element.id == selectedAdmin}" class="mat-icon-description">person</mat-icon></td>
  </ng-container>

  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef> {{ 'Admins.table-name-column' | translate }} </th>
    <td mat-cell *matCellDef="let element"> {{ element.name }} </td>
  </ng-container>

  <ng-container matColumnDef="email">
    <th mat-header-cell *matHeaderCellDef> {{ 'Admins.table-email-column' | translate }} </th>
    <td mat-cell *matCellDef="let element"> {{ element.email }} </td>
  </ng-container>

  <ng-container matColumnDef="activated">
    <th mat-header-cell *matHeaderCellDef> {{ 'Admins.table-activated-column' | translate }} </th>
    <td mat-cell *matCellDef="let element"> {{ (element.activated ? 'General.Yes': 'General.No') | translate }} </td>
  </ng-container>

  <ng-container matColumnDef="button">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell class="td-button" *matCellDef="let element" [ngClass] = "{'highlight-td' : element.id == selectedAdmin}"><button mat-icon-button color="primary" (click)="deleteAdmin(element.id)" title="Delete Admin"><mat-icon class="mat-icon-arrow">delete</mat-icon></button></td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="adminsDisplayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: adminsDisplayedColumns;" (mouseover)="highlightRow(row)"></tr>
</table>

<mat-paginator [pageSize]="10">
</mat-paginator>

<div *ngIf="!showTable">
    <p>{{ 'Admins.no-admins' | translate }}</p>
</div>

</div>



