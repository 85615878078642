import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../_services/authentication.service';
import { AuthFacade } from '../auth/state';
import { subscribeOnce } from '../_helpers/rxjs.helper';
import { ToastrService } from 'ngx-toastr';
import jwt_decode from "jwt-decode";
import { JwtData } from '../auth/state/auth.reducer';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit {
  form!: FormGroup;
  formCsa!: FormGroup;

  superAdminExists = true;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private authFacade: AuthFacade,
    private toastr: ToastrService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    var token = this.route.snapshot.params.token;
    this.route.queryParams.subscribe(params => {
      token = params['token'];
    })
    try{
      let decodedToken: JwtData = jwt_decode(token)

      this.form = this.formBuilder.group({ 
        email: ['', Validators.compose([Validators.email, Validators.required])],
        password: ['', Validators.required],
        mobileDesktop: ''
      });

      this.formCsa = this.formBuilder.group({ 
        email: ['', Validators.compose([Validators.email, Validators.required])],
        name: ['', Validators.required],
        mobileDesktop: ''
      });
      this.authFacade.signInExternal(token)
      this.router.navigate(['/home'])
    }
    catch{
      this.authService.getUserCount().subscribe(result => {
        this.superAdminExists = result > 0;
      });

      this.toastr.toastrConfig.preventDuplicates = true;
      this.toastr.toastrConfig.countDuplicates = true;
      this.authFacade.resetToken();
      this.form = this.formBuilder.group({ 
        email: ['', Validators.compose([Validators.email, Validators.required])],
        password: ['', Validators.required],
        mobileDesktop: ''
      });

      this.formCsa = this.formBuilder.group({ 
        email: ['', Validators.compose([Validators.email, Validators.required])],
        name: ['', Validators.required],
        mobileDesktop: ''
      });
    }
  }

  onSubmit(){
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(navigator.userAgent)){
      this.form.controls['mobileDesktop'].setValue('mobile');
    }
    else{
      this.form.controls['mobileDesktop'].setValue('desktop');
    }
    
    if(this.form.valid){
      this.authFacade.signIn(this.form.value).subscribe({ 
        next: (result) => {
          if(result){
            this.router.navigate(['/home'])
          }
          else{
            console.log(result)
            this.showToasterError();
          }
        },
        error: (e) => {
          console.log( e)
          this.showToasterError();
        }
     });
    }
  }

  onSubmitCsa(){
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(navigator.userAgent)){
      this.formCsa.controls['mobileDesktop'].setValue('mobile');
    }
    else{
      this.formCsa.controls['mobileDesktop'].setValue('desktop');
    }
    
    if(this.formCsa.valid){
      this.authService.createSuperAdmin(this.formCsa.value).subscribe((result) =>
      {
        if(result){
          console.log(result)
          this.router.navigate(['/login'])
        }
        else{
          this.showToasterError();
        }
      });
    }
  }

  showToasterError(){
    this.toastr.error('Failed to sign in. Check if you entered credentials correctly.', 'Error', {
      positionClass: 'toast-bottom-center',
      progressAnimation: 'decreasing',
      timeOut: 5000,
    });
  }
}
