<div class="messages">
    <div class="messages-title" fxLayout="row">
        <mat-icon>drafts</mat-icon>
        <p> Messages List</p>

        <button mat-flat-button (click)="createNewMessage()">{{ 'Messages.new-message-button' | translate }}</button>
    </div>

    <mat-divider></mat-divider>

    <!-- <mat-table class="messages-table" [dataSource]="messages" class="mat-elevation-z0">
        <ng-container matColumnDef="messageType">
            <mat-header-cell class="w-15" *matHeaderCellDef> Type </mat-header-cell>
            <mat-cell class="w-15" *matCellDef="let element"> {{element.typeText}} </mat-cell>
          </ng-container>

        <ng-container matColumnDef="contract">
            <mat-header-cell class="w-15" *matHeaderCellDef> Contract </mat-header-cell>
            <mat-cell class="w-15" *matCellDef="let element"> {{element.contractId}} </mat-cell>
          </ng-container>

        <ng-container matColumnDef="subject">
          <mat-header-cell *matHeaderCellDef> Subject </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.subject}} </mat-cell>
        </ng-container>
      
        <ng-container matColumnDef="excerpt">
          <mat-header-cell *matHeaderCellDef> Message </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.excerpt}} </mat-cell>
        </ng-container>
      
        <ng-container matColumnDef="messageCreated">
          <mat-header-cell *matHeaderCellDef> Created At </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.messageCreated | date:'dd.MM.yyyy'}} </mat-cell>
        </ng-container>
      
        <mat-header-row *matHeaderRowDef="messagesDisplayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: messagesDisplayedColumns;"></mat-row>
      </mat-table> -->
</div>