import { Component, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AuthFacade } from '../auth';
import { JwtData } from '../auth/state/auth.reducer';
import { AgreementsService } from '../_services/agreements.service';
// import { ContractsService } from '../_services/contracts.service';
import jwt_decode from "jwt-decode";

@Component({
  selector: 'app-upload-documents',
  templateUrl: './upload-documents.component.html',
  styleUrls: ['./upload-documents.component.scss']
})
export class UploadDocumentsComponent  implements OnInit {
  @Input() selectedFile: any;
  @Input() selectedValue: string;

  agreementIds: string[] = [];
  files: any[] = [];
  id = '';

  //spinner properties
  color = 'primary';
  mode = 'indeterminate';
  value = 50;
  displayProgressSpinner = false;
  spinnerWithoutBackdrop = false;

  constructor(
    private agreementsService: AgreementsService, 
    private authFacade: AuthFacade, 
    private toastr: ToastrService
    ) { 
      this.authFacade.token$.subscribe((token => {
        let decodedToken: JwtData = jwt_decode(token)
        this.id = decodedToken.customerId;
      }));
  }

  ngOnInit() {
    this.agreementsService.getAgreements().subscribe((result) => {
      this.agreementIds = result.map(el => {
        return el.id;
      });
    })
  }

  onFileDropped(file) {
    this.prepareFilesList(file);
  }

  fileBrowseHandler(file) {
    this.prepareFilesList(file);
  }

  deleteFile(index: number) {
    this.files.splice(index, 1);
  }

  prepareFilesList(files: Array<any>) {
    for (const item of files) {
      this.files.push(item);
    }
  }

  formatBytes(bytes) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = 0;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }


  highlightRow(file) {
    this.selectedFile = file.name;
  }

  removeHighlightedRow(){
    this.selectedFile = null;
  }

  uploadDocuments(){
    this.showProgressSpinner();
    let formData = new FormData();
    
    for (const file of this.files) {
      formData.append(file.name, file);
    }

    //let agreementId = this.selectedValue ? this.selectedValue : "";

    this.agreementsService.uploadDocument(formData).subscribe(result => {
      this.hideProgressSpinner();

      if(result){
        this.showToasterSuccess();
        this.files = [];
        this.selectedValue = "";
      }
      else{
        this.showToasterError();
      }
      
    });

  }

 //toast
 showToasterSuccess(){
  this.toastr.success('Files uploaded successfully.', 'Success', {
    positionClass: 'toast-bottom-center',
    progressAnimation: 'decreasing',
    timeOut: 5000,
  });
}

showToasterError(){
  this.toastr.error('Failed to upload files.', 'Error', {
    positionClass: 'toast-bottom-center',
    progressAnimation: 'decreasing',
    timeOut: 5000,
  });
}

  //spinner methods
  showProgressSpinner = () => {
    this.displayProgressSpinner = true;
  };

  hideProgressSpinner = () => {
    this.displayProgressSpinner = false;
  };
}