<mat-divider></mat-divider>
<div class="logs">
    <div class="logs-title" fxLayout="row">
        <mat-icon>history</mat-icon>
        <p> {{ 'Logs.form-title' | translate }}</p>

        <button mat-flat-button (click)="exportLogs()">{{ 'Logs.export-logs-button' | translate }}</button>
    </div> 

    <table *ngIf="showTable" mat-table [dataSource]="logsDataSource" class="mat-elevation-z0">
        <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element"><mat-icon class="mat-icon-description">history</mat-icon></td>
        </ng-container>

        <ng-container matColumnDef="content">
            <th mat-header-cell *matHeaderCellDef> {{ 'Logs.table-content-column' | translate }} </th>
            <td mat-cell *matCellDef="let element"> {{ element.content }} </td>
        </ng-container>


        <ng-container matColumnDef="activityType">
            <th mat-header-cell *matHeaderCellDef> {{ 'Logs.table-type-column' | translate }} </th>
            <td mat-cell *matCellDef="let element"> {{ element.activityTypeText }} </td>
        </ng-container>

        <ng-container matColumnDef="activityStatus">
            <th mat-header-cell *matHeaderCellDef> {{ 'Logs.table-status-column' | translate }} </th>
            <td mat-cell *matCellDef="let element"> {{ element.activityStatusText }} </td>
        </ng-container>

        <ng-container matColumnDef="activityDate">
            <th mat-header-cell *matHeaderCellDef> {{ 'Logs.table-date-column' | translate }} </th>
            <td mat-cell *matCellDef="let element"> {{ element.activityDateTime | date:'dd.MM.yyyy' }} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="logsDisplayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: logsDisplayedColumns;"></tr>
    </table>

    <mat-paginator [pageSize]="10">
    </mat-paginator>

    <div *ngIf="!showTable">
        <p> {{ 'Logs.no-logs' | translate }}</p>
    </div>
</div>



