import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { BaseServiceProxy, IListData, ISingleData, OperationContract, OperationMethod, ServiceContract, ServiceOperation } from "../_helpers/contract.helper";
import { HttpClientProxy, HTTP_CLIENT_PROXY } from "../_helpers/http.helper";
import { environment } from '../../environments/environment';
import { AppConfigService } from "../_services/appconfig.service";
import { AgreementData, AgreementVendorDebtorData, AgreementVendorDebtorDataExtended, CreditLimitAmounts, CreditLimitData, ImportInvoiceData, InvoiceData, NewInvoiceData, SingleResponse } from "../_services/agreements.service";

@Injectable({ providedIn: 'root' })
@ServiceContract({ uri: 'api/agreement' })
export class AgreemntsServiceProxy extends BaseServiceProxy {
  constructor(@Inject(HTTP_CLIENT_PROXY) proxy: HttpClientProxy, private http: HttpClient, private appSettings: AppConfigService) {
    super(proxy);
  }

  @OperationContract({ uri: 'agreements', method: OperationMethod.Get })
  getAgreements!: ServiceOperation<void, void, IListData<AgreementData>>;

  @OperationContract({ uri: 'agreementdetails/{id}', method: OperationMethod.Get })
  getAgreement!: ServiceOperation<void, { id: string }, ISingleData<AgreementData>>;

  @OperationContract({ uri: 'creditlimitamounts/{id}/{type}', method: OperationMethod.Get })
  getCreditLimitAmounts!: ServiceOperation<void, { id: string, type: string }, ISingleData<CreditLimitAmounts>>;

  @OperationContract({ uri: 'agreementdetails/{id}/invoices', method: OperationMethod.Get })
  getAgreementInvoices!: ServiceOperation<void, { id: string }, IListData<InvoiceData>>;

  @OperationContract({ uri: 'invoices', method: OperationMethod.Get })
  getInvoices!: ServiceOperation<void, void, IListData<InvoiceData>>;

  @OperationContract({ uri: 'vendorsdebtors/{id}', method: OperationMethod.Get })
  getAgreementVendorsDebtors!: ServiceOperation<void, { id: string }, IListData<AgreementVendorDebtorData>>;

  @OperationContract({ uri: 'vendorsdebtorsextended/{id}', method: OperationMethod.Get })
  getAgreementVendorsDebtorsExtended!: ServiceOperation<void, { id: string }, IListData<AgreementVendorDebtorDataExtended>>;

  @OperationContract({ uri: 'vendorsdebtors', method: OperationMethod.Get })
  getVendorsDebtors!: ServiceOperation<void, void, IListData<AgreementVendorDebtorData>>;

  @OperationContract({ uri: 'create/invoice', method: OperationMethod.Post })
  createInvoice!: ServiceOperation<NewInvoiceData, void, SingleResponse>;

  @OperationContract({ uri: 'creditlimit/{type}/{id}', method: OperationMethod.Get })
  getCreditLimit!: ServiceOperation<void, { type: string, id: string }, IListData<CreditLimitData>>;

  downloadAgreement (id: string){
    return this.http.get(this.appSettings.apiEndpointUrl + `/api/agreement/download/agreement/${id}`, { responseType: 'blob', observe: 'response'});
  }

  @OperationContract({ uri: 'upload', method: OperationMethod.Post })
  uploadDocument!: ServiceOperation<FormData, void, SingleResponse>;

  downloadCustomerStatement(language: string){
    return this.http.get(this.appSettings.apiEndpointUrl + `/api/agreement/download/customerstatement/${language}`, { responseType: 'blob', observe: 'response'});
  }

  @OperationContract({ uri: 'processinvoice/{id}', method: OperationMethod.Get })
  processInvoice!: ServiceOperation<void, { id: string }, ISingleData<boolean>>;

  @OperationContract({ uri: 'importinvoices', method: OperationMethod.Post })
  importInvoices!: ServiceOperation<FormData, void, IListData<ImportInvoiceData>>;

  @OperationContract({ uri: 'createinvoices', method: OperationMethod.Post })
  createInvoices!: ServiceOperation<FormData, void, SingleResponse>;

  downloadInvoiceTemplate (){
    return this.http.get(this.appSettings.apiEndpointUrl + `/api/agreement/download/invoicetemplate`, { responseType: 'blob', observe: 'response'});
  }
}