<div class="file-upload">
    <p>{{ 'Upload.title' | translate }}</p>
    <div class="file-upload-outer" fxFlexFill fxLayout="column">
        <div fxLayout="row">
            <div fxLayout="column" fxLayoutAlign="center center"  class="file-upload-field" appDnd (fileDropped)="onFileDropped($event)">
                <input type="file" #fileDropRef id="fileDropRef" multiple (change)="fileBrowseHandler($event.target.files)" />
                <mat-icon class="main-icon">cloud_upload</mat-icon>
                
                <div class="file-upload-text" fxLayout="row">
                    <mat-icon class="attach-icon">attach_file</mat-icon>
                    <p class="add-file">{{ 'Upload.add-file-title' | translate }}</p>
                    <p class="drop">{{ 'Upload.add-file-subtitle' | translate }}</p>
                </div>
            </div>


            <div class="files-list" (mouseout)="removeHighlightedRow()">
                <div fxLayout="row" class="single-file" *ngFor="let file of files; let i = index" (mouseover)="highlightRow(file)">
                    <div class="info" [ngClass] = "{'highlight-div' : file.name == selectedFile}" fxLayout="row">
                        <p class="name" [ngClass] = "{'highlight-p' : file.name == selectedFile}">{{ file?.name }}</p>
                        <p class="size" [ngClass] = "{'highlight-p' : file.name == selectedFile}">{{ formatBytes(file?.size) }}</p>
                    </div>
                    <mat-icon (click)="deleteFile(i)">close</mat-icon>
                </div>
            </div>
        </div>
        <div class="file-upload-agreement">
            <!-- <p>{{ 'Upload.contract-select-explanation' | translate }}</p> -->
            <form fxLayout="row" fxLayoutAlign="space-between center">
                <!-- <mat-form-field appearance="fill"> 
                    <mat-label>{{ 'Upload.agreement-select-explanation' | translate }}</mat-label>
                    <mat-select [(ngModel)]="selectedValue" name="agreementId">
                        <mat-option></mat-option>
                        <mat-option *ngFor="let agreementId of agreementIds" [value]="agreementId">
                            {{ agreementId }}
                        </mat-option>
                    </mat-select>
                </mat-form-field> -->

                <div>
                    <button mat-flat-button [disabled]="files.length == 0" [ngClass]="{'disabled' : files.length == 0}" (click)="uploadDocuments()">{{ 'Upload.upload-button' | translate }}</button>
                </div>
            </form>
        </div>
    </div>
</div>

<app-progress-spinner  [color]="color" [mode]="mode" [value]="value" [backdropEnabled]="true" [positionGloballyCenter]="true"
 [displayProgressSpinner]="displayProgressSpinner">
</app-progress-spinner>