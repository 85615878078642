<div  class="header" fxLayout="row" fxLayoutAlign="space-between center">
    <div class="header-logo" (click)="openHome()">
        <img class="logo" src="./assets/images/logo_hovi.png">
    </div>

    <div class="header-main" fxLayout="end">
        <div class="header-left">
            <mat-form-field appearance="none" class="language-switcher"> 
                <mat-select [value]="language.value" (valueChange)="selectLanguage($event)" disableOptionCentering>
                    <mat-select-trigger>
                        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="padding: 0 16px">
                          <img [src]="language.img" class="flag"/>
                          <span>{{ language.label }}</span>
                        </div>
                      </mat-select-trigger>
                    <mat-option *ngFor="let opt of languages" [value]="opt.value">
                        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                          <img [src]="opt.img" class="flag" /> 
                          <span>{{ opt.label }}</span>
                        </div>
                      </mat-option>
                </mat-select>
            </mat-form-field>  
            <!-- <div *ngIf="!isAdmin" class="help">
                <p>?</p>
            </div>
            <div *ngIf="!isAdmin" class="help-text" (click)="openHelp()">
                <p>{{ 'Header.help' | translate }}</p>
            </div> -->
        </div>

        <mat-divider [vertical]="true" ></mat-divider>

        <div class="header-right">
            <!-- <mat-icon *ngIf="!isAdmin" (click)="openCustomerMessages()" matBadge="!" matBadgeColor="warn" matBadgeHidden="{{ (hideBadge | async) }}">notifications</mat-icon> -->
            <div class="initials" (click)="displayOverlay()" id='btn'>
                <p>{{ getInitials(name)}}</p>
            </div>
        </div>
        
    </div>
</div>