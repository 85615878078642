<mat-divider></mat-divider>
<div class="faqs">
    <div class="faqs-title" fxLayout="row">
        <mat-icon>question_answer</mat-icon>
        <p> {{ 'Faqs.form-title' | translate }}</p>
    
        <button *ngIf="isAdmin" mat-flat-button (click)="createNewFaq()">{{ 'Faqs.new-faq-button' | translate }}</button>
    </div> 

    <div class="faq-button" fxLayout="row">
        <div class="faq-search">
          <input matInput (keyup)="applySearch($event.target.value)" placeholder="{{ 'Faq.search' | translate }}">
        </div>
      </div>

    <table *ngIf="showTable" mat-table [dataSource]="faqsDataSource" class="mat-elevation-z0" (mouseout)="removeHighlightedRow()">
        <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element"><mat-icon [ngClass] = "{'highlight-description' : element.id == selectedFaq}" class="mat-icon-description">question_answer</mat-icon></td>
        </ng-container>

        <ng-container matColumnDef="question">
            <th mat-header-cell *matHeaderCellDef> {{ 'Faqs.table-question-column' | translate }} </th>
            <td mat-cell *matCellDef="let element"> {{ element.question }} </td>
        </ng-container>

        <ng-container matColumnDef="answer">
            <th mat-header-cell *matHeaderCellDef> {{ 'Faqs.table-answer-column' | translate }} </th>
            <td mat-cell *matCellDef="let element"> {{ element.answer }} </td>
        </ng-container>

        <ng-container matColumnDef="isEnabled">
            <th [ngClass]="{'hidden' : !isAdmin}" mat-header-cell *matHeaderCellDef> {{ 'Faqs.table-isEnabled-column' | translate }} </th>
            <td [ngClass]="{'hidden' : !isAdmin}" mat-cell *matCellDef="let element"> {{ (element.isEnabled ? 'General.Yes': 'General.No') | translate  }} </td>
        </ng-container>
        <ng-container matColumnDef="button">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell class="td-button" *matCellDef="let element" [ngClass] = "{'highlight-td' : element.id == selectedFaq}"><button mat-icon-button color="primary" (click)="openFaqEdit(element.id)" title="Edit Faq"><mat-icon class="mat-icon-arrow">keyboard_arrow_right</mat-icon></button></td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="faqsDisplayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: faqsDisplayedColumns;" (mouseover)="highlightRow(row)"></tr>
    </table>

    <mat-paginator [pageSize]="10">
    </mat-paginator>

    <div *ngIf="!showTable">
        <p> {{ 'Faqs.no-faqs' | translate }}</p>
    </div>
</div>



