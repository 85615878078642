import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AdminData, AdminService, FaqData } from '../_services/admin.service';
import { AuthService } from '../_services/authentication.service';

@Component({
  selector: 'app-admin-details',
  templateUrl: './admin-details.component.html',
  styleUrls: ['./admin-details.component.css']
})
export class AdminDetailsComponent implements OnInit {
  form!: FormGroup;
  
  //spinner properties
  color = 'primary';
  mode = 'indeterminate';
  value = 50;
  displayProgressSpinner = false;
  spinnerWithoutBackdrop = false;
  disableSubmitButton = true;

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private formBuilder: FormBuilder, 
    private toastr: ToastrService,
    private router: Router
  ) { 
  }

  ngOnInit() {
    this.refreshForm();
  }

  private refreshForm(){
    this.form = this.formBuilder.group({ 
      name: ['', Validators.required],
      email: ['', Validators.compose([Validators.email, Validators.required])],  
    })
  }

  onSubmit(){
    if(this.form.valid){
      //this.showProgressSpinner();
      this.authService.createAdmin({...this.form.value }).subscribe((result => {
        //this.hideProgressSpinner();
        if(result) {
          this.showToasterSuccess('Admin created successfully.');
          this.router.navigate(['/admins']);
        }
        else{
          this.showToasterError('Failed to create Admin.')
        }
      }));
    }
  }

  //toast
  showToasterSuccess(message){
    this.toastr.success(message, 'Success', {
      positionClass: 'toast-bottom-center',
      progressAnimation: 'decreasing',
      timeOut: 5000,
    });
  }

  showToasterError(message){
    this.toastr.error(message, 'Error', {
      positionClass: 'toast-bottom-center',
      progressAnimation: 'decreasing',
      timeOut: 5000,
    });
  }

  //spinner methods
  showProgressSpinner = () => {
    this.displayProgressSpinner = true;
  };

  hideProgressSpinner = () => {
    console.log('hide spinenr')
    this.displayProgressSpinner = false;
  };
}