import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../_services/authentication.service';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ForgotPasswordComponent implements OnInit {
  form!: FormGroup;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.toastr.toastrConfig.preventDuplicates = true;
    this.toastr.toastrConfig.countDuplicates = true;
    this.form = this.formBuilder.group({ 
      email: ['', Validators.required],
    })
  }

  onSubmit(){
    if(this.form.valid){
      this.authService.resetPassword(this.form.get('email').value).subscribe((result) =>
      {
        if(result){
          this.router.navigate(['./login'])
        }
        else{
          this.showToasterError();
        }
      });
    }
  }

  showToasterError(){
    this.toastr.error('Failed to reset password.', 'Error', {
      positionClass: 'toast-bottom-center',
      progressAnimation: 'decreasing',
      timeOut: 5000,
    });
  }
}
