const baseHref = (<HTMLElement>(document.querySelector('base') || {})).getAttribute('href') || '/';

// TODO: use g flag and move const into usage so g is always reset
const httpUri = /^https?:\/\/|\//i;
const leadingSlash = /^(?!https?)\/*/i;
const trailingSlash = /\/+(\?|#|$)/;

export const isValidHttpUri = (uri: string) => httpUri.test(uri);

export const normalizeUri = (uri: string) => uri.replace(leadingSlash, '/').replace(trailingSlash, '$1');

export const fulfillUri = (uri: string, params: any) =>
  uri.replace(/\{([^\}]*)\}/g, (_, $1) => (params[$1] === undefined ? '' : params[$1]));

export const tryOpenInNewTab = (uri: string) => {
  const valid = isValidHttpUri(uri);
  if (valid) window.open(uri, '_blank');
  return valid;
};

export const APP_BASE_PATH = normalizeUri(baseHref) || '/';
export const APP_BASE_URI = normalizeUri(window.location.origin + APP_BASE_PATH);
