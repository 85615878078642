import { Injectable } from '@angular/core';  
import { HttpClient,HttpHeaders }    from '@angular/common/http';  
import { AgreemntsServiceProxy } from '../_proxy/agreements.proxy';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IListData } from '../_helpers/contract.helper';

export const DEFAULT_CURRENCY = 'EUR';

export enum FactoringType{
  Loan,
  Conventional
}

export enum FactoringTypeTranslation{
  Loan = "Enum.Factoring-Type-loan",
  Conventional = "Enum.Factoring-Type-conventional"
}

export enum AgreementStatus{
  InDeliberation,
  Valid,
  CollectionsOnly,
  Closed,
  WaitingForApproval,
  Declined
}

export enum AgreementStatusTranslation{
  InDeliberation = "Enum.Agreement-id",
  Valid = "Enum.Agreement-valid",
  CollectionsOnly = "Enum.Agreement-co",
  Closed = "Enum.Agreement-closed",
  WaitingForApproval = "Enum.Agreement-wfa",
  Declined = "Enum.Agreement-declined"
}

export enum InvoiceStatus{
  Closed,
  Overdue,
  Disbursed,
  Rejected,
  New,
  Accepted,
  NotificationSent
}

export enum InvoiceStatusTranslation{
  Closed = "Enum.Invoice-closed",
  Overdue = "Enum.Invoice-overdue",
  Disbursed = "Enum.Invoice-disbursed",
  Rejected = "Enum.Invoice-rejected",
  New = "Enum.Invoice-new",
  Accepted = "Enum.Invoice-accepted",
  NotificationSent = "Enum.Invoice-ns"
}


export enum AgrDebtorsVendorsStatus{
  New,
  Valid,
  Suspended,
  Blocked
}

export enum AgrDebtorsVendorsStatusTranslation{
  New = "Enum.Debtor-new",
  Valid = "Enum.Debtor-valid",
  Suspended = "Enum.Debtor-suspended",
  Blocked = "Enum.Debtor-blocked"
}

export enum CommissionUpfrontType{
  Percentage,
  FixedAmount
}

export enum CreditLimitStatus{
  Approved,
  Cancelled
}

export interface CreditLimitData{
  level: string;
  type: string; 
  id: string; 
  agreementContactId: string;
  lineId: number;
  status: CreditLimitStatus;
  validFrom: Date;
  validUntil: Date;
  creditLimit: number;
  nextReviewDate: Date;
  utilisedAmount: number;
  availableAmount: number;
}

export interface CreditLimitAmounts {
  availableCreditLimit: number;
  utilisedCreditLimit: number;
}

export interface AgreementData{
   id: string;
   type: FactoringType;
   typeText: string;
   typeTranslation: string;
   disclosedUndisclosed: string
   status: AgreementStatus;
   statusText: string;
   statusTranslation: string;
   contractEffectiveDate: Date;
   contractTerm: Date;
   clientId: string;
   clientName: string;
   clientRegistrationId: string;
   clientVATRegistrationId: string;
   clientPhoneNo: string;
   clientEMail: string;
   clientBankAccNo: string;
   advance: number;
   commissionUpfrontType: CommissionUpfrontType;
   commissionUpfrontTypeText: string;
   commissionUpfront: number;
   commissionAmountUpfront: number;
   commissionDeferred: number;
   interestAPR: number;
   increasedInterestAPR: number;
   penaltyInterestDaily: number;
   numberOutstandingInvoices: number;
   numberClosedInvoices: number;
   advancePaid: number;
   advancePending: number;
   debtorCollections: number;
   termChargesTotal: number;
   interestAmount: number;
   expanded: boolean;
   overdueAmount: number;
   validContractTerm: boolean;
   systemId: string;
}

export interface InvoiceData{
  agreementId: string;
  invoiceId: string;
  advancePaid: number;
  disbursementDate: Date;
  status: InvoiceStatus;
  statusText: string;
  statusTranslation: string;
  invoiceNo: string;
  invoiceAmountInclVAT: number;
  debtorId: string;
  debtorName: string;
  invoiceDate: Date;
  invoiceDueDate: Date;
  penaltyInterestAmount: number;
  reserveOutstanding: number;
  vendorName: string
  vendorId: string
  factoringProductCode: string
  factoringType: FactoringType;
  termChargesTotal: number;
  termChargesDue: number;
  debtorOutstanding: number;
}

export interface AgreementVendorDebtorData{
  agreementId: string;
  id: string;
  name: string;
  registrationId: string;
  vatRegistrationId: string;
  address: string;
  address2: string;
  city: string;
  county: string;
  countryRegionCode: string;
  phoneNo: string;
  eMail: string;
  contactPerson: string;
  status: AgrDebtorsVendorsStatus;
  statusText: string;
}

export interface AgreementVendorDebtorDataExtended{
  agreementId: string;
  id: string;
  name: string;
  registrationId: string;
  vatRegistrationId: string;
  address: string;
  address2: string;
  city: string;
  county: string;
  countryRegionCode: string;
  phoneNo: string;
  eMail: string;
  contactPerson: string;
  status: AgrDebtorsVendorsStatus;
  statusText: string;
  statusTranslation: string;
  availableAmount: string;
  utilisedAmount: string;
}

export interface NewInvoiceData {
  agreementNo: string;
  debtorNo: string;
  invoiceDate: Date;
  invoiceDueDate: Date;
  invoiceNo : string;
  invoiceAmountInclVAT: number;
}

export interface SingleResponse {
  data: number;
  message: string;
}

export interface ImportInvoiceData{
  invoiceId: string;
  invoiceDate: string;
  amountInclVAT: string;
  invoiceDueDate: string;
}

@Injectable({  
    providedIn: 'root'  
  })
export class AgreementsService {  
    constructor(private proxy: AgreemntsServiceProxy) { }  

    getAgreements(): Observable<AgreementData[]> {
        return this.proxy.getAgreements().pipe(map(r => r.data));
    }

    getAgreement(id: string): Observable<AgreementData> {
      return this.proxy.getAgreement({ params: { id } }).pipe(map(r => r.data));
    }

    getCreditLimitAmounts(id: string, type: string): Observable<CreditLimitAmounts> {
      return this.proxy.getCreditLimitAmounts({ params: { id, type } }).pipe(map(r => r.data));
    }

    getAgreementInvoices(id: string): Observable<InvoiceData[]> {
      return this.proxy.getAgreementInvoices({ params: { id } }).pipe(map(r => r.data));
    }

    getInvoices(): Observable<InvoiceData[]> {
      return this.proxy.getInvoices().pipe(map(r => r.data));
    }

    getAgreementVendorsDebtors(id: string): Observable<AgreementVendorDebtorData[]> {
      return this.proxy.getAgreementVendorsDebtors({ params: { id } }).pipe(map(r => r.data));
    }

    getAgreementVendorsDebtorsExtended(id: string): Observable<AgreementVendorDebtorDataExtended[]> {
      return this.proxy.getAgreementVendorsDebtorsExtended({ params: { id } }).pipe(map(r => r.data));
    }

    getVendorsDebtors(): Observable<AgreementVendorDebtorData[]> {
      return this.proxy.getVendorsDebtors().pipe(map(r => r.data));
    }

    createInvoice(data: NewInvoiceData): Observable<SingleResponse> {
      return this.proxy.createInvoice({ body: data });
    }

    getCreditLimit(type: string, id: string): Observable<CreditLimitData[]> {
      return this.proxy.getCreditLimit({ params: { type, id } }).pipe(map(r => r.data));
    }

    downloadAgreement(id: string) {
      return this.proxy.downloadAgreement(id);
    }

    uploadDocument(data: FormData):  Observable<SingleResponse> {
      return this.proxy.uploadDocument({ body: data });
    }

    downloadCustomerStatement(language: string) {
      return this.proxy.downloadCustomerStatement(language);
    }

    processInvoice(id: string): Observable<boolean> {
      return this.proxy.processInvoice({ params: { id } }).pipe(map(r => r.data));
    }

    importInvoices(data: FormData):  Observable<ImportInvoiceData[]> {
      return this.proxy.importInvoices({ body: data }).pipe(map(r => r.data));
    }

    createInvoices(data: FormData):  Observable<SingleResponse> {
      return this.proxy.createInvoices({ body: data });
    }

    downloadInvoiceTemplate() {
      return this.proxy.downloadInvoiceTemplate();
    }
}  