import { Dictionary } from "./type.helper";
import { HttpClient } from '@angular/common/http';
import { Assert } from "./assert.helper";
import { normalizeUri } from "./url.helper";
import { Observable } from "rxjs";
import { InjectionToken } from "@angular/core";


type HttpHeaders = Dictionary<string | string[]>;
type HttpParams = Dictionary<string | string[]>;

enum HttpMethod {
  Get = 'get',
  Post = 'post',
  Put = 'put',
  Delete = 'delete'
}

export interface IHttpOptions {
    readonly withCredentials?: boolean;
    readonly headers?: HttpHeaders;
  }
  
  interface IHttpRequest {
    readonly headers?: HttpHeaders;
    readonly query?: HttpParams;
    readonly body?: any;
    readonly responseType?: string;
  }

const DEFAULT_HTTP_OPTIONS: IHttpOptions = { headers: {}, withCredentials: false };

export const HTTP_CLIENT_PROXY = new InjectionToken<HttpClientProxy>('http-client-proxy');

export class HttpClientProxy {
    private readonly _options: IHttpOptions;
    private readonly endpointUri: string;
  
    constructor(private http: HttpClient, endpointUri: string, opts?: IHttpOptions) {
      Assert.isHttpUri(endpointUri);
      this.endpointUri = normalizeUri(endpointUri);
      this._options = { ...DEFAULT_HTTP_OPTIONS, ...opts };
    }
  
    get<TResponse>(uri: string, req?: IHttpRequest): Observable<TResponse> {
      return this.request(HttpMethod.Get, uri, req);
    }
  
    post<TResponse>(uri: string, req?: IHttpRequest): Observable<TResponse> {
      return this.request(HttpMethod.Post, uri, req);
    }
  
    delete<TResponse>(uri: string, req?: IHttpRequest): Observable<TResponse> {
      return this.request(HttpMethod.Delete, uri, req);
    }
  
    put<TResponse>(uri: string, req?: IHttpRequest): Observable<TResponse> {
      return this.request(HttpMethod.Put, uri, req);
    }
  
    private request<TResponse>(method: HttpMethod, uri: string, req?: IHttpRequest): Observable<TResponse> {
      return this.http.request<TResponse>(method, this.url(uri), this.options(req));
    }
  
    private url(uri: string): string {
      return this.endpointUri + normalizeUri(uri);
    }
  
    private options(req?: IHttpRequest) {
      const headers = { ...this._options.headers, ...(req || {}).headers };
      return { ...this._options, headers,  body: (req || {}).body, params: (req || {}).query};
    }
  }