import { StringMap } from '@angular/compiler/src/compiler_facade_interface';
import { Component, OnInit } from '@angular/core';
import { AuthFacade } from '../auth';
import { subscribeOnce } from '../_helpers/rxjs.helper';
// import { ContractsService, DocumentTemplateData } from '../_services/contracts.service';

@Component({
  selector: 'app-document-templates',
  templateUrl: './document-templates.component.html',
  styleUrls: ['./document-templates.component.scss']
})
export class DocumentTemplatesComponent implements OnInit {
  // documentTemplates: DocumentTemplateData[] = [];

  //spinner properties
  color = 'primary';
  mode = 'indeterminate';
  value = 50;
  displayProgressSpinner = false;
  spinnerWithoutBackdrop = false;

  constructor(
    // private contractsService: ContractsService
  ) {}

  ngOnInit() {
    // this.contractsService.getDocumentTemplates().subscribe((result) => {
    //   this.documentTemplates = result;
    // });
  }

  downloadTemplate(){
    this.showProgressSpinner();
    // this.contractsService.downloadDocumentTemplate(documentTemplate).subscribe((result => {
    //   var filename = result.headers.get('content-disposition').split(';')[1].split('filename')[1].split('=')[1].trim();
    //   const downloadedFile = new Blob([result.body], { type: result.body.type });
    //   const a = document.createElement('a');
    //   a.setAttribute('style', 'display:none;');
    //   document.body.appendChild(a);
    //   a.href = URL.createObjectURL(downloadedFile);
    //   a.download = filename;
    //   a.target = '_blank';
    //   this.hideProgressSpinner();
    //   a.click();
    //   document.body.removeChild(a);
    // }));
  }

  //spinner methods
  showProgressSpinner = () => {
    this.displayProgressSpinner = true;
  };

  hideProgressSpinner = () => {
    this.displayProgressSpinner = false;
  };
}
