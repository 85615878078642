<mat-divider></mat-divider>

  <div class="create-invoice-button" fxLayout="row">
    <div class="invoice-search">
      <input matInput [(ngModel)]="defaultSearch" (keyup)="applySearch($event.target.value)" placeholder="{{ 'Invoices.search' | translate }}">
    </div>
    <!-- <button mat-flat-button (click)="createInvoice()">{{ 'Invoices.new-invoice-button' | translate }}</button> -->

    <div class="invoice-filters">
      <button mat-flat-button (click)="filterAll()" [ngClass] = "{'selected-filter' : allSelected}">{{ 'Agreement-Details.invoices-filter-status-all' | translate }}</button>
      <button mat-flat-button (click)="filterNew()" [ngClass] = "{'selected-filter' : newSelected}">{{ 'Agreement-Details.invoices-filter-status-new' | translate }}</button>
      <button mat-flat-button (click)="filterOpen()" [ngClass] = "{'selected-filter' : openSelected}">{{ 'Agreement-Details.invoices-filter-status-open' | translate }}</button>
      <button mat-flat-button (click)="filterArchive()" [ngClass] = "{'selected-filter' : archiveSelected}">{{ 'Agreement-Details.invoices-filter-status-archive' | translate }}</button>
    </div>
  </div>

  <!-- <table mat-table [dataSource]="invoicesDataSource" class="mat-elevation-z0" (mouseout)="removeHighlightedRow()">
    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element"><mat-icon [ngClass] = "{'highlight-description' : element.invoiceId == selectedInvoice}" class="mat-icon-description">description</mat-icon></td>
    </ng-container>
  
    <ng-container matColumnDef="invoiceid">
      <th mat-header-cell *matHeaderCellDef> {{ 'Invoices.table-invoice-id-column' | translate }} </th>
      <td mat-cell *matCellDef="let element"> {{ element.invoiceId }} </td>
    </ng-container>
  
    <ng-container matColumnDef="agreementid">
      <th mat-header-cell *matHeaderCellDef> {{ 'Invoices.table-agreement-id-column' | translate }} </th>
      <td mat-cell *matCellDef="let element"> {{ element.agreementId }} </td>
    </ng-container>
  
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef> {{ 'Invoices.table-status-column' | translate }} </th>
      <td mat-cell *matCellDef="let element"> {{ element.statusText }} </td>
    </ng-container>

    <ng-container matColumnDef="invoicedate">
      <th mat-header-cell *matHeaderCellDef> {{ 'Invoices.table-invoice-date-column' | translate }} </th>
      <td mat-cell *matCellDef="let element"> {{ element.invoiceDate | date:'dd.MM.yyyy' }} </td>
    </ng-container>

    <ng-container matColumnDef="invoiceduedate">
      <th mat-header-cell *matHeaderCellDef> {{ 'Invoices.table-invoice-due-date-column' | translate }} </th>
      <td mat-cell *matCellDef="let element"> {{ element.invoiceDueDate | date:'dd.MM.yyyy' }} </td>
    </ng-container>
  
    <ng-container matColumnDef="invoiceamount">
        <th mat-header-cell *matHeaderCellDef> {{ 'Invoices.table-invoice-amount-incl-VAT-column' | translate }} </th>
        <td mat-cell *matCellDef="let element"> {{element.invoiceAmountInclVAT | customCurrency : currencyCode }} </td>
    </ng-container>
  
  
    <tr mat-header-row *matHeaderRowDef="invoicesDisplayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: invoicesDisplayedColumns;" (mouseover)="highlightRow(row)"></tr>
  </table>  -->
  
  

  <mat-table [dataSource]="invoicesDataSource" class="mat-elevation-z0">
    <ng-container matColumnDef="description">
      <mat-header-cell *matHeaderCellDef>  </mat-header-cell>
      <mat-cell *matCellDef="let element"> <mat-icon [ngClass] = "{'highlight-description' : element.invoiceId == selectedInvoice}" class="mat-icon-description">description</mat-icon> </mat-cell>
    </ng-container>
    
    <ng-container matColumnDef="invoiceid">
      <mat-header-cell *matHeaderCellDef> {{ 'Invoices.table-invoice-id-column' | translate }} </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.invoiceId}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="agreementid">
      <mat-header-cell *matHeaderCellDef> {{ 'Invoices.table-agreement-id-column' | translate }} </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.agreementId}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef> {{ 'Invoices.table-status-column' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.statusTranslation | translate }} </mat-cell>
      </ng-container>
  
    <ng-container matColumnDef="invoicedate">
      <mat-header-cell *matHeaderCellDef> {{ 'Invoices.table-invoice-date-column' | translate }} </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.invoiceDate | date:'dd.MM.yyyy' }} </mat-cell>
    </ng-container>
  
    <ng-container matColumnDef="invoiceduedate">
      <mat-header-cell *matHeaderCellDef> {{ 'Invoices.table-invoice-due-date-column' | translate }} </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.invoiceDueDate | date:'dd.MM.yyyy' }} </mat-cell>
    </ng-container>
    
    <ng-container matColumnDef="advancepaid">
      <mat-header-cell class="align-right" *matHeaderCellDef> {{ 'Invoices.table-invoice-advance-paid' | translate }} </mat-header-cell>
      <mat-cell class="align-right" *matCellDef="let element"> {{element.advancePaid | customCurrency : currencyCode }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="reserveoutstanding">
        <mat-header-cell class="align-right" *matHeaderCellDef> {{ 'Invoices.table-invoice-reserve-outstanding' | translate }} </mat-header-cell>
        <mat-cell class="align-right" *matCellDef="let element"> {{element.reserveOutstanding | customCurrency : currencyCode }} </mat-cell>
      </ng-container>

    <ng-container matColumnDef="invoiceamount">
      <mat-header-cell class="align-right" *matHeaderCellDef> {{ 'Invoices.table-invoice-amount-incl-VAT-column' | translate }} </mat-header-cell>
      <mat-cell class="align-right" *matCellDef="let element"> {{element.invoiceAmountInclVAT | customCurrency : currencyCode }} </mat-cell>
    </ng-container>
  
    <mat-header-row *matHeaderRowDef="invoicesDisplayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: invoicesDisplayedColumns;"  (mouseover)="highlightRow(row)"></mat-row>
  </mat-table>

<mat-paginator [pageSize]="20">
</mat-paginator>


<app-progress-spinner  [color]="color" [mode]="mode" [value]="value" [backdropEnabled]="true" [positionGloballyCenter]="true"
 [displayProgressSpinner]="displayProgressSpinner">
</app-progress-spinner>