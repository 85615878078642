import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivityStatus, ActivityType, AdminService, LogData } from '../_services/admin.service';

@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.css']
})
export class LogsComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  logs: LogData[] = [];
  showTable = false;

  logsDisplayedColumns: string[] = ['description', 'content', 'activityType', 'activityStatus', 'activityDate'];
  logsDataSource = new MatTableDataSource<LogData>(this.logs);

  //spinner properties
  color = 'primary';
  mode = 'indeterminate';
  value = 50;
  displayProgressSpinner = false;
  spinnerWithoutBackdrop = false;

  constructor(
    private adminService: AdminService,
  ) {
  }

  ngOnInit() {
    this.adminService.getLogs().subscribe((result) => {
      this.logs = result.map(el => {;
        el.activityTypeText = ActivityType[el.activityType];
        el.activityStatusText = ActivityStatus[el.activityStatus];
        return el;
      });
     
        
      this.showTable = result.length > 0;
      this.logsDataSource = new MatTableDataSource(this.logs);
      this.logsDataSource.paginator = this.paginator;
    });
  }

  exportLogs(){
    this.showProgressSpinner()
    this.adminService.exportLogs().subscribe((result => {
      var filename = result.headers.get('content-disposition').split(';')[1].split('filename')[1].split('=')[1].trim();
      const downloadedFile = new Blob([result.body], { type: result.body.type });
      const a = document.createElement('a');
      a.setAttribute('style', 'display:none;');
      document.body.appendChild(a);
      a.href = URL.createObjectURL(downloadedFile);
      a.download = filename;
      a.target = '_blank';
      this.hideProgressSpinner();
      a.click();
      document.body.removeChild(a);
    }));
  }

    //spinner methods
    showProgressSpinner = () => {
      this.displayProgressSpinner = true;
    };
  
    hideProgressSpinner = () => {
      this.displayProgressSpinner = false;
    };
}