import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ChangeDetectorRef, Component, OnInit  } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthFacade } from 'src/app/auth';
import { InitialsOverlayComponent } from '../initials-overlay/initials-overlay.component';
import * as signalR from '@microsoft/signalr';
import { environment } from 'src/environments/environment';
// import { ContractsService, MessageData } from 'src/app/_services/contracts.service';
import { BehaviorSubject } from 'rxjs';
import { AppConfigService } from 'src/app/_services/appconfig.service';
import { JwtData } from 'src/app/auth/state/auth.reducer';
import jwt_decode from "jwt-decode";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  jwtData = null;
  overlayRef: OverlayRef;
  id = '';
  name = '';
  hideBadge = new BehaviorSubject<boolean>(true);
  isAdmin = false;

  languages = [
    { value: 'en', label: 'English', img: './assets/images/en.flag.svg', code: 'ENU' }, 
    // { value: 'lt', label: 'Lietuvių', img: './assets/images/lt.flag.svg'},
    { value: 'fn', label: 'Finnish', img: './assets/images/fn.png', code: 'FIN'}
  ];

  language = this.appSettings.defaultLanguage;

  constructor(private authFacade: AuthFacade,
              // private contractsService: ContractsService, 
              private initialsOverlay: Overlay, 
              private router: Router, 
              private translate: TranslateService,
              public changeDetector: ChangeDetectorRef,
              private appSettings: AppConfigService        
  ){ 
    this.authFacade.token$.subscribe((token => {
      let decodedToken: JwtData = jwt_decode(token)
      this.id = decodedToken.customerId;
      this.name = decodedToken.name;
      this.isAdmin = decodedToken.admin.toLowerCase() == 'true' || decodedToken.superAdmin.toLowerCase() == 'true';
    }));
  }

  ngOnInit(): void {

    let currLanguage = localStorage.getItem("language");
    if(currLanguage){
      currLanguage = currLanguage.substring(1, currLanguage.length -1);
      this.translate.use(currLanguage);
      this.language = this.languages.find( lang => lang.value === currLanguage);
    }
  }

  selectLanguage(value: string) {
    this.language = this.languages.find( lang => lang.value === value);
    localStorage.setItem("language", JSON.stringify(this.language.value));
    localStorage.setItem("languageCode", JSON.stringify(this.language.code));
    this.translate.use(this.language.value);
  }

  getInitials(name: string) {
    var names = name.split(' '),
        initials = names[0].substring(0, 1).toUpperCase();
    
    if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }

    return initials;
  };

  displayOverlay() {
    const target = document.querySelector("#btn") as HTMLElement;
    const overlayRef = this.initialsOverlay.create({
      hasBackdrop: true,
      backdropClass: "cdk-overlay-transparent-backdrop",
      panelClass: "mat-elevation-z8",
      positionStrategy: this.initialsOverlay
        .position()
        .flexibleConnectedTo(target)
        .withPositions([
          {
            originX: "start",
            originY: "bottom",
            overlayX: "start",
            overlayY: "top"
          }
        ])
    });
    const component = new ComponentPortal(InitialsOverlayComponent);
    const componentRef = overlayRef.attach(component);
    componentRef.instance.close.subscribe(() => overlayRef.detach());
    overlayRef.backdropClick().subscribe(() => overlayRef.detach());
  }

  openHome(){
    this.router.navigate(['/home']);
  }
}