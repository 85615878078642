import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AUTH_FEATURE_KEY, AuthState, JwtData } from './auth.reducer';

const AUTH_STATE = createFeatureSelector<AuthState>(AUTH_FEATURE_KEY);

const token = createSelector(
    AUTH_STATE,
    (state: AuthState) => state.token
)

// const isAdmin = createSelector(
//     AUTH_STATE,
//     (state: AuthState) => state.isAdmin || state.isSuperAdmin
// )

// const isSuperAdmin = createSelector(
//     AUTH_STATE,
//     (state: AuthState) => state.isSuperAdmin
// )

// const jwtData = createSelector(
//     AUTH_STATE,
//     (state: AuthState) => ({ email: state.email, name: state.name, customerId: state.customerId, expirationDate: state.expirationDate, isAdmin: state.isAdmin, isSuperAdmin: state.isSuperAdmin })
// )

export const authQuery = {
    token,
    // jwtData,
    // isAdmin,
    // isSuperAdmin
};