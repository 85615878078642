import { Injectable } from '@angular/core';  
import { map } from 'rxjs/operators';
import { AdminServiceProxy } from '../_proxy/admin.proxy';

export enum ActivityType{
    Login,
    Logout,
    Activation,
    Email,
    Error,
}

export enum ActivityStatus{
    Empty,
    Information,
    Success,
    Error
}

export interface MarketingBannerData {
    id: string;
    title: string;
    fileContent: Blob;
    isEnabled: boolean;
    fileName: string;
  }
  
  export interface FaqData {
    id: string;
    question: string;
    answer: string;
    isEnabled: boolean;
  }

  export interface LogData {
    activityDateTime: Date;
    activityType: ActivityType;
    activityTypeText: string;
    content: string;
    activityStatus: ActivityStatus;
    activityStatusText: string;
  }

  export interface AdminData {
    id: string;
    name: string;
    email: string;
    activated: boolean;
  }

  export interface UserData {
    id: string;
    name: string;
    email: string;
    activated: boolean;
  }

@Injectable({  
    providedIn: 'root'  
  })
export class AdminService {  
    constructor(private proxy: AdminServiceProxy) { }  

    
    getMarketingBanners() {
      return this.proxy.getMarketingBanners().pipe(map(r => r.data));
    }

    getFaqs() {
        return this.proxy.getFaqs().pipe(map(r => r.data));
    }

    getEnabledFaqs() {
        return this.proxy.getEnabledFaqs().pipe(map(r => r.data));
    }

    deleteFaq(id: string) {
        return this.proxy.deleteFaq({ params: { id } }).pipe(map(r => r.data));
    }

    deleteMarketingBanner(id: string) {
        return this.proxy.deleteMarketingBanner({ params: { id } }).pipe(map(r => r.data));
    }

    editMarketingBanner(data: FormData) {
        return this.proxy.editMarketingBanner({ body: data }).pipe(map(r => r.data));
    }

    editFaq(data: FaqData) {
        return this.proxy.editFaq({ body: data }).pipe(map(r => r.data));
    }

    getFaq(id: string) {
        return this.proxy.getFaq({ params: { id } }).pipe(map(r => r.data));
    }

    getMarketingBanner (id: string) {
        return this.proxy.getMarketingBanner({ params: { id } }).pipe(map(r => r.data));
    }

    getMarketingBannerPicture(id: string) {
        return this.proxy.getMarketingBannerPicture(id);
    }

    getEnabledMarketingBannerPicture() {
        return this.proxy.getEnabledMarketingBannerPicture();
    }

    getLogs() {
        return this.proxy.getLogs().pipe(map(r => r.data));
    }

    exportLogs(){
        return this.proxy.exportLogs();
    }

    getUsers() {
        return this.proxy.getUsers().pipe(map(r => r.data));
    }
}  
