import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AuthFacade } from 'src/app/auth';
import { JwtData } from 'src/app/auth/state/auth.reducer';
import { subscribeOnce } from 'src/app/_helpers/rxjs.helper';
// import { ContractsService } from 'src/app/_services/contracts.service';
import jwt_decode from "jwt-decode";

@Component({
    selector: 'app-initials-overlay',
    templateUrl: './initials-overlay.component.html',
    styleUrls: ['./initials-overlay.component.scss']
  })
export class InitialsOverlayComponent implements OnInit {
    @Output() close = new EventEmitter<void>();

    email = '';
    customerName = '';
    customerId = '';
    isAdmin = false;
    isSuperAdmin = false;

    constructor(
        private authFacade: AuthFacade, 
        private router: Router, 
        // private contractsService: ContractsService
    ) { 
        this.authFacade.token$.subscribe((token => {
            let decodedToken: JwtData = jwt_decode(token)
            this.customerId = decodedToken.customerId;
            this.email = decodedToken.email;
            this.isAdmin = decodedToken.admin.toLowerCase() == 'true' || decodedToken.superAdmin.toLowerCase() == 'true';
            this.isSuperAdmin = decodedToken.superAdmin.toLowerCase() == 'true';
        }));
    }

    ngOnInit() {
        // this.contractsService.getCustomerName(this.customerId).subscribe((result: string) => {
        //     this.customerName = result;
        // });
    }

    logout(){
        this.authFacade.signOut().subscribe((result) =>
            {
                if(result){
                    this.authFacade.resetToken();
                    this.close.emit();
                    this.router.navigate(['/login']);
                }
            })
    }

}