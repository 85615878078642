import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, DEFAULT_CURRENCY_CODE, InjectionToken, LOCALE_ID, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './login/login.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HomeComponent } from './home/home.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { LayoutModule } from './layout/layout.module';
import { AgreementsComponent } from './agreements/agreements.component';
import { InvoicesComponent } from './invoices/invoices.component';
import { DocumentTemplatesComponent } from './document-templates/document-templates.component';
import { UploadDocumentsComponent } from './upload-documents/upload-documents.component';
import { CreateInvoiceComponent } from './createinvoice/createinvoice.component';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatIconModule } from '@angular/material/icon';
import { HttpClientProxy, HTTP_CLIENT_PROXY, IHttpOptions } from './_helpers/http.helper';
import { AgreementDetailsComponent } from './agreement-details/agreement-details.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatFileUploadModule } from 'angular-material-fileupload';
import { DndDirective } from './dnd.directive';
import { MatSelectModule } from '@angular/material/select';
import { environment } from '../environments/environment';
import { JwtInterceptor } from './_interceptor/jwt.interceptor';
import { AuthModule, AuthState } from './auth';
import { ActionReducer, StoreModule } from '@ngrx/store';
import { metaReducers, reducers } from './auth/state';
import { CustomCurrencyPipe } from './_pipes/custom.currency.pipe';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AppOverlayModule } from './overlay/overlay.module';
import { ProgressSpinnerComponent } from './progress-spinner/progress-spinner.component';
import { CustomerMessagesComponent } from './customer-messages/customer-messages.component';
import { ToastrModule } from 'ngx-toastr';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { MatBadgeModule } from '@angular/material/badge';
import { AppConfigService } from './_services/appconfig.service';
import { FaqDetailsComponent } from './faq-details/faq-details.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AdminsComponent } from './admins/admins';
import { AdminDetailsComponent } from './admin-details/admin-details';
import { MarketingBannersComponent } from './marketing-banners/marketing-banners';
import { MarketingBannerDetailsComponent } from './marketing-banner-details/marketing-banner-details.component';
import { FaqsComponent } from './faqs/faqs.component';
import { LogsComponent } from './logs/logs.component';
import { MatDatepickerModule  } from '@angular/material/datepicker';
import { DateAdapter, MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatDialogModule } from '@angular/material/dialog';
import { UploadDocumentsDialog } from './upload-documents-dialog/upload-documents-dialog.component';
import { registerLocaleData } from '@angular/common';
import localeLt from '@angular/common/locales/lt';
import localeFi from '@angular/common/locales/fi';
import { MatTabsModule } from '@angular/material/tabs';
import { UsersComponent } from './users/users';
import { EventsComponent } from './events/events';

registerLocaleData(localeLt);
registerLocaleData(localeFi);

export interface IEndpointSettings {
  uri: string;
  options?: IHttpOptions;
}

export enum Language{
  EN,
  LT
}

export interface IAppSettings {
  endpoint: IEndpointSettings;
}

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'dd.MM.yyyy',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

export function HTTP_CLIENT_PROXY_FACTORY (http: HttpClient, settings: IEndpointSettings)
{
  return new HttpClientProxy(http, settings.uri, { "withCredentials": false });
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json?v=' + Date.now());
}

export function AppSettingsFactory(appConfigService: AppConfigService) {
  return () => appConfigService.loadAppConfig();
}

export const ENDPOINT_SETTINGS = new InjectionToken<IEndpointSettings>('endpoint-settings');
export const APP_SETTINGS = new InjectionToken<IAppSettings>('app-settings');

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    AgreementsComponent,
    InvoicesComponent,
    DocumentTemplatesComponent,
    UploadDocumentsComponent,
    CreateInvoiceComponent,
    AgreementDetailsComponent,
    DndDirective,
    SignUpComponent,
    CustomCurrencyPipe,
    ProgressSpinnerComponent,
    CustomerMessagesComponent,
    ForgotPasswordComponent,
    PasswordResetComponent,
    FaqsComponent,
    LogsComponent,
    MarketingBannersComponent,
    MarketingBannerDetailsComponent,
    FaqDetailsComponent,
    AdminsComponent,
    AdminDetailsComponent,
    UploadDocumentsDialog,
    UsersComponent,
    EventsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatCardModule,
    ReactiveFormsModule,
    FormsModule,
    MatSidenavModule,
    MatToolbarModule,
    LayoutModule,
    HttpClientModule,
    MatTableModule,
    MatPaginatorModule,
    MatIconModule,
    MatDividerModule,
    MatFileUploadModule,
    MatSelectModule,
    AuthModule,
    MatProgressSpinnerModule,
    AppOverlayModule,
    BrowserAnimationsModule,
    MatBadgeModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDialogModule,
    MatTabsModule,
    ToastrModule.forRoot(),
    StoreModule.forRoot(reducers, {metaReducers}),
    TranslateModule.forRoot({
      loader: {
      provide: TranslateLoader,
      useFactory: HttpLoaderFactory,
      deps: [HttpClient]
      }
    })
  ],
  providers: [
    {
      provide: ENDPOINT_SETTINGS,
      useFactory: (settings: IAppSettings) => settings.endpoint,
      deps: [APP_SETTINGS]
    },
    { provide: APP_INITIALIZER, multi: true, useFactory: AppSettingsFactory, deps: [AppConfigService]},
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: window.navigator.language },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR' },
    {
      provide: HTTP_CLIENT_PROXY,
      useFactory: HTTP_CLIENT_PROXY_FACTORY,
      deps: [HttpClient, ENDPOINT_SETTINGS]
    },
    MatDatepickerModule,
    MatNativeDateModule,
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
