import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { BaseServiceProxy, IListData, ISingleData, OperationContract, OperationMethod, ServiceContract, ServiceOperation } from "../_helpers/contract.helper";
import { HttpClientProxy, HTTP_CLIENT_PROXY } from "../_helpers/http.helper";
import { FaqData, LogData, MarketingBannerData, UserData } from "../_services/admin.service";
import { AppConfigService } from "../_services/appconfig.service";

@Injectable({ providedIn: 'root' })
@ServiceContract({ uri: 'api/admin' })
export class AdminServiceProxy extends BaseServiceProxy {
    constructor(@Inject(HTTP_CLIENT_PROXY) proxy: HttpClientProxy, private http: HttpClient, private appSettings: AppConfigService) {
        super(proxy);
        }

    @OperationContract({ uri: 'marketingbanners', method: OperationMethod.Get })
    getMarketingBanners!: ServiceOperation<void, void, IListData<MarketingBannerData>>;

    @OperationContract({ uri: 'faqs', method: OperationMethod.Get })
    getFaqs!: ServiceOperation<void, void, IListData<FaqData>>;

    @OperationContract({ uri: 'enabledfaqs', method: OperationMethod.Get })
    getEnabledFaqs!: ServiceOperation<void, void, IListData<FaqData>>;

    @OperationContract({ uri: 'marketingbanners/delete/{id}', method: OperationMethod.Get })
    deleteMarketingBanner!: ServiceOperation<void, { id: string }, ISingleData<boolean>>;

    @OperationContract({ uri: 'faqs/{id}', method: OperationMethod.Get })
    getFaq!: ServiceOperation<void, { id: string }, ISingleData<FaqData>>;

    @OperationContract({ uri: 'faqs/delete/{id}', method: OperationMethod.Get })
    deleteFaq!: ServiceOperation<void, { id: string }, ISingleData<boolean>>;

    @OperationContract({ uri: 'faqs/edit', method: OperationMethod.Post })
    editFaq!: ServiceOperation<FaqData, void, ISingleData<boolean>>;

    @OperationContract({ uri: 'marketingbanners/edit', method: OperationMethod.Post })
    editMarketingBanner!: ServiceOperation<FormData, void, ISingleData<boolean>>;

    @OperationContract({ uri: 'marketingbanners/{id}', method: OperationMethod.Get })
    getMarketingBanner!: ServiceOperation<void, { id: string }, ISingleData<MarketingBannerData>>;

    getMarketingBannerPicture(id: string){
        return this.http.get(this.appSettings.apiEndpointUrl + "/api/admin/marketingbannersimage/" + id, { responseType: 'blob', observe: 'response'});
    }

    getEnabledMarketingBannerPicture(){
        return this.http.get(this.appSettings.apiEndpointUrl + "/api/admin/marketingbannersimage", { responseType: 'blob', observe: 'response'});
    }

    @OperationContract({ uri: 'logs', method: OperationMethod.Get })
    getLogs!: ServiceOperation<void, void, IListData<LogData>>;

    exportLogs(){
        return this.http.get(this.appSettings.apiEndpointUrl + "/api/admin/exportlogs", { responseType: 'blob', observe: 'response'});
    }

    @OperationContract({ uri: 'users', method: OperationMethod.Get })
    getUsers!: ServiceOperation<void, void, IListData<UserData>>;
}