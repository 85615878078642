import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { BaseServiceProxy, IListData, ISingleData, OperationContract, OperationMethod, ServiceContract, ServiceOperation } from "../_helpers/contract.helper";
import { HttpClientProxy, HTTP_CLIENT_PROXY } from "../_helpers/http.helper";
import { AdminData } from "../_services/admin.service";
import { AdminModel, SetPasswordData, SignInData } from "../_services/authentication.service";

@Injectable({ providedIn: 'root' })
@ServiceContract({ uri: 'api/auth' })
export class AuthServiceProxy extends BaseServiceProxy {
  constructor(@Inject(HTTP_CLIENT_PROXY) proxy: HttpClientProxy, private http: HttpClient) {
    super(proxy);
  }

  @OperationContract({ uri: 'setpassword', method: OperationMethod.Post })
  setPassword!: ServiceOperation<SetPasswordData, void, ISingleData<boolean>>;

  @OperationContract({ uri: 'signin', method: OperationMethod.Post })
  signIn!: ServiceOperation<SignInData, void, ISingleData<string>>;

  @OperationContract({ uri: 'logout', method: OperationMethod.Get })
  logOut!: ServiceOperation<void, void, ISingleData<boolean>>;

  @OperationContract({ uri: 'resetpassword/{email}', method: OperationMethod.Get })
  resetPassword!: ServiceOperation<void, { email: string }, ISingleData<boolean>>;

  @OperationContract({ uri: 'usercount', method: OperationMethod.Get })
  getUserCount!: ServiceOperation<void, void, ISingleData<number>>;

  @OperationContract({ uri: 'createSuperAdmin', method: OperationMethod.Post })
  createSuperAdmin!: ServiceOperation<AdminModel, void, ISingleData<boolean>>;

  @OperationContract({ uri: 'createAdmin', method: OperationMethod.Post })
  createAdmin!: ServiceOperation<AdminModel, void, ISingleData<boolean>>;

  @OperationContract({ uri: 'admins', method: OperationMethod.Get })
  getAdmins!: ServiceOperation<void, void, IListData<AdminData>>;

  @OperationContract({ uri: 'deleteadmin/{id}', method: OperationMethod.Get })
  deleteAdmin!: ServiceOperation<void, { id: string }, ISingleData<boolean>>;

  @OperationContract({ uri: 'synchronize/{id}', method: OperationMethod.Get })
  synchronizeEvent!: ServiceOperation<void, { id: string }, ISingleData<boolean>>;

  @OperationContract({ uri: 'synchronize', method: OperationMethod.Get })
  synchronizeAll!: ServiceOperation<void, void, ISingleData<boolean>>;
}