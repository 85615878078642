<mat-divider></mat-divider>
<div class="users">
    <div class="users-title" fxLayout="row">
        <mat-icon>person</mat-icon>
        <p> {{ 'Users.form-title' | translate }} </p>
    
        <!-- <button mat-flat-button (click)="createNewUser()">{{ 'Users.new-user-button' | translate }}</button> -->
    </div> 

  <div class="users-search">
    <input matInput [(ngModel)]="defaultSearch" (keyup)="applySearch($event.target.value)" placeholder="{{ 'Users.search' | translate }}">
  </div>
  <table *ngIf="showTable" mat-table [dataSource]="usersDataSource" class="mat-elevation-z0" (mouseout)="removeHighlightedRow()">
    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element"><mat-icon [ngClass] = "{'highlight-description' : element.id == selectedUser }" class="mat-icon-description">person</mat-icon></td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> {{ 'Users.table-name-column' | translate }} </th>
      <td mat-cell *matCellDef="let element"> {{ element.name }} </td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef> {{ 'Users.table-email-column' | translate }} </th>
      <td mat-cell *matCellDef="let element"> {{ element.email }} </td>
    </ng-container>

    <ng-container matColumnDef="activated">
      <th mat-header-cell *matHeaderCellDef> {{ 'Users.table-activated-column' | translate }} </th>
      <td mat-cell *matCellDef="let element"> {{ (element.activated ? 'General.Yes': 'General.No') | translate }} </td>
    </ng-container>

    <!-- <ng-container matColumnDef="button">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell class="td-button" *matCellDef="let element" [ngClass] = "{'highlight-td' : element.id == selectedUser}"><button mat-icon-button color="primary" (click)="deleteAdmin(element.id)" title="Delete Admin"><mat-icon class="mat-icon-arrow">delete</mat-icon></button></td>
    </ng-container> -->

    <tr mat-header-row *matHeaderRowDef="usersDisplayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: usersDisplayedColumns;" (mouseover)="highlightRow(row)"></tr>
  </table>

  <mat-paginator [pageSize]="10">
  </mat-paginator>

  <div *ngIf="!showTable">
      <p>{{ 'Users.no-users' | translate }}</p>
  </div>

</div>



