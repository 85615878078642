import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthFacade } from '../auth';
import { AdminData, AdminService, FaqData } from '../_services/admin.service';
import { AuthService } from '../_services/authentication.service';

@Component({
  selector: 'app-admins',
  templateUrl: './admins.component.html',
  styleUrls: ['./admins.component.css']
})
export class AdminsComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  admins: AdminData[] = [];
  @Input() selectedAdmin: any;
  id = '';
  showTable = false;

  adminsDisplayedColumns: string[] = ['description', 'name', 'email', 'activated', 'button'];
  adminsDataSource = new MatTableDataSource<AdminData>(this.admins);

  constructor(
    private authService: AuthService,
    private router: Router,
    private toastr: ToastrService
  ) {
  }

  ngOnInit() {
    this.authService.getAdmins().subscribe((result) => {
      this.admins = result;
      this.showTable = result.length > 0;
      this.adminsDataSource = new MatTableDataSource(this.admins);
      this.adminsDataSource.paginator = this.paginator;
    });
  }

  deleteAdmin(id: any){
    this.authService.deleteAdmin(id).subscribe(result => {
      if(result) {
        this.showToasterSuccess('Admin deleted successfully.');
        this.admins = this.admins.filter(x => x.id != id);
        this.adminsDataSource = new MatTableDataSource(this.admins);
        this.showTable = this.admins.length > 0;
      }
      else{
        this.showToasterError('Failed to delete Admin.')
      }
    })
  }

  highlightRow(admin) {
    this.selectedAdmin = admin.id
  }

  removeHighlightedRow(){
    this.selectedAdmin = null;
  }

  createNewAdmin(){
    this.router.navigate(['/createadmin']);
  }

  //toast
  showToasterSuccess(message){
    this.toastr.success(message, 'Success', {
      positionClass: 'toast-bottom-center',
      progressAnimation: 'decreasing',
      timeOut: 5000,
    });
  }

  showToasterError(message){
    this.toastr.error(message, 'Error', {
      positionClass: 'toast-bottom-center',
      progressAnimation: 'decreasing',
      timeOut: 5000,
    });
  }
}