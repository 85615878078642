import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AdminData, AdminService, UserData } from '../_services/admin.service';
import { AuthService } from '../_services/authentication.service';

enum IntegrationEvent{
  ContactPerson = 0,
  FactoringAgreement = 1,
  FactoringAgreementDebtVendor = 2,
  FactoringInvoice = 3,
  FactoringCreditLimit = 5,
}


@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit {
  selectedEvent = IntegrationEvent.ContactPerson;
  availableEvents = [IntegrationEvent.ContactPerson, 
                     IntegrationEvent.FactoringAgreement, 
                     IntegrationEvent.FactoringAgreementDebtVendor, 
                     IntegrationEvent.FactoringCreditLimit, 
                     IntegrationEvent.FactoringInvoice];

  id = '';
  showTable = false;

  constructor(
    private authService: AuthService,
    private router: Router,
    private toastr: ToastrService
  ) {
  }

  ngOnInit() {
    
  }

  fullSync(){
    this.authService.synchronizeAll().subscribe((result => {
        if(result){
          this.showToasterSuccess('Full Synchronization events created');
        }
        else{
          this.showToasterError('Failed to create full synchronization events');
        }
    }));
  }

  selectedSync(){
    this.authService.synchronizeEvent(this.selectedEvent.toString()).subscribe((result => {
      if(result){
        this.showToasterSuccess('Synchronization event created');
      }
      else{
        this.showToasterError('Failed to create synchronization event');
      }
    }));
  }

  getEventLabel(option: IntegrationEvent) {
    switch (option) {
      case IntegrationEvent.ContactPerson:
        return "Contact Person";
      case IntegrationEvent.FactoringAgreement:
        return "Factoring Agreement";
      case IntegrationEvent.FactoringInvoice:
        return "Factoring Invoice";
      case IntegrationEvent.FactoringAgreementDebtVendor:
        return "Factoring Agreement Debtor/Vendor";
      case IntegrationEvent.FactoringCreditLimit:
        return "Factoring Credit Limit";
      default:
        throw new Error("Unsupported option");
    }
  }

  //toast
  showToasterSuccess(message){
    this.toastr.success(message, 'Success', {
      positionClass: 'toast-bottom-center',
      progressAnimation: 'decreasing',
      timeOut: 5000,
    });
  }

  showToasterError(message){
    this.toastr.error(message, 'Error', {
      positionClass: 'toast-bottom-center',
      progressAnimation: 'decreasing',
      timeOut: 5000,
    });
  }
}