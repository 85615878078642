<mat-divider></mat-divider>

<div class="admin-details">
  <div class="admin-details-title" fxLayoutAlign="space-between">
    <p>{{ 'Admin-Details.title' | translate }}</p>
    <a href="./admins">{{ 'Admin-Details.back-button-title' | translate }}</a>
  </div>
    
    <div class="admin-details-form">
        <form [formGroup]="form" fxLayout="column" (ngSubmit)="onSubmit()">
            <mat-form-field >
                <mat-label>{{ 'Admin-Details.form-name' | translate }}</mat-label>
                <input matInput formControlName="name" placeholder="{{ 'Admin-Details.form-name-placeholder' | translate }}">
            </mat-form-field>
            
            <mat-form-field >
              <mat-label>{{ 'Admin-Details.form-email' | translate }}</mat-label>
              <input matInput formControlName="email" placeholder="{{ 'Admin-Details.form-email-placeholder' | translate }}">
            </mat-form-field>
    
            <div class="send-button" fxLayout="row" fxLayoutAlign="space-between center">
                <button [disabled]="!form.valid" [ngClass]="{'disabled' : !form.valid}" mat-flat-button>{{ 'Admin-Details.create-button' | translate }}</button>
            </div>
        </form>
    </div>
</div>

<app-progress-spinner  [color]="color" [mode]="mode" [value]="value" [backdropEnabled]="true" [positionGloballyCenter]="true"
 [displayProgressSpinner]="displayProgressSpinner">
</app-progress-spinner>