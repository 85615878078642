import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { AuthGuard } from './auth.guard';

import { AuthInterceptor } from './auth.interceptor';
import { AUTH_FEATURE_KEY, AuthFacade, authReducer } from './state';



// TODO global resolve constant? as argument?

@NgModule({
  imports: [StoreModule.forFeature(AUTH_FEATURE_KEY, authReducer)],
  providers: [
    AuthFacade,
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ]
})
export class AuthModule {}
