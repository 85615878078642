<mat-divider></mat-divider>

<table mat-table [dataSource]="agreementsDataSource" class="mat-elevation-z0" (mouseout)="removeHighlightedRow()">
  <ng-container matColumnDef="description">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element"><mat-icon [ngClass] = "{'highlight-description' : element.id == selectedAgreement}" class="mat-icon-description">description</mat-icon></td>
  </ng-container>

  <ng-container matColumnDef="agreementno">
    <th mat-header-cell *matHeaderCellDef> {{ 'Agreements.table-agreement-no-column' | translate }} </th>
    <td mat-cell *matCellDef="let element"> {{ element.clientName }} </td>
  </ng-container>

  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef> {{ 'Agreements.table-status-column' | translate }} </th>
    <td mat-cell *matCellDef="let element"> {{ element.statusTranslation | translate }} </td>
  </ng-container>

  <!-- <ng-container matColumnDef="disclosedundisclosed">
    <th mat-header-cell *matHeaderCellDef> {{ 'Agreements.table-disclosed-undisclosed-column' | translate }} </th>
    <td mat-cell *matCellDef="let element"> {{ element.disclosedUndisclosed | translate}} </td>
  </ng-container> -->

  <ng-container matColumnDef="contracteffectivedate">
    <th mat-header-cell *matHeaderCellDef> {{ 'Agreements.table-contract-effective-date-column' | translate }} </th>
    <td mat-cell *matCellDef="let element"> {{element.contractEffectiveDate | date:'dd.MM.yyyy' }} </td>
  </ng-container>

  <!-- <ng-container matColumnDef="contractterm">
      <th mat-header-cell *matHeaderCellDef> {{ 'Agreements.table-contract-term-column' | translate }} </th>
      <td mat-cell *matCellDef="let element"> {{element.contractTerm | date:'dd.MM.yyyy' }} </td>
  </ng-container> -->

  <ng-container matColumnDef="button">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell class="td-button" *matCellDef="let element" [ngClass] = "{'highlight-td' : element.id == selectedAgreement}"><button mat-icon-button color="primary" (click)="openAgreementDetails(element.systemId)" title="View Agreement"><mat-icon class="mat-icon-arrow">keyboard_arrow_right</mat-icon></button></td>
</ng-container>

  <tr mat-header-row *matHeaderRowDef="agreementsDisplayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: agreementsDisplayedColumns;" (mouseover)="highlightRow(row)"></tr>
</table>

<mat-paginator [pageSize]="10">
</mat-paginator>
