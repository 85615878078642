import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { AuthFacade } from '../auth';
import { JwtData } from '../auth/state/auth.reducer';
import { AdminService, FaqData } from '../_services/admin.service';
import jwt_decode from "jwt-decode";

@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.css']
})
export class FaqsComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  faqs: FaqData[] = [];
  @Input() selectedFaq: any;
  isAdmin = false;

  showTable = false;

  faqsDisplayedColumns: string[] = ['description', 'question', 'answer', 'isEnabled', 'button'];
  faqsDataSource = new MatTableDataSource<FaqData>(this.faqs);

  constructor(
    private adminService: AdminService,
    private router: Router,
    private authFacade: AuthFacade
  ) {
    this.authFacade.token$.subscribe((token => {
      let decodedToken: JwtData = jwt_decode(token)
      this.isAdmin = decodedToken.admin.toLowerCase() == 'true' || decodedToken.superAdmin.toLowerCase() == 'true';
    }));
  }

  ngOnInit() {
    if(this.isAdmin){
      this.adminService.getFaqs().subscribe((result) => {
        this.faqs = result;
        this.showTable = result.length > 0;
        this.faqsDataSource = new MatTableDataSource(this.faqs);
        this.faqsDataSource.paginator = this.paginator;
      });
    }
    else{
      this.adminService.getEnabledFaqs().subscribe((result) => {
        this.faqs = result;
        this.showTable = result.length > 0;
        this.faqsDataSource = new MatTableDataSource(this.faqs);
        this.faqsDataSource.paginator = this.paginator;
      });
    }
   
  }

  applySearch(searchValue: string) {
    searchValue = searchValue.trim(); 
    searchValue = searchValue.toLowerCase(); 
    this.faqsDataSource.filter = searchValue;
  }

  openFaqEdit(id: any){
    this.router.navigate(['/faqs', id]);
  }

  deleteFaq(id: any){
    this.adminService.deleteFaq(id).subscribe(result => {
      console.log(result)
    })
  }

  highlightRow(contract) {
    this.selectedFaq = contract.id;
  }

  removeHighlightedRow(){
    this.selectedFaq = null;
  }

  createNewFaq(){
    this.router.navigate(['/faqs', 0]);
  }
}
