import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { ToastrService } from "ngx-toastr";
import { Observable } from "rxjs";
import { BaseStoreFacade } from "src/app/_helpers/store.helper";
import { AuthService, SignInData } from "src/app/_services/authentication.service";
import { ResetTokenAction, SignInExternalAction, SignInSuccessAction, SignOutSuccessAction } from "./auth.actions";
import { AuthPartialState } from './auth.reducer';
import { authQuery } from './auth.selector';

@Injectable()
export class AuthFacade extends BaseStoreFacade<AuthPartialState> {
    token$ = this.store.select(authQuery.token);
    // jwtData$ = this.store.select(authQuery.jwtData);

    constructor(private service: AuthService, store: Store<AuthPartialState>, private toastr: ToastrService) {
        super(store);
        this.toastr.toastrConfig.preventDuplicates = true;
        this.toastr.toastrConfig.countDuplicates = true;
    }

    signIn(data: SignInData): Observable<string> {
        return this.fetch({
            execute: () => this.service.signIn(data),
            success: result => new SignInSuccessAction(result),
            failure: () => this.showToasterError()
        });
    }

    signInExternal(token: string) {
        this.store.dispatch(new SignInExternalAction(token));
    }

    signOut(): Observable<boolean> {
        return this.fetch({
          execute: () => this.service.logOut(),
          success: () => new SignOutSuccessAction()
        });
    }

    resetToken(){
        this.store.dispatch(new ResetTokenAction());
    }

    showToasterError(){
        this.toastr.error('Failed to sign in. Check if you entered credentials correctly.', 'Error', {
          positionClass: 'toast-bottom-center',
          progressAnimation: 'decreasing',
          timeOut: 5000,
        });
    }
}