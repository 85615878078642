import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { formatDate, getCurrencySymbol } from '@angular/common';
// import { AssetData, ContractData, ContractScheduleLineData, ContractsService, ContractStatus, DEFAULT_CURRENCY, ExtendedContractData } from '../_services/contracts.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { AuthFacade } from '../auth';
import { AppConfigService } from '../_services/appconfig.service';
import { AdminService } from '../_services/admin.service';
import { DomSanitizer } from '@angular/platform-browser';
import { AgrDebtorsVendorsStatusTranslation, AgreementData, AgreementsService, AgreementStatus, AgreementStatusTranslation, AgreementVendorDebtorData, CommissionUpfrontType, CreditLimitData, DEFAULT_CURRENCY, FactoringType, FactoringTypeTranslation, InvoiceData, InvoiceStatus, InvoiceStatusTranslation } from '../_services/agreements.service';
import { MatDialog } from '@angular/material/dialog';
import { UploadDocumentsDialog } from '../upload-documents-dialog/upload-documents-dialog.component';
import { CreateInvoiceComponent } from '../createinvoice/createinvoice.component';
import { JwtData } from '../auth/state/auth.reducer';
import jwt_decode from "jwt-decode";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-agreement-details',
  templateUrl: './agreement-details.component.html',
  styleUrls: ['./agreement-details.component.scss']
})
export class AgreementDetailsComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Input() id: string = '';
  @Input() agreementTitle: string = '';
  @Input() status: string = '';
  @Input() agreementType;

  form!: FormGroup;
  agreement: AgreementData = null;
  invoices: InvoiceData[] = [];
  creditLimitList: CreditLimitData[] = [];
  vendorsDebtorsList: AgreementVendorDebtorData[] = [];

  invoicesDisplayedColumns: string[] = ['description', 'invoiceid', 'status', 'invoicedate', 'invoiceduedate', 'invoiceamount'];
  creditLimitDisplayedColumns: string[] = ['creditlimit', 'availableamount', 'utilisedamount'];
  // creditLimitDisplayedColumns: string[] = ['creditlimit', 'availableamount', 'utilisedamount', 'validfrom', 'validuntil'];
  debtorsDisplayedColumns: string[] = ['name', 'status', 'registrationNo', 'utilisedAmount', 'availableAmount'];

  invoicesDataSource = new MatTableDataSource<InvoiceData>(this.invoices);

  currencyCode = DEFAULT_CURRENCY;
  customerId = '';

  allSelected = true;
  newSelected = false;
  openSelected = false;
  archiveSelected = false;
  showPercentage = false;
  defaultSearch = '';

  //spinner properties
  color = 'primary';
  mode = 'indeterminate';
  value = 50;
  displayProgressSpinner = false;
  spinnerWithoutBackdrop = false;
  
  showAd = false;
  banner: any;

  constructor(
    private agreementsService: AgreementsService, 
    private router: Router, 
    private route: ActivatedRoute,
    private formBuilder: FormBuilder, 
    private toastr: ToastrService,
    private authFacade: AuthFacade,
    private sanitizer: DomSanitizer,
    private dialog: MatDialog,
    private adminService: AdminService,
    private translate: TranslateService
  ) { 
    this.authFacade.token$.subscribe((token => {
      let decodedToken: JwtData = jwt_decode(token)
      this.customerId = decodedToken.customerId;
    }));
  }

  ngOnInit() {
    this.adminService.getEnabledMarketingBannerPicture().subscribe(result => {
      let filename = '';
          if(result.headers.get('content-disposition')){
            filename = result.headers.get('content-disposition').split(';')[1].split('filename')[1].split('=')[1].trim();
            filename = filename.split('"')[1] === undefined ? filename : filename.split('"')[1].trim();
            var objectURL = URL.createObjectURL(new Blob([result.body], { type: result.body.type }));
            this.banner = this.sanitizer.bypassSecurityTrustUrl(objectURL);
            this.showAd = filename != 'null.txt';
          }
          else{
            this.showAd = false;
          }
    });

    this.refreshForm();
    this.id = this.route.snapshot.params.id;
    this.agreementsService.getAgreement(this.id).subscribe((result) => {
      this.agreement = result;
      this.agreementTitle = this.agreement.id;
      this.showPercentage = result.commissionUpfrontType === CommissionUpfrontType.Percentage;
      this.status = AgreementStatusTranslation[AgreementStatus[result.status]];
      this.agreementType = this.agreement.type;
      this.agreement.typeText = FactoringType[result.type].replace(/([A-Z])/g, ' $1').trim();
      this.agreement.typeTranslation = this.translate.instant(FactoringTypeTranslation[FactoringType[result.type]]);
      this.agreement.commissionUpfrontTypeText = CommissionUpfrontType[result.commissionUpfrontType].replace(/([A-Z])/g, ' $1').trim();
      this.refreshForm();
    });

    this.agreementsService.getAgreementInvoices(this.id).subscribe((result) => {
      this.invoices = result.map(el => {
        el.statusText = InvoiceStatus[el.status].replace(/([A-Z])/g, ' $1').trim();
        el.statusTranslation = InvoiceStatusTranslation[InvoiceStatus[el.status]];
        return el;
      });

      this.invoicesDataSource = new MatTableDataSource(this.invoices);
      this.invoicesDataSource.paginator = this.paginator;
    });

    this.agreementsService.getCreditLimit('agreement', this.id).subscribe((result) => {
      this.creditLimitList = result;
      this.creditLimitList = result.map((limit) => {
        this.agreementsService.getCreditLimitAmounts(this.id, 'agreement').subscribe((result) => {
          limit.utilisedAmount = result.utilisedCreditLimit;
          limit.availableAmount = result.availableCreditLimit;
        });
        return limit;
      });
     });

     this.agreementsService.getAgreementVendorsDebtorsExtended(this.id).subscribe((result) => {
      this.vendorsDebtorsList = result.map((el)=> {
        el.statusTranslation = AgrDebtorsVendorsStatusTranslation[el.status];
        el.availableAmount = el.availableAmount ? el.availableAmount : "Not Available";
        el.utilisedAmount = el.utilisedAmount ? el.utilisedAmount : "Not Available";
        return el;
      });
    });
  }

  private refreshForm(){
    this.form = this.formBuilder.group({ 
      id: [(this.agreement && this.agreement.id) || undefined],
      statusText: [(this.agreement && this.agreement.statusText) || undefined],
      typeText: [(this.agreement && this.agreement.typeText) || undefined],
      typeTranslation: [(this.agreement && this.agreement.typeTranslation) || undefined],
      disclosedUndisclosed: [(this.agreement && this.agreement.disclosedUndisclosed) || undefined],
      status: [(this.agreement && this.agreement.status) || undefined],
      contractEffectiveDate: [(this.agreement && this.getDate(this.agreement.contractEffectiveDate) && formatDate(this.getDate(this.agreement.contractEffectiveDate), 'dd.MM.yyyy', 'en-us')) || undefined],
      contractTerm: [(this.agreement && this.getDate(this.agreement.contractTerm) && formatDate(this.getDate(this.agreement.contractTerm), 'dd.MM.yyyy', 'en-us')) || undefined],
      clientId: [(this.agreement && this.agreement.clientId) || undefined],
      clientName: [(this.agreement && this.agreement.clientName) || undefined],
      clientRegistrationId: [(this.agreement && this.agreement.clientRegistrationId) || undefined],
      clientVATRegistrationId: [(this.agreement && this.agreement.clientVATRegistrationId) || undefined],
      clientPhoneNo: [(this.agreement && this.agreement.clientPhoneNo) || undefined],
      clientEMail: [(this.agreement && this.agreement.clientEMail) || undefined],
      clientBankAccNo: [(this.agreement && this.agreement.clientBankAccNo) || undefined],
      commissionUpfrontTypeText: [(this.agreement && this.agreement.commissionUpfrontTypeText) || undefined],
      numberOutstandingInvoices: [(this.agreement && this.agreement.numberOutstandingInvoices) || 0],
      numberClosedInvoices: [(this.agreement && this.agreement.numberClosedInvoices) || 0],
      advance: [(this.agreement && this.agreement.advance) || 0], 
      commissionUpfront: [(this.agreement && this.agreement.commissionUpfront) || 0], 
      commissionAmountUpfront: [this.getCurrency(((this.agreement && this.agreement.commissionAmountUpfront) || 0), this.currencyCode)], 
      commissionDeferred: [(this.agreement && this.agreement.commissionDeferred) || 0], 
      interestAPR: [(this.agreement && this.agreement.interestAPR) || 0], 
      increasedInterestAPR: [(this.agreement && this.agreement.increasedInterestAPR) || 0], 
      penaltyInterestDaily: [(this.agreement && this.agreement.penaltyInterestDaily) || 0], 
      advancePaid: [this.getCurrency(((this.agreement && this.agreement.advancePaid) || 0), this.currencyCode)], 
      advancePending: [this.getCurrency(((this.agreement && this.agreement.advancePending) || 0), this.currencyCode)], 
      debtorCollections: [this.getCurrency(((this.agreement && this.agreement.debtorCollections) || 0), this.currencyCode)], 
      termChargesTotal: [this.getCurrency(((this.agreement && this.agreement.termChargesTotal) || 0), this.currencyCode)], 
      interestAmount: [this.getCurrency(((this.agreement && this.agreement.interestAmount) || 0), this.currencyCode)], 
      empty: [undefined]
     })
  }

  filterNew(){
    let newInvoices = this.invoices.filter(x => x.status == InvoiceStatus.New || x.status == InvoiceStatus.NotificationSent);
    this.invoicesDataSource = new MatTableDataSource(newInvoices);
    this.changeSelected('new');
  }

  filterOpen(){
    let newInvoices = this.invoices.filter(x => x.status == InvoiceStatus.Accepted || x.status == InvoiceStatus.Disbursed || x.status == InvoiceStatus.Overdue);
    this.invoicesDataSource = new MatTableDataSource(newInvoices);
    this.changeSelected('open');
  }

  filterArchive(){
    let newInvoices = this.invoices.filter(x => x.status == InvoiceStatus.Closed || x.status == InvoiceStatus.Rejected);
    this.invoicesDataSource = new MatTableDataSource(newInvoices);
    this.changeSelected('archive');
  }

  filterAll(){
    this.invoicesDataSource = new MatTableDataSource(this.invoices);
    this.changeSelected('all');
  }

  changeSelected(type: string){
    this.allSelected = false;
    this.newSelected = false;
    this.openSelected = false;
    this.archiveSelected = false;

    if(type == 'all')
      this.allSelected = true;
    if(type == 'new')
      this.newSelected = true;
    if(type == 'open')
      this.openSelected = true;
    if(type == 'archive')
      this.archiveSelected = true;
  }

  getCurrency(value: number, currencyCode: string){
    var formattedValue = this.formatDecimalToUseComma(value);
    return `${formattedValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} ${getCurrencySymbol(currencyCode, 'narrow')}`;
  }
  
  formatDecimalToUseComma(input) {
    var ret = (input) ? input.toString().replace(".",",") : '0';
    if(ret) {
        var decArr=ret.split(",");
        if(decArr.length>1){
            var dec=decArr[1].length;
            if(dec===1){ret+="0";}
        }
    }
    return ret;
  }

  getDate(date: Date) : Date{
    return date.toString() == '0001-01-01T00:00:00' ? undefined : date;
  }

  createNewInvoice(){
    this.router.navigate(['/createinvoice/'+this.id]);
  }

  downloadAgreement(){
    this.showProgressSpinner()
    this.agreementsService.downloadAgreement(this.id).subscribe((result => {
      if(result.body['size'] > 0){
        var filename = result.headers.get('content-disposition').split(';')[1].split('filename')[1].split('=')[1].trim();
        const downloadedFile = new Blob([result.body], { type: result.body.type });
        const a = document.createElement('a');
        a.setAttribute('style', 'display:none;');
        document.body.appendChild(a);
        a.href = URL.createObjectURL(downloadedFile);
        a.download = filename;
        a.target = '_blank';
        this.hideProgressSpinner();
        a.click();
        document.body.removeChild(a);
      }
      else{
        this.hideProgressSpinner();
        this.showToasterError()
      }
    }));
  }

  backToContracts(){
    this.router.navigate(['/home']);
  }

  uploadDocument(){
    const dialogRef = this.dialog.open(UploadDocumentsDialog, {
      restoreFocus: false,
      width: '50%',
      height: '50%',
      data: {agreementId: this.id},
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  applySearch(searchValue: string) {
    searchValue = searchValue.trim(); 
    searchValue = searchValue.toLowerCase(); 
    this.invoicesDataSource.filter = searchValue;
  }

  createInvoice(){
    //this.creatingInvoice = true;
    const dialogRef = this.dialog.open(CreateInvoiceComponent, {
      restoreFocus: false,
      width: '60%',
      height: '80%',
      data: {agreementId: this.agreement.systemId},
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  //spinner methods
  showProgressSpinner = () => {
    this.displayProgressSpinner = true;
  };

  hideProgressSpinner = () => {
    this.displayProgressSpinner = false;
  };

  //toast

  showToasterError(){
    this.toastr.error('Failed to download agreement.', 'Error', {
      positionClass: 'toast-bottom-center',
      progressAnimation: 'decreasing',
      timeOut: 5000,
    });
  }
}