import { Action, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { share, tap } from 'rxjs/operators';

import { ProcessOptions } from './type.helper';

export interface FetchOptions<TResult> extends ProcessOptions<TResult, Action | void, Action | void> {}

export abstract class BaseStoreFacade<TPartialState> {
  protected constructor(protected store: Store<TPartialState>) {}

  protected fetch<TResult>(opts: FetchOptions<TResult>): Observable<TResult> {
    return opts.execute().pipe(
      tap(this.run(opts.success), this.run(opts.failure)),
      share()
    );
  }

  private run<TData>(func?: (data: TData) => Action | void) {
    return (data: TData) => {
      if (!func) return;
      const action = func(data);
      if (action) this.store.dispatch(action);
    };
  }
}
