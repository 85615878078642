<mat-divider></mat-divider>

<div class="agreement-details">
    <div class="agreement-details-back" fxLayoutAlign="end">
        <a href="./agreements">{{ 'Agreement-Details.back-button-title' | translate }}</a>
    </div>
    <div class="agreement-details-header">
        <div class="agreement-details-header-title" fxLayout="row">
            <mat-icon>description</mat-icon>
            <!-- <p> {{ 'Agreement-Details.form-title' | translate }} {{ agreementTitle }} - {{ status | translate }}</p> -->
            <p> {{ 'Agreement-Details.form-title' | translate }} - {{ status | translate }}</p>
            <!-- <div class="agreement-details-buttons">
                <button mat-flat-button (click)="downloadAgreement()">{{ 'Agreement-Details.button-download-agreement' | translate }}</button>
                <button mat-flat-button (click)="uploadDocument()">{{ 'Agreement-Details.button-upload-document' | translate }}</button>
            </div> -->
        </div>
        <mat-divider></mat-divider>
            <form [formGroup]="form" fxLayout="column" class="agreement-details-form" >
                <div class="agreement-details-row" fxLayout="row" fxLayoutAlign="space-between center">
                    <div class="agreement-details-property" fxLayout="column">
                        <mat-label>{{ 'Agreement-Details.form-client-name' | translate }}</mat-label>
                        <mat-form-field [style.marginBottom]="'-1em'" appearance="none">
                            <input class="overflow" matInput formControlName="clientName">
                        </mat-form-field>
                    </div>
    
                    <div class="agreement-details-property" fxLayout="column">
                        <mat-label>{{ 'Agreement-Details.form-type' | translate }}</mat-label>
                        <mat-form-field [style.marginBottom]="'-1em'"appearance="none">
                            <input class="overflow" matInput formControlName="typeTranslation" >
                        </mat-form-field>
                    </div>
                    <div class="agreement-details-property" fxLayout="column">
                        <mat-label>{{ 'Agreement-Details.form-contract-effective-date' | translate }}</mat-label>
                        <mat-form-field [style.marginBottom]="'-1em'" appearance="none">
                            <input class="overflow" matInput formControlName="contractEffectiveDate">
                        </mat-form-field>
                    </div>
    
                    <!-- <div class="agreement-details-property" fxLayout="column">
                        <mat-label>{{ 'Agreement-Details.form-contract-term' | translate }}</mat-label>
                        <mat-form-field [style.marginBottom]="'-1em'" appearance="none">
                            <input class="overflow" matInput formControlName="contractTerm">
                        </mat-form-field>
                    </div> -->
                </div>

                <mat-divider></mat-divider>
    
                <div class="agreement-details-row" fxLayout="row" fxLayoutAlign="space-between center">
                    <div class="agreement-details-property" fxLayout="column">
                        <mat-label>{{ 'Agreement-Details.form-client-phone-no' | translate }}</mat-label>
                        <mat-form-field [style.marginBottom]="'-1em'" appearance="none">
                            <input class="overflow" matInput formControlName="clientPhoneNo" >
                        </mat-form-field>
                    </div>
                    
                    <div class="agreement-details-property" fxLayout="column">
                        <mat-label>{{ 'Agreement-Details.form-client-eMail' | translate }}</mat-label>
                        <mat-form-field [style.marginBottom]="'-1em'" appearance="none">
                            <input class="overflow" matInput formControlName="clientEMail">
                        </mat-form-field>
                    </div>
    
                    <div class="agreement-details-property" fxLayout="column">
                        <mat-label>{{ 'Agreement-Details.form-client-bank-acc-no' | translate }}</mat-label>
                        <mat-form-field [style.marginBottom]="'-1em'" appearance="none">
                            <input class="overflow" matInput formControlName="clientBankAccNo">
                        </mat-form-field>
                    </div>
                </div>
            
                <mat-divider></mat-divider>
    
                <!-- <div class="agreement-details-row" fxLayout="row" fxLayoutAlign="space-between center">
                    <div class="agreement-details-property" fxLayout="column">
                        <mat-label>{{ 'Agreement-Details.form-advance' | translate }}</mat-label>
                        <mat-form-field [style.marginBottom]="'-1em'" appearance="none">
                            <input class="overflow" matInput formControlName="advance" >
                        </mat-form-field>
                    </div>
                    
                    <div class="agreement-details-property" fxLayout="column">
                        <mat-label>{{ 'Agreement-Details.form-advance-paid' | translate }}</mat-label>
                        <mat-form-field [style.marginBottom]="'-1em'" appearance="none">
                            <input class="overflow" matInput formControlName="advancePaid">
                        </mat-form-field>
                    </div>
    
                    <div class="agreement-details-property" fxLayout="column">
                        <mat-label>{{ 'Agreement-Details.form-advance-pending' | translate }}</mat-label>
                        <mat-form-field [style.marginBottom]="'-1em'" appearance="none">
                            <input class="overflow" matInput formControlName="advancePending">
                        </mat-form-field>
                    </div>
    
                    <div class="agreement-details-property" fxLayout="column">
                        <mat-form-field [style.marginBottom]="'-1em'" appearance="none">
                            <input class="overflow" matInput formControlName="empty">
                        </mat-form-field>
                    </div>
                </div>
    
                <mat-divider></mat-divider>
    
                <div class="agreement-details-row" fxLayout="row" fxLayoutAlign="space-between center">
                    <div class="agreement-details-property" fxLayout="column">
                        <mat-label>{{ 'Agreement-Details.form-commission-upfront-type' | translate }}</mat-label>
                        <mat-form-field [style.marginBottom]="'-1em'" appearance="none">
                            <input class="overflow" matInput formControlName="commissionUpfrontTypeText" >
                        </mat-form-field>
                    </div>
    
                    <div *ngIf="showPercentage" class="agreement-details-property" fxLayout="column">
                        <mat-label>{{ 'Agreement-Details.form-commission-upfront' | translate }}</mat-label>
                        <mat-form-field [style.marginBottom]="'-1em'" appearance="none">
                            <input class="overflow" matInput formControlName="commissionUpfront">
                        </mat-form-field>
                    </div>
    
                    <div *ngIf="!showPercentage" class="agreement-details-property" fxLayout="column">
                        <mat-label>
                            {{ 'Agreement-Details.form-commission-amount-upfront' | translate }}</mat-label>
                        <mat-form-field [style.marginBottom]="'-1em'" appearance="none">
                            <input class="overflow" matInput formControlName="commissionAmountUpfront">
                        </mat-form-field>
                    </div>
                    
                    <div class="agreement-details-property" fxLayout="column">
                        <mat-label>{{ 'Agreement-Details.form-commission-deferred' | translate }}</mat-label>
                        <mat-form-field [style.marginBottom]="'-1em'" appearance="none">
                            <input class="overflow" matInput formControlName="commissionDeferred">
                        </mat-form-field>
                    </div>
    
                    <div class="agreement-details-property" fxLayout="column">
                        <mat-form-field [style.marginBottom]="'-1em'" appearance="none">
                            <input class="overflow" matInput formControlName="empty">
                        </mat-form-field>
                    </div>
                </div>
    
                <mat-divider></mat-divider>
    
                <div class="agreement-details-row" fxLayout="row" fxLayoutAlign="space-between center">
                    <div class="agreement-details-property" fxLayout="column">
                        <mat-label>{{ 'Agreement-Details.form-interest-APR' | translate }}</mat-label>
                        <mat-form-field [style.marginBottom]="'-1em'" appearance="none">
                            <input class="overflow" matInput formControlName="interestAPR">
                        </mat-form-field>
                    </div>
    
                    <div class="agreement-details-property" fxLayout="column">
                        <mat-label>{{ 'Agreement-Details.form-increased-interest-APR' | translate }}</mat-label>
                        <mat-form-field [style.marginBottom]="'-1em'" appearance="none">
                            <input class="overflow" matInput formControlName="increasedInterestAPR">
                        </mat-form-field>
                    </div>
    
                    <div class="agreement-details-property" fxLayout="column">
                        <mat-label>
                            {{ 'Agreement-Details.form-penalty-interest-daily' | translate }}</mat-label>
                        <mat-form-field [style.marginBottom]="'-1em'" appearance="none">
                            <input class="overflow" matInput formControlName="penaltyInterestDaily">
                        </mat-form-field>
                    </div>
                    
                    <div class="agreement-details-property" fxLayout="column">
                        <mat-form-field [style.marginBottom]="'-1em'" appearance="none">
                            <input class="overflow" matInput formControlName="empty">
                        </mat-form-field>
                    </div>
                </div>
    
                <mat-divider></mat-divider> -->
    
                <div class="agreement-details-row" fxLayout="row" fxLayoutAlign="space-between center">
                    <div class="agreement-details-property" fxLayout="column">
                        <mat-label>{{ 'Agreement-Details.form-debtor-collections' | translate }}</mat-label>
                        <mat-form-field [style.marginBottom]="'-1em'" appearance="none">
                            <input class="overflow" matInput formControlName="debtorCollections" >
                        </mat-form-field>
                    </div>
    
                    <!-- <div class="agreement-details-property" fxLayout="column">
                        <mat-label>{{ 'Agreement-Details.form-term-charges-total' | translate }}</mat-label>
                        <mat-form-field [style.marginBottom]="'-1em'" appearance="none">
                            <input class="overflow" matInput formControlName="termChargesTotal">
                        </mat-form-field>
                    </div> -->
    
                    <!-- <div class="agreement-details-property" fxLayout="column">
                        <mat-label>
                            {{ 'Agreement-Details.form-interest-amount' | translate }}</mat-label>
                        <mat-form-field [style.marginBottom]="'-1em'" appearance="none">
                            <input class="overflow" matInput formControlName="interestAmount">
                        </mat-form-field>
                    </div> -->
                    
                    <div class="agreement-details-property" fxLayout="column">
                        <mat-form-field [style.marginBottom]="'-1em'" appearance="none">
                            <input class="overflow" matInput formControlName="empty">
                        </mat-form-field>
                    </div>

                    <div class="agreement-details-property" fxLayout="column">
                        <mat-form-field [style.marginBottom]="'-1em'" appearance="none">
                            <input class="overflow" matInput formControlName="empty">
                        </mat-form-field>
                    </div>
                </div>
    
                <mat-divider></mat-divider>
    
                <div class="agreement-details-row" fxLayout="row" fxLayoutAlign="space-between center">
                    <div class="agreement-details-property" fxLayout="column">
                        <mat-label>{{ 'Agreement-Details.form-number-outstanding-invoices' | translate }}</mat-label>
                        <mat-form-field [style.marginBottom]="'-1em'" appearance="none">
                            <input class="overflow" matInput formControlName="numberOutstandingInvoices" >
                        </mat-form-field>
                    </div>
    
                    <div class="agreement-details-property" fxLayout="column">
                        <mat-label>{{ 'Agreement-Details.form-number-closed-invoices' | translate }}</mat-label>
                        <mat-form-field [style.marginBottom]="'-1em'" appearance="none">
                            <input class="overflow" matInput formControlName="numberClosedInvoices">
                        </mat-form-field>
                    </div>
    
                    <div class="agreement-details-property" fxLayout="column">
                        <mat-form-field [style.marginBottom]="'-1em'" appearance="none">
                            <input class="overflow" matInput formControlName="empty">
                        </mat-form-field>
                    </div>
                </div>

                <mat-divider></mat-divider>

            </form>
    </div>  
    
    <div class="agreement-details-credit-limit">
        <div class="agreement-details-credit-limit-title" fxLayout="row">
            <mat-icon>description</mat-icon>
            <p> {{ 'Agreement-Details.credit-limit-title' | translate }}</p>
        </div>

        <mat-divider></mat-divider>

        <mat-table class="credit-limit-table" [dataSource]="creditLimitList" class="mat-elevation-z0">
            <ng-container matColumnDef="creditlimit">
              <mat-header-cell *matHeaderCellDef> {{ 'Agreement-Details.credit-limit-table-credit-limit-column' | translate }} </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.creditLimit | customCurrency : currencyCode}} </mat-cell>
            </ng-container>
          
            <ng-container matColumnDef="availableamount">
              <mat-header-cell *matHeaderCellDef> {{ 'Agreement-Details.credit-limit-table-available-amount-column' | translate }} </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.availableAmount | customCurrency : currencyCode}} </mat-cell>
            </ng-container>
          
            <!-- <ng-container *ngIf="!available" matColumnDef="availableamount">
                <mat-header-cell *matHeaderCellDef> {{ 'Agreement-Details.credit-limit-table-available-amount-column' | translate }} </mat-header-cell>
                <mat-cell  class="align-left" *matCellDef="let element"> {{'Agreement-Details.credit-limit-loading' | translate}} </mat-cell>
            </ng-container> -->

            <ng-container matColumnDef="utilisedamount">
              <mat-header-cell *matHeaderCellDef> {{ 'Agreement-Details.credit-limit-table-utilised-amount-column' | translate }} </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.utilisedAmount | customCurrency : currencyCode}} </mat-cell>
            </ng-container>

            <!-- <ng-container *ngIf="!utilised" matColumnDef="utilisedamount">
                <mat-header-cell *matHeaderCellDef> {{ 'Agreement-Details.credit-limit-table-utilised-amount-column' | translate }} </mat-header-cell>
                <mat-cell  class="align-left" *matCellDef="let element"> {{'Agreement-Details.credit-limit-loading' | translate}} </mat-cell>
            </ng-container> -->

            <!-- <ng-container matColumnDef="validfrom">
                <mat-header-cell *matHeaderCellDef> {{ 'Agreement-Details.credit-limit-table-valid-from-column' | translate }} </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.validFrom | date:'dd.MM.yyyy'}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="validuntil">
            <mat-header-cell *matHeaderCellDef> {{ 'Agreement-Details.credit-limit-table-valid-until-column' | translate }} </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.validUntil | date:'dd.MM.yyyy'}} </mat-cell>
            </ng-container> -->
          
            <mat-header-row *matHeaderRowDef="creditLimitDisplayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: creditLimitDisplayedColumns;"></mat-row>
          </mat-table>
    </div>

    <div *ngIf="agreementType != 0 && agreementType != 3" class="agreement-details-debtors">
        <div class="agreement-details-debtors-title" fxLayout="row">
            <mat-icon>description</mat-icon>
            <p *ngIf="agreementType == 1"> {{ 'Agreement-Details.debtors-title-debtor' | translate }}</p>
            <p *ngIf="agreementType == 2"> {{ 'Agreement-Details.debtors-title-vendor' | translate }}</p>
        </div>

        <mat-divider></mat-divider>

        <mat-table class="debtors-table" [dataSource]="vendorsDebtorsList" class="mat-elevation-z0">
            <ng-container matColumnDef="name">
              <mat-header-cell *matHeaderCellDef> {{ 'Agreement-Details.debtors-table-name-column' | translate }} </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.name }} </mat-cell>
            </ng-container>
          
            <ng-container matColumnDef="status">
              <mat-header-cell *matHeaderCellDef> {{ 'Agreement-Details.debtors-table-status-column' | translate }} </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.statusTranslation | translate }} </mat-cell>
            </ng-container>
          
            <ng-container matColumnDef="registrationNo">
              <mat-header-cell *matHeaderCellDef> {{ 'Agreement-Details.debtors-table-registration-no-column' | translate }} </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.registrationId}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="utilisedAmount">
                <mat-header-cell *matHeaderCellDef> {{ 'Agreement-Details.debtors-table-utilised-amount-column' | translate }} </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.utilisedAmount | customCurrency : currencyCode }} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="availableAmount">
            <mat-header-cell *matHeaderCellDef> {{ 'Agreement-Details.debtors-table-available-amount-column' | translate }} </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.availableAmount | customCurrency : currencyCode }} </mat-cell>
            </ng-container>
          
            <mat-header-row *matHeaderRowDef="debtorsDisplayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: debtorsDisplayedColumns;"></mat-row>
          </mat-table>
    </div>

    <div *ngIf="showAd" class="agreement-details-banner">
        <img class="ad" [src]="banner">
    </div>

    <div class="agreement-details-invoices">
        <div class="agreement-details-invoices-title" fxLayout="row">
            <mat-icon>description</mat-icon>
            <p> {{ 'Agreement-Details.invoices-title' | translate }}</p>
            <!-- <button mat-flat-button (click)="createNewInvoice()">{{ 'Agreement-Details.create-new-invoice-button-title' | translate }}</button> -->
        </div>

        <mat-divider></mat-divider>

        
        <div class="agreement-details-invoice-header" fxLayout="row">
            <div class="invoice-search">
              <input class="overflow" matInput [(ngModel)]="defaultSearch" (keyup)="applySearch($event.target.value)" placeholder="{{ 'Invoices.search' | translate }}">
            </div>
            <!-- <button class="create-invoice-button" mat-flat-button (click)="createInvoice()">{{ 'Invoices.new-invoice-button' | translate }}</button> -->
        
            <div class="agreement-details-filters">
            
                <button mat-flat-button (click)="filterAll()" [ngClass] = "{'selected-filter' : allSelected}">{{ 'Agreement-Details.invoices-filter-status-all' | translate }}</button>
                <button mat-flat-button (click)="filterNew()" [ngClass] = "{'selected-filter' : newSelected}">{{ 'Agreement-Details.invoices-filter-status-new' | translate }}</button>
                <button mat-flat-button (click)="filterOpen()" [ngClass] = "{'selected-filter' : openSelected}">{{ 'Agreement-Details.invoices-filter-status-open' | translate }}</button>
                <button mat-flat-button (click)="filterArchive()" [ngClass] = "{'selected-filter' : archiveSelected}">{{ 'Agreement-Details.invoices-filter-status-archive' | translate }}</button>
            </div>
          </div>
        
        

        <!-- <table mat-table [dataSource]="invoicesDataSource" class="mat-elevation-z0">
            <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element"><mat-icon class="mat-icon-description">description</mat-icon></td>
            </ng-container> 
          
            <ng-container matColumnDef="invoiceid">
                <th mat-header-cell *matHeaderCellDef> {{ 'Agreement-Details.invoices-table-invoice-id' | translate }} </th>
                <td mat-cell *matCellDef="let element"> {{element.invoiceId }} </td>
            </ng-container>
          
            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef> {{ 'Agreement-Details.invoices-table-status' | translate }} </th>
                <td mat-cell *matCellDef="let element"> {{element.statusText }} </td>
            </ng-container>

            <ng-container matColumnDef="invoicedate">
                <th mat-header-cell *matHeaderCellDef> {{ 'Agreement-Details.invoices-table-invoice-date' | translate }} </th>
                <td mat-cell *matCellDef="let element"> {{element.invoiceDate | date:'dd.MM.yyyy'}} </td>
            </ng-container>

            <ng-container matColumnDef="invoiceduedate">
                <th mat-header-cell *matHeaderCellDef> {{ 'Agreement-Details.invoices-table-invoice-due-date' | translate }} </th>
                <td mat-cell *matCellDef="let element"> {{element.invoiceDueDate | date:'dd.MM.yyyy'}} </td>
            </ng-container>

            <ng-container matColumnDef="invoiceamount">
                <th mat-header-cell *matHeaderCellDef> {{ 'Agreement-Details.installments-table-invoice-amount' | translate }} </th>
                <td mat-cell *matCellDef="let element"> {{element.invoiceAmountInclVAT | customCurrency : currencyCode}} </td>
            </ng-container>
          
            <tr mat-header-row *matHeaderRowDef="invoicesDisplayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: invoicesDisplayedColumns;"></tr>
        </table>  -->

        <mat-table [dataSource]="invoicesDataSource" class="mat-elevation-z0">
            <ng-container matColumnDef="description">
              <mat-header-cell *matHeaderCellDef>  </mat-header-cell>
              <mat-cell *matCellDef="let element"> <mat-icon class="mat-icon-description">description</mat-icon> </mat-cell>
            </ng-container>
            
            <ng-container matColumnDef="invoiceid">
              <mat-header-cell *matHeaderCellDef> {{ 'Agreement-Details.invoices-table-invoice-id' | translate }} </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.invoiceId}} </mat-cell>
            </ng-container>
        
            <ng-container matColumnDef="status">
                <mat-header-cell *matHeaderCellDef> {{ 'Agreement-Details.invoices-table-status' | translate }} </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.statusTranslation | translate }} </mat-cell>
              </ng-container>
          
            <ng-container matColumnDef="invoicedate">
              <mat-header-cell *matHeaderCellDef> {{ 'Agreement-Details.invoices-table-invoice-date' | translate }} </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.invoiceDate | date:'dd.MM.yyyy' }} </mat-cell>
            </ng-container>
          
            <ng-container matColumnDef="invoiceduedate">
              <mat-header-cell *matHeaderCellDef> {{ 'Agreement-Details.invoices-table-invoice-due-date' | translate }} </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.invoiceDueDate | date:'dd.MM.yyyy' }} </mat-cell>
            </ng-container>
        
            <ng-container matColumnDef="invoiceamount">
                <mat-header-cell *matHeaderCellDef> {{ 'Agreement-Details.installments-table-invoice-amount' | translate }} </mat-header-cell>
                <mat-cell class="align-right" *matCellDef="let element"> {{element.invoiceAmountInclVAT | customCurrency : currencyCode }} </mat-cell>
              </ng-container>
          
            <mat-header-row *matHeaderRowDef="invoicesDisplayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: invoicesDisplayedColumns;"></mat-row>
          </mat-table>

        <mat-paginator [pageSize]="10">
        </mat-paginator>
    </div>
    

    <div class="agreement-details-back-button" fxLayoutAlign="center">
        <button mat-flat-button (click)="backToContracts()">{{ 'Agreement-Details.back-button-title2' | translate }}</button>
    </div>

</div>

<app-progress-spinner  [color]="color" [mode]="mode" [value]="value" [backdropEnabled]="true" [positionGloballyCenter]="true"
 [displayProgressSpinner]="displayProgressSpinner">
</app-progress-spinner>