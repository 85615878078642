<div fxFlexFill>
    <div class="logo-container" fxLayout="column" fxLayoutAlign="center center"  fxFlex="50">
        <img class="logo" src="./assets/images/logo_hovi.png">
    </div>
    <div class="sign-up" fxLayout="column" fxFlex="50">
        <div>
            <p class="sign-up-label">{{ 'Sign-Up.title' | translate }}</p>
            <p class="subtitle"> {{ 'Sign-Up.subtitle' | translate }}</p>
            <form [formGroup]="form" fxLayout="column" class="sign-up-form" (ngSubmit)="onSubmit()">
                <mat-form-field>
                    <mat-label>{{ 'Sign-Up.form-email' | translate }}</mat-label>
                    <input matInput formControlName="email" placeholder="{{ 'Sign-Up.form-email' | translate }}">
                </mat-form-field>
                
                <mat-form-field>
                    <mat-label>{{ 'Sign-Up.form-password' | translate }}</mat-label>
                    <input type="password" matInput formControlName="password" placeholder="{{ 'Sign-Up.form-password' | translate }}">
                    <mat-error>{{ 'Sign-Up.form-password-error' | translate }}</mat-error>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>{{ 'Sign-Up.form-confirm-password' | translate }}</mat-label>
                    <input type="password" matInput formControlName="confirmPassword" placeholder="{{ 'Sign-Up.form-confirm-password' | translate }}" >
                    <mat-error>{{ 'Sign-Up.form-confirmpassword-error' | translate }}</mat-error> 
                      
                </mat-form-field>

                <div class="sign-up-buttons" fxLayout="row" fxLayoutAlign="end">
                    <button [disabled]="!form.valid" [ngClass]="{'disabled' : !form.valid}" mat-flat-button>{{ 'Sign-Up.sign-up-button-title' | translate }}</button>
                </div>
            </form>
        </div>
    </div>
</div>
