<mat-divider></mat-divider>
<div class="events">
    <div class="events-title" fxLayout="row">
        <mat-icon>sync</mat-icon>
        <p> {{ 'Events.form-title' | translate }} </p>
    </div> 

    <div class="events-main">
      <div class="events-main-row">
        <mat-form-field appearance="fill"> 
          <mat-select [(ngModel)]="selectedEvent"  disableOptionCentering >
              <mat-option *ngFor="let event of availableEvents" [value]="event">
                  {{ getEventLabel(event) }}
              </mat-option>
          </mat-select>
        </mat-form-field>
  
        <button mat-flat-button (click)="selectedSync()">{{ 'Events.button-selectedsync' | translate }}</button>
      </div>
      <div class="events-main-row">
        <button mat-flat-button (click)="fullSync()">{{ 'Events.button-fullsync' | translate }}</button>
      </div>
    </div>
</div>



