import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthFacade } from '../auth';
import { JwtData } from '../auth/state/auth.reducer';
import { subscribeOnce } from '../_helpers/rxjs.helper';
import { AgrDebtorsVendorsStatus, AgreementData, AgreementsService, AgreementStatus, AgreementVendorDebtorData, DEFAULT_CURRENCY, ImportInvoiceData } from '../_services/agreements.service';
import jwt_decode from "jwt-decode";
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { MatTableDataSource } from '@angular/material/table';
import { error } from '@angular/compiler/src/util';
import { AppConfigService } from '../_services/appconfig.service';

export interface CreateInvoiceComponentData{
  agreementId: string;
}

export function noWhitespaceValidator(control: FormControl) {
  const isSpace = (control.value || '').match(/\s/g);
  return isSpace ? {'whitespace': true} : null;
}

export function noZeroValidator(control: FormControl) {
  const isZero = control.value == 0;
  return isZero ? {'zero': true} : null;
}

@Component({
  selector: 'app-createinvoice',
  templateUrl: './createinvoice.component.html',
  styleUrls: ['./createinvoice.component.scss']
})

export class CreateInvoiceComponent implements OnInit {
  @Input() selectedValue: string;
  @Input() selectedInvoice: any;
  @Input() selectedFile: any;
  @Input() showFiles = false;
  @Input() showFilesTemplate = false;
  @Input() showFilesInvoices = false;
  @Input() showFilesTemplateArea = true;
  @Input() showFilesInvoicesArea = false;

  currencyCode = DEFAULT_CURRENCY;

  invoices: ImportInvoiceData[] = [];
  files: any[] = [];
  filesTemplate: any[] = [];
  filesInvoices: any[] = [];
  form!: FormGroup;
  formInvoices!: FormGroup;
  vendorsDebtors: AgreementVendorDebtorData[] = [];
  agreements: AgreementData[] = [];
  agreementTitles: string[] = [];
  agreementVendorsDebtorsNames: string[] = [];
  id = '';
  idInvoices = '';
  agreementTitle = '';
  agreementTitleInvoices = '';
  customerId = '';

  //spinner properties
  color = 'primary';
  mode = 'indeterminate';
  value = 50;
  displayProgressSpinner = false;
  spinnerWithoutBackdrop = false;
  disableSubmitButton = true;


  invoicesDisplayedColumns: string[] = ['description', 'invoiceid', 'invoiceDate', 'invoiceDueDate', 'amount'];
  invoicesDataSource = new MatTableDataSource<ImportInvoiceData>(this.invoices);
  
  constructor(
    public dialogRef: MatDialogRef<CreateInvoiceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CreateInvoiceComponentData,
    private formBuilder: FormBuilder,
    private agreementsService: AgreementsService,
    private authFacade: AuthFacade,
    private toastr: ToastrService,
    private translate: TranslateService,
    private appSettings: AppConfigService
  ) { 
    this.authFacade.token$.subscribe((token => {
      let decodedToken: JwtData = jwt_decode(token)
      this.customerId = decodedToken.customerId;
    }));
  }

  ngOnInit(): void {
    this.id = this.data.agreementId;
    this.idInvoices = this.data.agreementId;
    this.agreementsService.getAgreements().subscribe((result) => {
      this.agreements = result.filter(x => x.status == AgreementStatus.Valid && new Date(x.contractEffectiveDate) < new Date() && new Date(x.contractTerm) > new Date());
      if(this.id)
        this.agreementTitle = this.agreements.filter(x => x.systemId == this.id)[0].id;
        this.agreementTitleInvoices = this.agreementTitle;
      this.agreementTitles = this.agreements.map(x => x.id);
      this.refreshForm();
      this.refreshFormInvoices();
    });

    this.agreementsService.getVendorsDebtors().subscribe((result) => {
      this.vendorsDebtors = result;
      if(this.agreementTitle){
        this.agreementVendorsDebtorsNames = this.vendorsDebtors.filter((x => x.agreementId == this.agreementTitle && x.status == AgrDebtorsVendorsStatus.Valid)).map(x => x.name);
      }
    });
   this.refreshForm();
   this.refreshFormInvoices();
  }

  refreshForm(){
    this.form = this.formBuilder.group({ 
      agreementNo: [ this.agreementTitle || '', Validators.required],
      debtorNo: ['', Validators.required],
      invoiceDate:['', Validators.required],
      invoiceNo: ['', [Validators.required, noWhitespaceValidator]],
      invoiceAmountInclVAT: ['', [Validators.required, Validators.pattern(/^\d+(?:[,.]\d+)?$/), noZeroValidator]],
      invoiceDueDate: [undefined]
    })

    this.form.controls['agreementNo'].valueChanges.subscribe((result) => {
      if(result){
        this.agreementTitle = result;
        this.id = this.agreements.filter(x => x.id == result)[0].systemId;
        this.agreementVendorsDebtorsNames = this.vendorsDebtors.filter((x => x.agreementId == result)).map(x => x.name);
        this.form.controls['debtorNo'].setValue('');
      }
    });
  }

  refreshFormInvoices(){
    this.formInvoices = this.formBuilder.group({ 
      agreementNo: [ this.agreementTitle || '', Validators.required],
      debtorNo: ['', Validators.required],
    })

    this.formInvoices.controls['agreementNo'].valueChanges.subscribe((result) => {
      if(result){
        this.agreementTitleInvoices = result;
        this.idInvoices = this.agreements.filter(x => x.id == result)[0].systemId;
        this.agreementVendorsDebtorsNames = this.vendorsDebtors.filter((x => x.agreementId == result)).map(x => x.name);
        this.form.controls['debtorNo'].setValue('');
      }
    });
  }

  uploadTemplate(){
    let formData = new FormData();
    for (const file of this.filesTemplate) {
      formData.append(file.name, file);
    }

    this.agreementsService.importInvoices(formData).subscribe(result => {
      if(result){
        this.invoices = result;
        this.invoicesDataSource = new MatTableDataSource<ImportInvoiceData>(this.invoices);
        this.showFilesInvoicesArea = true;
        this.showFilesTemplateArea = false;
      }
      
    });
  }

  submitInvoices(){
    if(this.formInvoices.valid){
      this.showProgressSpinner();

      let formData = new FormData();
      for (const file of this.filesInvoices) {
        formData.append(file.name, file);
      }

      let invoices = JSON.stringify(this.invoices);
      formData.append('invoices', invoices);
      formData.append('agreementId', this.id);
      formData.append('vendorId', this.vendorsDebtors.filter(x => x.agreementId == this.agreementTitleInvoices && x.name == this.formInvoices.value.debtorNo)[0].id);

      this.agreementsService.createInvoices(formData).subscribe(
        (result) => {
        this.hideProgressSpinner();

        if(result.data == 20000){
          this.showToasterSuccess();
          this.filesInvoices = [];
          this.dialogRef.close();
        }
        else{
          this.showToasterError(this.translate.instant("Error.Failed-upload-invoice.Create-invoices-failed"));
          this.dialogRef.close();
        }
        }, 
        (error) => {
          this.hideProgressSpinner();
          this.showToasterError(this.translate.instant("Error.Failed-upload-invoice.Create-invoices-failed-max-size"));
        }
      );
    }
  }

  cancelInvoices(){
    this.showFilesInvoicesArea = false;
    this.showFilesTemplateArea = true;
  }

  onSubmit(){
    if(this.form.valid){
      this.showProgressSpinner();
      let agreementVendorDebtor = this.form.value.debtorNo;
      this.form.value.invoiceDate = moment(this.form.value.invoiceDate).format("dd.MM.yyyyTHH:mm");
      //this.form.value.invoiceDueDate = moment(this.form.value.invoiceDueDate).format("dd.MM.yyyyTHH:mm");
      this.form.value.debtorNo = this.vendorsDebtors.filter(x => x.agreementId == this.agreementTitle && x.name == agreementVendorDebtor)[0].id; 
      this.agreementsService.createInvoice({...this.form.value }).subscribe((result => {
        if(result.data == 20000){
          if(this.files.length > 0){
            let formData = new FormData();
            for (const file of this.files) {
              formData.append(file.name, file);
            }
  
            let agreementId = this.id;
            formData.append('agreementId', this.id);
            formData.append('invoiceId', 'Invoice_'+this.form.value.invoiceNo);

            this.agreementsService.uploadDocument(formData).subscribe(result => {
              this.hideProgressSpinner();
    
              if(result.data == 20000){
                this.showToasterSuccess();
                this.files = [];
                this.selectedValue = "";
                this.dialogRef.close();
              }
              else{
                this.showToasterError(this.translate.instant("Error.Failed-upload-invoice.Document-upload-failed"));
                this.dialogRef.close();
              }
            });
          }
          else{
            this.agreementsService.processInvoice(this.form.value.invoiceNo).subscribe(result => {
              this.hideProgressSpinner();
              if(result){
                this.showToasterSuccess();
                this.dialogRef.close();
              }
              else{
                this.showToasterError(this.translate.instant("Error.Failed-upload-invoice.Process-failed"));
                this.dialogRef.close();
              }
            });
          }
        }
        else{
          this.hideProgressSpinner();

          if(result.data == 40001){
            this.showToasterError(this.translate.instant("Error.Failed-upload-invoice"))
          }
          if(result.data == 40002){
            this.showToasterError(this.translate.instant("Error.Failed-upload-invoice.Already-existing"))
          }

          this.dialogRef.close();
        }
      }));
    }
  }

  downloadInvoiceTemplate(){
    this.showProgressSpinner()
    this.agreementsService.downloadInvoiceTemplate().subscribe((result => {
      if(result.body['size'] > 0){
        var filename = result.headers.get('content-disposition').split(';')[1].split('filename')[1].split('=')[1].trim();
        const downloadedFile = new Blob([result.body], { type: result.body.type });
        const a = document.createElement('a');
        a.setAttribute('style', 'display:none;');
        document.body.appendChild(a);
        a.href = URL.createObjectURL(downloadedFile);
        a.download = filename;
        a.target = '_blank';
        this.hideProgressSpinner();
        a.click();
        document.body.removeChild(a);
      }
      else{
        this.hideProgressSpinner();
        this.showToasterError(this.translate.instant("Error.Failed-download-invoice-template"));
      }
    }));
  }

  onFileDropped(file) {
    this.prepareFilesList(file);
  }

  onFileDroppedTemplate(file) {
    this.prepareFilesListTemplate(file);
  }

  onFileDroppedInvoices(file) {
    this.prepareFilesListInvoices(file);
  }

  fileBrowseHandler(file) {
    this.prepareFilesList(file);
  }

  fileBrowseHandlerTemplate(file) {
    this.prepareFilesListTemplate(file);
  }

  fileBrowseHandlerInvoices(file) {
    this.prepareFilesListInvoices(file);
  }

  deleteFile(index: number) {
    this.files.splice(index, 1);
    if(this.files.length <= 0){
      this.showFiles = false;
    }
  }

  deleteFileTemplate(index: number) {
    this.filesTemplate.splice(index, 1);
    if(this.filesTemplate.length <= 0){
      this.showFilesTemplate = false;
    }
  }

  deleteFileInvoices(index: number) {
    this.filesInvoices.splice(index, 1);
    if(this.filesInvoices.length <= 0){
      this.showFilesInvoices = false;
    }
  }

  prepareFilesList(files: Array<any>) {
    for (const item of files) {
      this.files.push(item);
    }
    this.showFiles = true;
  }

  prepareFilesListTemplate(files: Array<any>) {
    this.filesTemplate = [];
    for (const item of files) {
      this.filesTemplate.push(item);
    }
    this.showFilesTemplate = true;
  }

  prepareFilesListInvoices(files: Array<any>) {
    for (const item of files) {
      this.filesInvoices.push(item);
    }
    this.showFilesInvoices = true;
  }

  formatBytes(bytes) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = 0;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
  
  highlightRow(file) {
    this.selectedFile = file.name;
  }

  removeHighlightedRow(){
    this.selectedFile = null;
  }

  highlightRowInvoice(invoice) {
    this.selectedInvoice = invoice.invoiceId;
  }

  removeHighlightedRowInvoice(){
    this.selectedInvoice = null;
  }

  //toast
  showToasterSuccess(){
    this.toastr.success('Invoice created successfully.', 'Success', {
      positionClass: 'toast-bottom-center',
      progressAnimation: 'decreasing',
      timeOut: 5000,
    });
  }

  showToasterError(message: string){
    this.toastr.error(message, 'Error', {
      positionClass: 'toast-bottom-center',
      progressAnimation: 'decreasing',
      timeOut: 5000,
    });
  }

  //spinner methods
  showProgressSpinner = () => {
    this.displayProgressSpinner = true;
  };

  hideProgressSpinner = () => {
    this.displayProgressSpinner = false;
  };
}