import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule, APP_SETTINGS } from './app/app.module';
import { environment } from './environments/environment';


enum Settings {
  App,
  Core
}

const bootstrap = (data: any[]) => {
  const providers = [
    { provide: APP_SETTINGS, useValue: data[Settings.App] },
  ];

  // Static providers before bootstrap
  platformBrowserDynamic(providers)
    .bootstrapModule(AppModule)
    .catch(err => console.error(err))
};

if (environment.production) {
  enableProdMode();
}

 // Preload settings
 const uris = ['settings/app.settings.json'];
 const reqs = uris.map(uri => fetch(uri).then(resp => resp.json())); // TODO: what if error?

 Promise.all(reqs)
   .then(bootstrap)