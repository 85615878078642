import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AdminData, AdminService, UserData } from '../_services/admin.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  users: UserData[] = [];
  @Input() selectedUser: any;
  id = '';
  showTable = false;
  defaultSearch = '';

  //usersDisplayedColumns: string[] = ['description', 'name', 'email', 'activated', 'button'];
  usersDisplayedColumns: string[] = ['description', 'name', 'email', 'activated'];
  usersDataSource = new MatTableDataSource<UserData>(this.users);

  constructor(
    private adminService: AdminService,
    private router: Router,
    private toastr: ToastrService
  ) {
  }

  ngOnInit() {
    this.adminService.getUsers().subscribe((result) => {
      this.users = result;
      this.showTable = result.length > 0;
      this.usersDataSource = new MatTableDataSource(this.users);
      this.usersDataSource.paginator = this.paginator;
    });
  }

  deleteUser(id: any){
    // this.authService.deleteAdmin(id).subscribe(result => {
    //   if(result) {
    //     this.showToasterSuccess('Admin deleted successfully.');
    //     this.admins = this.admins.filter(x => x.id != id);
    //     this.adminsDataSource = new MatTableDataSource(this.admins);
    //     this.showTable = this.admins.length > 0;
    //   }
    //   else{
    //     this.showToasterError('Failed to delete Admin.')
    //   }
    // })
  }

  highlightRow(user) {
    this.selectedUser = user.id
  }

  removeHighlightedRow(){
    this.selectedUser = null;
  }

  createNewUser(){
    //this.router.navigate(['/createuser']);
  }

  applySearch(searchValue: string) {
    searchValue = searchValue.trim(); 
    searchValue = searchValue.toLowerCase(); 
    this.usersDataSource.filter = searchValue;
  }

  //toast
  showToasterSuccess(message){
    this.toastr.success(message, 'Success', {
      positionClass: 'toast-bottom-center',
      progressAnimation: 'decreasing',
      timeOut: 5000,
    });
  }

  showToasterError(message){
    this.toastr.error(message, 'Error', {
      positionClass: 'toast-bottom-center',
      progressAnimation: 'decreasing',
      timeOut: 5000,
    });
  }
}