import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { AuthFacade } from '../auth';
import { JwtData } from '../auth/state/auth.reducer';
import { AgreementData, AgreementsService, AgreementStatus, AgreementStatusTranslation } from '../_services/agreements.service';
import jwt_decode from "jwt-decode";

@Component({
  selector: 'app-agreements',
  templateUrl: './agreements.component.html',
  styleUrls: ['./agreements.component.css']
})
export class AgreementsComponent implements OnInit {
    @ViewChild(MatPaginator) paginator: MatPaginator;
    agreements: AgreementData[] = [];
    @Input() selectedAgreement: any;
    id = '';

    agreementsDisplayedColumns: string[] = ['description', 'agreementno', 'status', 'contracteffectivedate', 'button'];
    // agreementsDisplayedColumns: string[] = ['description', 'agreementno', 'status', 'disclosedundisclosed', 'contracteffectivedate', 'contractterm', 'button'];
    agreementsDataSource = new MatTableDataSource<AgreementData>(this.agreements);

    constructor(private agreementsService: AgreementsService, private router: Router, private authFacade: AuthFacade) {
      this.authFacade.token$.subscribe((token => {
        let decodedToken: JwtData = jwt_decode(token)
        this.id = decodedToken.customerId;
      }));
    }

    ngOnInit() {
      this.agreementsService.getAgreements().subscribe((result) => {
        this.agreements = result.map(el => {
          el.statusText = AgreementStatus[el.status].replace(/([A-Z])/g, ' $1').trim();
          el.statusTranslation = AgreementStatusTranslation[AgreementStatus[el.status]];
          el.contractEffectiveDate = this.getDate(el.contractEffectiveDate);
          el.contractTerm = this.getDate(el.contractTerm);
          return el;
        });
        this.agreementsDataSource = new MatTableDataSource(this.agreements);
        this.agreementsDataSource.paginator = this.paginator;
      })
    }

    getDate(date: Date) : Date{
      return date.toString() == '0001-01-01T00:00:00' ? undefined : date;
    }

    openAgreementDetails(id: any){
        this.router.navigate(['/agreements', id]);
    }

    highlightRow(agreement) {
      this.selectedAgreement = agreement.id;
    }

    removeHighlightedRow(){
      this.selectedAgreement = null;
    }
}