import { Observable, throwError } from "rxjs";
import { HttpClientProxy } from "./http.helper";
import { Dictionary } from "./type.helper";
import { fulfillUri, normalizeUri } from "./url.helper";
import { catchError } from 'rxjs/operators';
import { Type } from "@angular/core";

interface IRequest<TBody, TParams> {
    body?: TBody;
    params?: TParams;
  }
  
  interface IResponse {
    message?: string;
  }

  export interface ISingleData<TData> extends IResponse {
    data: TData;
  }
  
  export interface IListData<TData> extends IResponse {
    data: TData[];
  }
export enum OperationMethod {
    Get = 'get',
    Post = 'post',
    Delete = 'delete',
    Put = 'put'
  }

  interface IServiceOptions {
    readonly uri?: string;
  }

interface IOperationOptions {
    readonly uri?: string;
    readonly method?: OperationMethod;
    readonly headers?: Dictionary<string>;
  }

  export function ServiceContract(opts: IServiceOptions = {}): (type: Type<BaseServiceProxy>) => void {
    return (type: Type<BaseServiceProxy>): void => {
      type.prototype.prefix = normalizeUri(opts.uri || '');
    };
  }

export function OperationContract(opts?: IOperationOptions): (proxy: BaseServiceProxy, operation: string) => void {
    const method = (opts || {}).method || OperationMethod.Get;
    const uri = normalizeUri((opts || {}).uri || '');
    const headers = (opts || {}).headers;
  
    return (proxy: BaseServiceProxy, operation: string): void => {
      (<any>proxy)[operation] = function<TBody, TParams, TResponse>(
        req?: IRequest<TBody, TParams>
      ): Observable<TResponse> {
        return this.call(method, uri, req || {}, headers);
      };
    };
  }

  export type ServiceOperation<TBody = void, TParams = void, TResponse = void> = (
    req?: IRequest<TBody, TParams>
  ) => Observable<TResponse>;

  export abstract class BaseServiceProxy {
    private readonly prefix!: string;
  
    protected constructor(private proxy: HttpClientProxy, baseUri?: string) {
      this.prefix = baseUri || this.prefix;
    }
  
    private call<TBody, TParams, TResponse>(
      method: OperationMethod,
      uri: string,
      req: IRequest<TBody, TParams>,
      headers?: Dictionary<string>
    ): Observable<TResponse> {
      return this.proxy[method]<TResponse>(this.uri(uri, req.params), { body: req.body, headers: headers }).pipe(
        catchError(resp => throwError(resp))
      );
   
    }

    private uri(uri: string, params?: any): string {
        return fulfillUri(this.prefix + uri, params);
      }
}