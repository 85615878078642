<mat-divider></mat-divider>

<div class="document-templates" fxLayout="row">
    <!-- <div *ngFor="let documentTemplate of documentTemplates" class="document-template" fxLayout="column" fxLayoutAlign="space-between center">
        <div class="document-template-text">
            <mat-icon class="document-icon">description</mat-icon>
            <p>{{ documentTemplate.description }}</p>
        </div>
    
        <button mat-flat-button (click)="downloadTemplate(documentTemplate)">
            <mat-icon>download</mat-icon>
            {{ 'Documents.download' | translate }}
        </button>
    </div> -->
</div>

<app-progress-spinner  [color]="color" [mode]="mode" [value]="value" [backdropEnabled]="true" [positionGloballyCenter]="true"
 [displayProgressSpinner]="displayProgressSpinner">
</app-progress-spinner>