<div class="sidebar" fxLayout="column" >
    <button *ngIf="!isAdmin" mat-flat-button (click)="openHome()">{{ 'Sidebar.homepage' | translate }}</button>
    <button *ngIf="!isAdmin" mat-flat-button (click)="openAgreements()">{{ 'Sidebar.agreement-data' | translate }}</button>
    <button *ngIf="!isAdmin" mat-flat-button (click)="openInvoices()">{{ 'Sidebar.invoices' | translate }}</button>
    <!-- <button *ngIf="!isAdmin" mat-flat-button (click)="openUploadDocuments()">{{ 'Sidebar.upload-documents' | translate }}</button> -->
    <button *ngIf="!isAdmin" mat-flat-button (click)="openFaqs()">{{ 'Sidebar.faqs' | translate }}</button>
    <!-- <button mat-flat-button (click)="openHelp()">Help</button> -->

    <button *ngIf="isAdmin" mat-flat-button (click)="openMarketingBannersConfiguration()">{{ 'Sidebar.configure-marketing-banners' | translate }}</button>
    <button *ngIf="isAdmin" mat-flat-button (click)="openFaqsConfiguration()">{{ 'Sidebar.configure-faqs' | translate }}</button>
    <button *ngIf="isAdmin" mat-flat-button (click)="openLogs()">{{ 'Sidebar.logs' | translate }}</button>
    <button *ngIf="isAdmin" mat-flat-button (click)="openUsers()">{{ 'Sidebar.users' | translate }}</button>
    <button *ngIf="isAdmin" mat-flat-button (click)="openEvents()">{{ 'Sidebar.events' | translate }}</button>
    <button *ngIf="isSuperAdmin" mat-flat-button (click)="openCreateAdmins()">{{ 'Sidebar.create-admins' | translate }}</button>
</div>