import { getCurrencySymbol } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core'

@Pipe({ name : 'customCurrency' })
export class CustomCurrencyPipe implements PipeTransform{
    transform(currency: number, currencyCode: string, format: 'wide' | 'narrow' = 'narrow'): string {
        var formattedCurrency = this.formatDecimalToUseComma(currency);
        return `${formattedCurrency.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} ${getCurrencySymbol(currencyCode, format)}`;
    }

    formatDecimalToUseComma(input) {
        var ret = (input) ? input.toString().replace(".",",") : '0';
        if(ret) {
            var decArr=ret.split(",");
            if(decArr.length>1){
                var dec=decArr[1].length;
                if(dec===1){ret+="0";}
            }
        }
        return ret;
    }
}