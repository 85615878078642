import { isValidHttpUri } from './url.helper';

export namespace Assert {
  const throwError = (msg: string, value?: any): never => {
    if (value) msg += ` value=${JSON.stringify(value)}`;
    throw new Error(msg);
  };

  export const isHttpUri = (uri: string, msg?: string) => {
    if (!isValidHttpUri(uri)) throwError(msg || 'Value must be valid http uri', uri);
  };
}
