import { Injectable } from '@angular/core';  
import { AuthServiceProxy } from '../_proxy/auth.proxy';
import { map } from 'rxjs/operators';

export interface SetPasswordData{
    email: string;
    code: string;
    password: string;
    confirmPassword: string;
}

export interface SignInData{
    email: string;
    password: string;
    mobileDesktop: string;
}

export interface AdminModel{
    email: string;
    name: string;
}

@Injectable({  
    providedIn: 'root'  
  })
export class AuthService {  
    constructor(private proxy: AuthServiceProxy) { }  

    
    setPassword(data: SetPasswordData) {
      return this.proxy.setPassword({ body: data }).pipe(map(r => r.data));
    }

    signIn(data: SignInData) {
        return this.proxy.signIn({ body: data }).pipe(map(r => r.data));
    }

    logOut() {
        return this.proxy.logOut().pipe(map(r => r.data));
    }

    resetPassword(email: string) {
        return this.proxy.resetPassword({ params: { email } }).pipe(map(r => r.data));
    }

    getUserCount(){
        return this.proxy.getUserCount().pipe(map(r => r.data));
    }

    createSuperAdmin(data: AdminModel) {
        return this.proxy.createSuperAdmin({ body: data }).pipe(map(r => r.data));
    }

    createAdmin(data: AdminModel) {
        return this.proxy.createAdmin({ body: data }).pipe(map(r => r.data));
    }

    getAdmins() {
        return this.proxy.getAdmins().pipe(map(r => r.data));
    }

    deleteAdmin(id: string){
        return this.proxy.deleteAdmin({ params: { id } }).pipe(map(r => r.data));
    }

    synchronizeEvent(id: string) {
        return this.proxy.synchronizeEvent({ params: { id } }).pipe(map(r => r.data));
    }

    synchronizeAll() {
        return this.proxy.synchronizeAll().pipe(map(r => r.data));
    }
}  
