import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { AuthFacade } from './state';

const AUTH_UNAUTHORIZED_STATUS = 401;

export enum AuthInterceptorHeader {
    IgnoreStatus = 'ignoreStatus'
}

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private facade: AuthFacade, 
    private toastr: ToastrService,
    private router: Router
    ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.headers.has(AuthInterceptorHeader.IgnoreStatus)) {
      return next.handle(req.clone({ headers: req.headers.delete(AuthInterceptorHeader.IgnoreStatus) }));
    }

    return next.handle(req).pipe(catchError(err => this.handleError(err)));
  }

  private handleError(res: HttpErrorResponse): Observable<any> {
    if (res.status === AUTH_UNAUTHORIZED_STATUS) {
        this.facade.resetToken();
        this.router.navigate(['/login']);
    }

    return throwError(res);
  }
}
