<mat-divider></mat-divider>

<div *ngIf="isAdmin" class="homepage">
    <div class="homepage-tiles">
        <div class="homepage-tile admin configure-marketing-banners" (click)="openMarketingBannersConfiguration()">
            <mat-icon>photo_library</mat-icon>  
            <p class="title">{{ 'Homepage.marketingbanners-title' | translate }}</p>
        </div>

        <div class="homepage-tile admin configure-faqs" (click)="openFaqsConfiguration()">
            <mat-icon>question_answer</mat-icon>
            <p class="title">{{ 'Homepage.faqs-title' | translate }}</p>
        </div>
        
        <div class="homepage-tile admin logs" (click)="openLogs()">
            <mat-icon>history</mat-icon>
            <p class="title">{{ 'Homepage.logs-title' | translate }}</p>
        </div>

        <div class="homepage-tile admin users" (click)="openUsers()">
            <mat-icon>people_outline</mat-icon>
            <p class="title">{{ 'Homepage.users-title' | translate }}</p>
        </div>

        <div class="homepage-tile admin events" (click)="openEvents()">
            <mat-icon>sync</mat-icon>
            <p class="title">{{ 'Homepage.events-title' | translate }}</p>
        </div>
        
        <div *ngIf="isSuperAdmin" class="homepage-tile admin create-admins" (click)="openCreateAdmins()">
            <mat-icon>people</mat-icon>
            <p class="title">{{ 'Homepage.admins-title' | translate }}</p>
        </div>
    </div>
</div>

<div *ngIf="!isAdmin" class="homepage">
    <div class="homepage-tiles">
        <div *ngIf="creditLimit" class="homepage-tile home-credit-limit">
            <p class="title">{{ 'Homepage.credit-limit-title' | translate }}</p>
            <p *ngIf="!creditLimit.availableAmount && creditLimit.availableAmount !== 0" class="number">{{ 'Homepage.credit-limit-available-loading' | translate }}</p>
            <p *ngIf="!creditLimit.utilisedAmount && creditLimit.utilisedAmount !== 0" class="number">{{ 'Homepage.credit-limit-utilised-loading' | translate }}</p>
            <p *ngIf="creditLimit.availableAmount || creditLimit.availableAmount === 0" class="number">{{ 'Homepage.credit-limit-available' | translate }} {{ creditLimit.availableAmount | customCurrency : currencyCode }}</p>
            <p *ngIf="creditLimit.utilisedAmount || creditLimit.utilisedAmount === 0" class="number">{{ 'Homepage.credit-limit-utilised' | translate }} {{ creditLimit.utilisedAmount | customCurrency : currencyCode }}</p>
            <!-- <p class="number">{{ 'Homepage.credit-limit-subtitle' | translate }} {{ creditLimit.validFrom | date:'dd.MM.yyyy' }} - {{ creditLimit.validUntil | date:'dd.MM.yyyy' }}</p> -->
            <p *ngIf="isValidCreditLimit" class="number">{{ 'Homepage.credit-limit-valid' | translate }}</p>
            <p *ngIf="!isValidCreditLimit" class="number">{{ 'Homepage.credit-limit-notvalid' | translate }}</p>
        </div>

        <div class="homepage-tile home-agreement-overdue">
            <p class="title">{{ 'Homepage.agreement-overdue-title' | translate }}</p>
            <p class="number">{{ agreementsOverdueAmount | customCurrency : currencyCode }} </p>
            <a href="./invoices/overdue">{{ 'Homepage.invoices-quicklink' | translate }}</a>
        </div>
    </div>

    <div class="homepage-preview">
        <div *ngFor="let agreement of agreements; let i = index" class="preview-invoices">
            <div class="preview-invoices-title" fxLayout="row" (click)="agreementHeaderClicked(agreement)">
                <mat-icon *ngIf="agreement.expanded">expand_less</mat-icon>
                <mat-icon *ngIf="!agreement.expanded">expand_more</mat-icon>
                <p class="preview-invoices-title-id"> {{agreement.clientName}}</p>
                <p class="preview-invoices-title-status"> {{agreement.statusTranslation | translate }}</p>
                <p class="preview-invoices-title-field" *ngIf="!isMinimumDateTimeValue(agreement.contractEffectiveDate)"> {{ 'Agreements.table-contract-effective-date-column' | translate }} - {{agreement.contractEffectiveDate | date:'dd.MM.yyyy'}}</p>
                <!-- <p class="preview-invoices-title-field" *ngIf="!isMinimumDateTimeValue(agreement.contractTerm)"> {{ 'Agreements.table-contract-term-column' | translate }} - {{agreement.contractTerm | date:'dd.MM.yyyy'}}</p> -->
                <div class="preview-invoices-title-buttons">
                    <button mat-flat-button (click)="details(agreement.systemId)">{{ 'Homepage.more-details-button' | translate }}</button>
                </div>
            </div>
    
            <mat-divider *ngIf="agreement.expanded"></mat-divider>
    
            <mat-table *ngIf="agreement.expanded" class="preview-invoices-table" [dataSource]="getInvoicesData(agreement.id)" class="mat-elevation-z0">
                <ng-container matColumnDef="invoiceid">
                  <mat-header-cell *matHeaderCellDef> {{ 'Homepage.invoices-preview-table-invoice-id-column' | translate }} </mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{element.invoiceId}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="invoicestatus">
                    <mat-header-cell *matHeaderCellDef> {{ 'Homepage.invoices-preview-table-invoice-status-column' | translate }} </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.statusTranslation | translate }} </mat-cell>
                  </ng-container>
              
                <ng-container matColumnDef="invoicedate">
                  <mat-header-cell *matHeaderCellDef> {{ 'Homepage.invoices-preview-table-invoice-date-column' | translate }} </mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{element.invoiceDate | date:'dd.MM.yyyy' }} </mat-cell>
                </ng-container>
              
                <ng-container matColumnDef="invoiceduedate">
                  <mat-header-cell *matHeaderCellDef> {{ 'Homepage.invoices-preview-table-invoice-due-date-column' | translate }} </mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{element.invoiceDueDate | date:'dd.MM.yyyy' }} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="invoiceamount">
                    <mat-header-cell class="align-right" *matHeaderCellDef> {{ 'Homepage.invoices-preview-table-invoice-amount-incl-VAT-column' | translate }} </mat-header-cell>
                    <mat-cell class="align-right" *matCellDef="let element"> {{element.invoiceAmountInclVAT | customCurrency : currencyCode }} </mat-cell>
                  </ng-container>
              
                <mat-header-row *matHeaderRowDef="invoicesDisplayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: invoicesDisplayedColumns;"></mat-row>

               
            </mat-table>

            <div *ngIf="agreement.expanded" class="preview-invoices-button">
                <a href="./invoices/{{agreement.systemId}}">{{ 'Homepage.invoices-quicklink' | translate }}</a>
            </div>
        </div>
    </div>

    <div *ngIf="showAd" class="homepage-banner">
        <img class="ad" [src]="banner">
    </div>

    <div class="homepage-quicklinks">
        <div class="quicklinks-templates" (click)="downloadCustomerStatement()">
            <img src="./assets/images/download_f.svg"/>
            <div class="quicklinks-texts">
                <p>{{ 'Homepage.customer-statement-title' | translate }}</p>
            </div>
        </div>
       
        <!-- <div class="quicklinks-upload" (click)="redirectToUpload()">
            <img src="./assets/images/upload_f.svg"/>
            <div class="quicklinks-texts">
                <p>{{ 'Homepage.upload-title' | translate }}</p>
            </div>
        </div> -->
    </div>
</div>

<app-progress-spinner  [color]="color" [mode]="mode" [value]="value" [backdropEnabled]="true" [positionGloballyCenter]="true"
 [displayProgressSpinner]="displayProgressSpinner">
</app-progress-spinner>