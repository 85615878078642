import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { authQuery, AuthState } from './state';
import { JwtData } from './state/auth.reducer';
import jwt_decode from "jwt-decode";

@Injectable({ providedIn: 'root' })
export class SuperAdminGuard implements CanActivate {
    constructor(
        private store$: Store<AuthState>,
        private router: Router
    ) {}
    canActivate(
      next: ActivatedRouteSnapshot,
      state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean 
    {
        return this.checkToken().pipe(
            map(token => {
                let decodedToken: JwtData = jwt_decode(token)
                if (decodedToken.superAdmin.toLowerCase() == 'true') return true;
                this.router.navigate(['/login']);
                return false;
            }),
            first()
        );
    }
      
    private checkToken(): Observable<string | undefined> {
        return this.store$.pipe(select(authQuery.token)).pipe(first());
    }
      
}