import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminDetailsComponent } from './admin-details/admin-details';
import { AdminsComponent } from './admins/admins';
import { AuthGuard } from './auth';
import { AdminGuard } from './auth/admin.guard';
import { SuperAdminGuard } from './auth/super-admin.guard';
import { AgreementDetailsComponent } from './agreement-details/agreement-details.component';
import { AgreementsComponent } from './agreements/agreements.component';
import { CustomerMessagesComponent } from './customer-messages/customer-messages.component';
import { DocumentTemplatesComponent } from './document-templates/document-templates.component';
import { FaqDetailsComponent } from './faq-details/faq-details.component';
import { FaqsComponent } from './faqs/faqs.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { CreateInvoiceComponent } from './createinvoice/createinvoice.component';
import { HomeComponent } from './home/home.component';
import { InvoicesComponent } from './invoices/invoices.component';
import { LoginComponent } from './login/login.component';
import { LogsComponent } from './logs/logs.component';
import { MarketingBannerDetailsComponent } from './marketing-banner-details/marketing-banner-details.component';
import { MarketingBannersComponent } from './marketing-banners/marketing-banners';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { UploadDocumentsComponent } from './upload-documents/upload-documents.component';
import { UsersComponent } from './users/users';
import { EventsComponent } from './events/events';


const routes: Routes = [
  {
    path:'',
    component: LoginComponent,
    data: { showHeader: false, showSidebar: false }
  },
  {
    path:'login',
    component: LoginComponent,
    data: { showHeader: false, showSidebar: false }
  },
  {
    path:'login/:token',
    component: LoginComponent,
    data: { showHeader: false, showSidebar: false }
  },
  {
    path:'home',
    canActivate: [AuthGuard],
    component: HomeComponent,
  },
  {
    path:'agreements',
    canActivate: [AuthGuard],
    component: AgreementsComponent,
  },
  {
    path:'invoices',
    canActivate: [AuthGuard],
    component: InvoicesComponent,
  },
  {
    path:'invoices/:id',
    canActivate: [AuthGuard],
    component: InvoicesComponent,
  },
  {
    path:'templates',
    canActivate: [AuthGuard],
    component: DocumentTemplatesComponent,
  },
  {
    path:'upload',
    canActivate: [AuthGuard],
    component: UploadDocumentsComponent,
  },
  {
    path:'createinvoice',
    canActivate: [AuthGuard],
    component: CreateInvoiceComponent,
  },
  {
    path:'createinvoice/:id',
    canActivate: [AuthGuard],
    component: CreateInvoiceComponent,
  },
  {
    path:'agreements/:id',
    canActivate: [AuthGuard],
    component: AgreementDetailsComponent,
  },
  {
    path:'signup/:code/:email',
    component: SignUpComponent,
    data: { showHeader: false, showSidebar: false }
  },
  {
    path:'messages',
    canActivate: [AuthGuard],
    component: CustomerMessagesComponent,
  },
  {
    path:'forgotpassword',
    component: ForgotPasswordComponent,
    data: { showHeader: false, showSidebar: false }
  },
  {
    path:'passwordreset/:code/:email',
    component: PasswordResetComponent,
    data: { showHeader: false, showSidebar: false }
  },
  {
    path:'marketingbanners',
    canActivate: [AuthGuard, AdminGuard],
    component: MarketingBannersComponent,
  },
  {
    path:'faqs',
    canActivate: [AuthGuard],
    component: FaqsComponent,
  },
  {
    path:'admins',
    canActivate: [AuthGuard, SuperAdminGuard],
    component: AdminsComponent,
  },
  {
    path:'users',
    canActivate: [AuthGuard, SuperAdminGuard],
    component: UsersComponent,
  },
  {
    path:'events',
    canActivate: [AuthGuard, SuperAdminGuard],
    component: EventsComponent,
  },
  {
    path:'createadmin',
    canActivate: [AuthGuard, SuperAdminGuard],
    component: AdminDetailsComponent,
  },
  {
    path:'faqs/:id',
    canActivate: [AuthGuard],
    component: FaqDetailsComponent,
  },
  {
    path:'marketingbanners/:id',
    canActivate: [AuthGuard, AdminGuard],
    component: MarketingBannerDetailsComponent,
  },
  {
    path:'logs',
    canActivate: [AuthGuard, AdminGuard],
    component: LogsComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'top'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
