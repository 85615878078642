import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthFacade } from '../auth';
import { JwtData } from '../auth/state/auth.reducer';
import { AdminService, FaqData } from '../_services/admin.service';
import jwt_decode from "jwt-decode";

@Component({
  selector: 'app-faq-details',
  templateUrl: './faq-details.component.html',
  styleUrls: ['./faq-details.component.css']
})
export class FaqDetailsComponent implements OnInit {
  @Input() id: string = '';
  faq: FaqData = null;
  buttonText = "Faq-Details.create-button";
  titleText = "Faq-Details.create-title";
  form!: FormGroup;
  isAdmin = false;
  
  //spinner properties
  color = 'primary';
  mode = 'indeterminate';
  value = 50;
  displayProgressSpinner = false;
  spinnerWithoutBackdrop = false;
  disableSubmitButton = true;

  constructor(
    private route: ActivatedRoute,
    private adminService: AdminService,
    private formBuilder: FormBuilder, 
    private toastr: ToastrService,
    private router: Router,
    private authFacade: AuthFacade
    ) {
      this.authFacade.token$.subscribe((token => {
        let decodedToken: JwtData = jwt_decode(token)
        this.isAdmin = decodedToken.admin.toLowerCase() == 'true' || decodedToken.superAdmin.toLowerCase() == 'true';
      }));
    }

  ngOnInit() {
    this.id = this.route.snapshot.params.id;
    if(this.id != '0'){
      this.buttonText = 'Faq-Details.edit-button';
      if(this.isAdmin){
        this.titleText = 'Faq-Details.edit-title';
      }else{
        this.titleText = 'Faq-Details.edit-title-2';
      }
      
      this.adminService.getFaq(this.id).subscribe(result => {
        this.faq = result;
        this.refreshForm();
      });    
    }
    this.refreshForm();
  }

  private refreshForm(){
    this.form = this.formBuilder.group({ 
      id: [(this.faq && this.faq.id) || undefined],
      question: [(this.faq && this.faq.question) || undefined, Validators.required],
      answer: [(this.faq && this.faq.answer) || undefined, Validators.required],  
      isEnabled: [(this.faq && this.faq.isEnabled) || false],
    })
  }

  deleteFaq(){
    this.adminService.deleteFaq(this.id).subscribe((result => {
      if(result) {
        this.showToasterSuccess('FAQ deleted successfully.');
        this.router.navigate(['/faqs']);
      }
      else{
        this.showToasterError('Failed to delete FAQ.')
      }
    }));
  }
  onSubmit(){
    if(this.form.valid){
      //this.showProgressSpinner();
      this.adminService.editFaq({...this.form.value }).subscribe((result => {
        //this.hideProgressSpinner();
        if(result) {
          this.showToasterSuccess('FAQ created/edited successfully.');
          this.router.navigate(['/faqs']);
        }
        else{
          this.showToasterError('Failed to create/edit FAQ.')
        }
      }));
    }
  }

  //toast
  showToasterSuccess(message){
    this.toastr.success(message, 'Success', {
      positionClass: 'toast-bottom-center',
      progressAnimation: 'decreasing',
      timeOut: 5000,
    });
  }

  showToasterError(message){
    this.toastr.error(message, 'Error', {
      positionClass: 'toast-bottom-center',
      progressAnimation: 'decreasing',
      timeOut: 5000,
    });
  }

  //spinner methods
  showProgressSpinner = () => {
    this.displayProgressSpinner = true;
  };

  hideProgressSpinner = () => {
    console.log('hide spinenr')
    this.displayProgressSpinner = false;
  };
}