<div fxFlexFill>
    <div class="logo-container" fxLayout="column" fxLayoutAlign="center center"  fxFlex="50">
        <img class="logo" src="./assets/images/logo_hovi.png">
    </div>
    <div class="forgot-password" fxLayout="column" fxFlex="50">
        <div>
            <p class="forgot-password-label">{{ 'Forgot-Password.title' | translate }}</p>
            <p class="subtitle"> {{ 'Forgot-Password.subtitle' | translate }}</p>
            <form [formGroup]="form" fxLayout="column" class="forgot-password-form" (ngSubmit)="onSubmit()">
                <mat-form-field>
                    <mat-label>{{ 'Forgot-Password.form-email' | translate }}</mat-label>
                    <input matInput formControlName="email" placeholder="Enter email">
                </mat-form-field>
                <div class="forgot-password-buttons" fxLayout="row" fxLayoutAlign="space-between center">
                    <button  [disabled]="!form.valid" [ngClass]="{'disabled' : !form.valid}" mat-flat-button>{{ 'Forgot-Password.forgot-password-button-title' | translate }}</button>
                </div>
            </form>
        </div>
    </div>
</div>
