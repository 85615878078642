<div class="create-invoice">
    <!-- <div class="title">
        <p>{{ 'CreateInvoice.title' | translate }}</p>
    </div>
     -->
    <mat-tab-group preserveContent mat-stretch-tabs dynamicHeight >
        <mat-tab label="{{ 'CreateInvoice.one-invoice-tab-title' | translate }}">
            <div class="create-invoice-form">
                <!-- <p>{{ 'CreateInvoice.form-title' | translate }}</p> -->
        
                <form [formGroup]="form" fxLayout="column" (ngSubmit)="onSubmit()">
                    <mat-form-field appearance="fill"> 
                        <mat-label>{{ 'CreateInvoice.form-agreement-no' | translate }}</mat-label>
                        <mat-select formControlName="agreementNo" name="agreementTitle" disableOptionCentering >
                            <mat-option></mat-option>
                            <mat-option *ngFor="let agreementTitle of agreementTitles" [value]="agreementTitle">
                                {{ agreementTitle }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
        
                    <mat-form-field appearance="fill"> 
                        <mat-label>{{ 'CreateInvoice.form-agreement-vendor-debtor' | translate }}</mat-label>
                        <mat-select formControlName="debtorNo" name="vendorDebtor" disableOptionCentering >
                            <mat-option></mat-option>
                            <mat-option *ngFor="let vendorDebtor of agreementVendorsDebtorsNames" [value]="vendorDebtor">
                                {{ vendorDebtor }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
        
                    <mat-form-field appearance="fill">
                        <mat-label>{{ 'CreateInvoice.form-invoice-date' | translate }}</mat-label>
                        <input matInput [matDatepicker]="datePicker" formControlName="invoiceDate" (click)="datePicker.open()">
                        <!-- <mat-hint>dd.MM.yyyy</mat-hint> -->
                        <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
                        <mat-datepicker #datePicker></mat-datepicker>
                    </mat-form-field>
        
                    <mat-form-field appearance="fill">
                        <mat-label>{{ 'CreateInvoice.form-invoice-no' | translate }}</mat-label>
                        <input matInput formControlName="invoiceNo" placeholder="Enter Invoice No.">
                        <mat-error>{{ 'CreateInvoice.form-invoice-no-error' | translate }}</mat-error>
                    </mat-form-field>
        
                    <mat-form-field appearance="fill">
                        <mat-label>{{ 'CreateInvoice.form-invoice-amount-incl-VAT' | translate }} </mat-label>
                        <input matInput formControlName="invoiceAmountInclVAT" placeholder="Enter Invoice Amount Including VAT">
                        <mat-error>{{ 'CreateInvoice.form-invoice-amount-incl-VAT-error' | translate }}</mat-error>
                    </mat-form-field>
        
                    <!-- <mat-form-field appearance="fill">
                        <mat-label>{{ 'CreateInvoice.form-invoice-due-date' | translate }}</mat-label>
                        <input matInput [matDatepicker]="dueDatePicker" formControlName="invoiceDueDate" (click)="dueDatePicker.open()">
                        <mat-datepicker-toggle matSuffix [for]="dueDatePicker"></mat-datepicker-toggle>
                        <mat-datepicker #dueDatePicker></mat-datepicker>
                    </mat-form-field> -->
                  
                    <div class="file-upload-outer" fxFlexFill fxLayout="column">
                        <div fxLayout="row">
                            <div fxLayout="column" fxLayoutAlign="center center"  class="file-upload-field" appDnd (fileDropped)="onFileDropped($event)">
                                <input type="file" #fileDropRef id="fileDropRef" multiple (change)="fileBrowseHandler($event.target.files)" />
                                <mat-icon class="main-icon">cloud_upload</mat-icon>
                                
                                <div class="file-upload-text" fxLayout="row">
                                    <mat-icon class="attach-icon">attach_file</mat-icon>
                                    <p class="add-file">{{ 'Upload.add-file-title' | translate }}</p>
                                    <p class="drop">{{ 'Upload.add-file-subtitle' | translate }}</p>
                                </div>
                            </div>
                
                
                            <div class="files-list" (mouseout)="removeHighlightedRow()">
                                <div fxLayout="row" class="single-file" *ngFor="let file of files; let i = index" (mouseover)="highlightRow(file)">
                                    <div class="info" [ngClass] = "{'highlight-div' : file.name == selectedFile}" fxLayout="row">
                                        <p class="name" [ngClass] = "{'highlight-p' : file.name == selectedFile}">{{ file?.name }}</p>
                                        <p class="size" [ngClass] = "{'highlight-p' : file.name == selectedFile}">{{ formatBytes(file?.size) }}</p>
                                    </div>
                                    <mat-icon (click)="deleteFile(i)">close</mat-icon>
                                </div>
                            </div>
                        </div>
                    </div>
        
                    <div class="send-button" fxLayout="row" fxLayoutAlign="space-between center">
                        <button [disabled]="!form.valid || !showFiles" [ngClass]="{'disabled' : !form.valid || !showFiles }" mat-flat-button>{{ 'CreateInvoice.create' | translate }}</button>
                    </div>
                </form>
            </div>
        </mat-tab>
        <mat-tab label="{{ 'CreateInvoice.invoices-tab-title' | translate }}">
            <div class="create-invoices-form">
                <form [formGroup]="formInvoices" fxLayout="column">
                    <mat-form-field appearance="fill"> 
                        <mat-label>{{ 'CreateInvoice.form-agreement-no' | translate }}</mat-label>
                        <mat-select formControlName="agreementNo" name="agreementTitle" disableOptionCentering >
                            <mat-option></mat-option>
                            <mat-option *ngFor="let agreementTitle of agreementTitles" [value]="agreementTitle">
                                {{ agreementTitle }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
        
                    <mat-form-field appearance="fill"> 
                        <mat-label>{{ 'CreateInvoice.form-agreement-vendor-debtor' | translate }}</mat-label>
                        <mat-select formControlName="debtorNo" name="debtorNo" disableOptionCentering >
                            <mat-option></mat-option>
                            <mat-option *ngFor="let vendorDebtor of agreementVendorsDebtorsNames" [value]="vendorDebtor">
                                {{ vendorDebtor }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
        
                    <div *ngIf="showFilesTemplateArea" class="file-upload-outer" fxFlexFill fxLayout="column">
                        <div fxLayout="row">
                            <div fxLayout="column" fxLayoutAlign="center center"  class="file-upload-field" appDnd (fileDropped)="onFileDroppedTemplate($event)">
                                <input type="file" #fileDropRef2 id="fileDropRef2" (change)="fileBrowseHandlerTemplate($event.target.files)" />
                                <mat-icon class="main-icon">cloud_upload</mat-icon>
                                
                                <div class="file-upload-text" fxLayout="row">
                                    <mat-icon class="attach-icon">attach_file</mat-icon>
                                    <p class="add-file">{{ 'Upload.add-file-title-template' | translate }}</p>
                                    <p class="drop">{{ 'Upload.add-file-subtitle-template' | translate }}</p>
                                </div>
                            </div>
                
                
                            <div *ngIf="showFilesTemplate" class="files-list" (mouseout)="removeHighlightedRow()">
                                <div fxLayout="row" class="single-file" *ngFor="let file of filesTemplate; let i = index" (mouseover)="highlightRow(file)">
                                    <div class="info" [ngClass] = "{'highlight-div' : file.name == selectedFile}" fxLayout="row">
                                        <p class="name" [ngClass] = "{'highlight-p' : file.name == selectedFile}">{{ file?.name }}</p>
                                        <p class="size" [ngClass] = "{'highlight-p' : file.name == selectedFile}">{{ formatBytes(file?.size) }}</p>
                                    </div>
                                    <mat-icon (click)="deleteFileTemplate(i)">close</mat-icon>
                                </div>
                            </div>
        
                            <div *ngIf="!showFilesTemplate" class="requirements-list">
                                <p>{{ 'CreateInvoice.form-invoice-template-title' | translate }}</p>
                                <p class="push-1">1. <a class="template-download" (click)="downloadInvoiceTemplate()">{{ 'CreateInvoice.form-invoice-template' | translate }}</a> {{ 'CreateInvoice.form-invoice-template2' | translate }}</p>
                                <p class="push-1">{{ 'CreateInvoice.form-invoice-template-step-1' | translate }}</p>
                                <p class="push-1">{{ 'CreateInvoice.form-invoice-template-step-2' | translate }}</p>
                            </div>       
                        </div>
                    </div>
                    <div *ngIf="showFilesTemplateArea" class="send-button" fxLayout="row" fxLayoutAlign="space-between center">
                        <button [disabled]="!showFilesTemplate" [ngClass]="{'disabled' : !showFilesTemplate }" mat-flat-button (click)="uploadTemplate()" >{{ 'CreateInvoice.Import' | translate }}</button>
                    </div>

                    <mat-table *ngIf="showFilesInvoicesArea" [dataSource]="invoicesDataSource" class="mat-elevation-z0">
                        <ng-container matColumnDef="description">
                          <mat-header-cell *matHeaderCellDef>  </mat-header-cell>
                          <mat-cell *matCellDef="let element"> <mat-icon [ngClass] = "{'highlight-description' : element.invoiceId == selectedInvoice}" class="mat-icon-description">description</mat-icon> </mat-cell>
                        </ng-container>
                        
                        <ng-container matColumnDef="invoiceid">
                          <mat-header-cell *matHeaderCellDef> {{ 'CreateInvoice.invoices-upload-invoiceid' | translate }} </mat-header-cell>
                          <mat-cell *matCellDef="let element"> {{element.invoiceId}} </mat-cell>
                        </ng-container>
                    
                        <ng-container matColumnDef="invoiceDate">
                          <mat-header-cell *matHeaderCellDef> {{ 'CreateInvoice.invoices-upload-invoiceDate' | translate }} </mat-header-cell>
                          <mat-cell *matCellDef="let element"> {{element.invoiceDate | date:'dd.MM.yyyy'}} </mat-cell>
                        </ng-container>
                    
                        <ng-container matColumnDef="invoiceDueDate">
                          <mat-header-cell *matHeaderCellDef> {{ 'CreateInvoice.invoices-upload-invoiceDueDate' | translate }} </mat-header-cell>
                          <mat-cell *matCellDef="let element"> {{element.invoiceDueDate | date:'dd.MM.yyyy'}} </mat-cell>
                        </ng-container>
                    
                        <ng-container matColumnDef="amount">
                            <mat-header-cell *matHeaderCellDef> {{ 'CreateInvoice.invoices-upload-amount' | translate }} </mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{element.amountInclVAT | customCurrency : currencyCode }} </mat-cell>
                        </ng-container>

                        <mat-header-row *matHeaderRowDef="invoicesDisplayedColumns"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: invoicesDisplayedColumns;"  (mouseover)="highlightRowInvoice(row)"></mat-row>
                    </mat-table>

                    <div *ngIf="showFilesInvoicesArea" class="file-upload-outer" fxFlexFill fxLayout="column">
                        <div fxLayout="row">
                            <div fxLayout="column" fxLayoutAlign="center center"  class="file-upload-field" appDnd (fileDropped)="onFileDroppedInvoices($event)">
                                <input type="file" #fileDropRef3 id="fileDropRef3" multiple (change)="fileBrowseHandlerInvoices($event.target.files)" />
                                <mat-icon class="main-icon">cloud_upload</mat-icon>
                                
                                <div class="file-upload-text" fxLayout="row">
                                    <mat-icon class="attach-icon">attach_file</mat-icon>
                                    <p class="add-file">{{ 'Upload.add-file-title-invoices' | translate }}</p>
                                    <p class="drop">{{ 'Upload.add-file-subtitle-invoices' | translate }}</p>
                                </div>
                            </div>
                
                
                            <div class="files-list" (mouseout)="removeHighlightedRow()">
                                <div fxLayout="row" class="single-file" *ngFor="let file of filesInvoices; let i = index" (mouseover)="highlightRow(file)">
                                    <div class="info" [ngClass] = "{'highlight-div' : file.name == selectedFile}" fxLayout="row">
                                        <p class="name" [ngClass] = "{'highlight-p' : file.name == selectedFile}">{{ file?.name }}</p>
                                        <p class="size" [ngClass] = "{'highlight-p' : file.name == selectedFile}">{{ formatBytes(file?.size) }}</p>
                                    </div>
                                    <mat-icon (click)="deleteFileInvoices(i)">close</mat-icon>
                                </div>
                            </div>
        
                        </div>
                    </div>
                    <div class="invoices-buttons">
                        <div *ngIf="showFilesInvoicesArea" class="send-button" fxLayout="row" fxLayoutAlign="space-between center">
                            <button [disabled]="!formInvoices.valid || !showFilesInvoices" [ngClass]="{'disabled' : !formInvoices.valid || !showFilesInvoices }" (click)="submitInvoices()" mat-flat-button>{{ 'CreateInvoice.create' | translate }}</button>
                        </div>
                        <div *ngIf="showFilesInvoicesArea" class="send-button cancel" fxLayout="row" fxLayoutAlign="space-between center">
                            <button (click)="cancelInvoices()" mat-flat-button>{{ 'CreateInvoice.cancel' | translate }}</button>
                        </div>
                    </div>
                </form>
            </div>
        </mat-tab>
    </mat-tab-group>
    
</div>

<app-progress-spinner  [color]="color" [mode]="mode" [value]="value" [backdropEnabled]="true" [positionGloballyCenter]="true"
 [displayProgressSpinner]="displayProgressSpinner">
</app-progress-spinner>